import { BannerSlider } from 'components/organisms'
import PromotionsCard from 'components/organisms/promotions-card'
import PromotionsCopy from 'components/organisms/promotions-copy'
import PromotionsForm from 'components/organisms/promotions-form'

const PromotionsRelax = ({ data, idFilter }: any) => {
  return (
    <>
      <BannerSlider data={data.banners} />

      <PromotionsCopy data={data} />

      {data.is_formulario ? (
        <PromotionsForm data={data?.imagenes_form} />
      ) : (
        <PromotionsCard
          title='Aprovecha nuestros descuentos'
          data={data.hoteles}
          type='campaign2'
          idFilter={idFilter}
        />
      )}
    </>
  )
}

export default PromotionsRelax
