import { BannerSlider } from "components/organisms";
import { banner_data_centrado } from "./data";
import { Controller, useForm } from "react-hook-form";
import { COUNTRY_CODE } from "utils/valuesForms";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { getUtils } from "actions/utils/services";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { toast } from "sonner";
import useRecaptcha from "hooks/useRecaptcha";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { logSentryErrorResponse } from "utils/function";
import * as Sentry from '@sentry/react';

interface FormValues {
  razon_social: string;
  ruc: string;
  dni: string;
  nombre: string;
  apellido: string;
  codigo_pais: string;
  htl_id: string;
  telefono: string;
  correo_electronico: string;
  tipo: string;
  check_in: string;
  check_out: string;
  huespedes: any;
  habitaciones: any;
  terminos_condiciones: boolean,
  politicas_datos_personales: boolean,
  publicidad_y_promociones: boolean,
}

const defaultValues: FormValues = {
  razon_social: "",
  ruc: "",
  dni: "",
  nombre: "",
  apellido: "",
  codigo_pais: "",
  htl_id: "",
  telefono: "",
  correo_electronico: "",
  tipo: "Information",
  check_in: "",
  check_out: "",
  huespedes: 0,
  habitaciones: 0,
  terminos_condiciones: false,
  politicas_datos_personales: false,
  publicidad_y_promociones: false,
};

const TarifasCorporativas = () => {
  const { t, i18n } = useTranslation();
  const { getRecaptchaToken } = useRecaptcha();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    getValues,
    setValue,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues,
  });
  const [isLoading, setIsLoading] = useState(false);

  const selectedOption = watch("tipo");
  const guests = watch("huespedes");
  const rooms = watch("habitaciones");

  const { language, hoteles } = useAppSelector((state) => ({
    language: state.config.language,
    hoteles: state.language.dataUtils?.hoteles || [],
  }));

  const handleOptionChange = (event: any) => {
    setValue("tipo", event.target.value);
  };

  const increment = (field: keyof FormValues) => {
    const currentValue = getValues()[field];
    setValue(field, currentValue + 1);
  };

  const decrement = (field: keyof FormValues) => {
    const currentValue = getValues()[field];
    setValue(field, currentValue > 0 ? currentValue - 1 : 0);
  };

  const onSubmit = async (data: FormValues) => {
    const token = await getRecaptchaToken();

    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      setIsLoading(true);
      const { htl_id, huespedes, habitaciones, terminos_condiciones, politicas_datos_personales, publicidad_y_promociones, ...rest } =
        data;

      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/form/tarifa_corporativa`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          },
          body: JSON.stringify({
            ...rest,
            tipo:
              selectedOption === "additionalInfo" ? "Reserva" : "Informacion",
            htl_id: parseInt(htl_id) || "",
            huespedes: parseInt(huespedes) || "",
            habitaciones: parseInt(habitaciones) || "",
            terminos_condiciones : terminos_condiciones  ? 1 : 0,
            politicas_datos_personales : politicas_datos_personales  ? 1 : 0,
            publicidad_y_promociones : publicidad_y_promociones  ? 1 : 0,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        toast.success(data.message, { position: "top-right" });
        reset();
      } else {
        logSentryErrorResponse(response, 'Tarifas Corporativas Form');
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }
    } catch (error) {
      Sentry.captureException(error); // Captura el error en Sentry
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUtils({ language });
    window.scrollTo(0, 0);
  }, [language]);

  return (
    <>
      <BannerSlider data={banner_data_centrado} />
      <div className="tarifas_corporativas tarifas_corporativas-element">
        <div className="tarifas_corporativas__container px-1rem">
          <div className="content-form">
            <h3>{t("forms.leaveData")}</h3>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="radiobutton">
                <label htmlFor="information">
                  <input
                    type="radio"
                    id="information"
                    {...register("tipo")}
                    value="information"
                    defaultChecked
                    onChange={handleOptionChange}
                  />
                  {t("forms.lookingForInfo")}
                </label>
                <label htmlFor="additionalInfo">
                  <input
                    type="radio"
                    id="additionalInfo"
                    {...register("tipo")}
                    value="additionalInfo"
                    onChange={handleOptionChange}
                  />
                  {t("forms.needQuoteReservation")}
                </label>
              </div>

              <div className="form-option1">
                <div className="input-formcontact1">
                  <div>
                    <input
                      type="text"
                      id="razon_social"
                      placeholder={`${t("forms.businessName")}*`}
                      {...register("razon_social", {
                        required: false,
                      })}
                    />
                    {errors.razon_social && (
                      <span>{t("forms.requiredField")}</span>
                    )}
                  </div>

                  <div>
                    <Controller
                      name="ruc"
                      control={control}
                      rules={{
                        required: t("forms.rucRequired"),
                        pattern: {
                          value: /^[0-9]+$/,
                          message: t("forms.rucNumbersOnly"),
                        },
                        maxLength: {
                          value: 11,
                          message: t("forms.ruc11Digits"),
                        },
                        minLength: {
                          value: 11,
                          message: t("forms.ruc11Digits"),
                        },
                      }}
                      render={({ field }) => (
                        <input
                          type="text"
                          id="ruc"
                          placeholder={`${t("forms.ruc")}*`}
                          {...field}
                          onChange={(e) => {
                            const value = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 11);
                            field.onChange(value);
                          }}
                        />
                      )}
                    />
                    {errors.ruc && <span>{errors.ruc.message}</span>}
                  </div>
                </div>

                <div className="input-formcontact">
                  <Controller
                    name="dni"
                    control={control}
                    rules={{
                      required: t("forms.dniRequired"),
                      pattern: {
                        value: /^[0-9]+$/,
                        message: t("forms.dniNumbersOnly"),
                      },
                      maxLength: {
                        value: 8,
                        message: t("forms.dni8Digits"),
                      },
                      minLength: {
                        value: 8,
                        message: t("forms.dni8Digits"),
                      },
                    }}
                    render={({ field }) => (
                      <input
                        type="text"
                        id="dni"
                        placeholder={`${t("forms.dni")}*`}
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 8);
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                  {errors.dni && <span>{errors.dni.message}</span>}
                </div>

                <div className="input-formcontact">
                  <input
                    type="text"
                    id="nombre"
                    placeholder={`${t("forms.firstName")}*`}
                    {...register("nombre", {
                      required: true,
                    })}
                  />
                  {errors.nombre && <span>{t("forms.requiredField")}</span>}

                  <input
                    type="text"
                    id="apellido"
                    placeholder={`${t("forms.lastName")}*`}
                    {...register("apellido", {
                      required: true,
                    })}
                    style={{ marginTop: 20 }}
                  />
                  {errors.apellido && <span>{t("forms.requiredField")}</span>}
                </div>

                <div className="select-formcontact">
                  <div>
                  <select
                    id="codigo_pais"
                    {...register("codigo_pais", { required: false })}
                    >
                    <option value="">{t("forms.countryCode")}</option>
                    {COUNTRY_CODE.map((destino) => (
                      <option key={destino} value={destino}>
                        {destino}
                      </option>
                    ))}
                  </select>
                  {errors.codigo_pais && (
                    <span>{t("forms.requiredField")}</span>
                  )}
                  </div>

                  <div>
                    <Controller
                      name="telefono"
                      control={control}
                      rules={{
                        required: t("forms.requiredField"),
                        pattern: {
                          value: /^[0-9]+$/,
                          message: t("forms.enterOnlyNumbers"),
                        },
                      }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="tel"
                          id="telefono"
                          placeholder={`${t("forms.phone")}*`}
                        />
                      )}
                    />
                    <span>
                      {errors.telefono && <>{errors.telefono.message}</>}
                    </span>
                  </div>
                </div>

                <div className="input-formcontact">
                  <Controller
                    name="correo_electronico"
                    control={control}
                    rules={{
                      required: t("forms.emailRequired"),
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Debe ser un email válido",
                      },
                    }}
                    render={({ field }) => (
                      <input
                        type="email"
                        id="correo_electronico"
                        placeholder={`${t("forms.email")}*`}
                        {...field}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value.endsWith(".con")) {
                            value = value.slice(0, -4) + ".com";
                          }
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                  {errors.correo_electronico && (
                    <span>{errors.correo_electronico.message}</span>
                  )}
                </div>

                {selectedOption === "additionalInfo" && (
                  <>
                    <div className="select-form">
                      <select
                        id="htl_id"
                        {...register("htl_id", { required: false })}
                      >
                        <option value="">{t("forms.chooseHotel")}</option>
                        {hoteles.map((opcion: any) => (
                          <option key={opcion.id} value={opcion.id}>
                            {opcion.nombre}
                          </option>
                        ))}
                      </select>
                      {errors.htl_id && <span>{t("forms.requiredField")}</span>}
                    </div>

                    <div className="input-inlines">
                      <div className="input-inlines__content">
                        <label htmlFor="">
                          {t("forms.checkIn")}
                          <input
                            type="date"
                            id="check_in"
                            placeholder="Fecha de Evento"
                            min={new Date().toISOString().split("T")[0]}
                            {...register("check_in", { required: false })}
                          />
                          {errors.check_in && (
                            <span>{t("forms.requiredField")}</span>
                          )}
                        </label>
                      </div>
                      <div className="input-inlines__content">
                        <label htmlFor="">
                          {t("forms.checkOut")}
                          <input
                            type="date"
                            id="check_out"
                            placeholder="Fecha de Evento"
                            {...register("check_out", { required: false })}
                            min={new Date().toISOString().split("T")[0]}
                          />
                          {errors.check_out && (
                            <span>{t("forms.requiredField")}</span>
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="btnsguest">
                      <label>{t("forms.guests")}</label>
                      <div className="btn-content">
                        <button
                          type="button"
                          onClick={() => decrement("huespedes")}
                          className="btn-guests"
                          disabled={guests === 0}
                        >
                          -
                        </button>
                        <Controller
                          name="huespedes"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="number"
                              readOnly
                              className="guesttext"
                            />
                          )}
                        />
                        <button
                          type="button"
                          onClick={() => increment("huespedes")}
                          className="btn-guests"
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <div className="btnsguest2">
                      <label>{t("forms.rooms")}</label>
                      <div className="btn-content">
                        <button
                          type="button"
                          onClick={() => decrement("habitaciones")}
                          className="btn-guests"
                          disabled={rooms === 0}
                        >
                          -
                        </button>
                        <Controller
                          name="habitaciones"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="number"
                              readOnly
                              className="guesttext"
                            />
                          )}
                        />
                        <button
                          type="button"
                          onClick={() => increment("habitaciones")}
                          className="btn-guests"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </>
                )}

                <Controller
                  name="terminos_condiciones"
                  control={control}
                  rules={{ required: t("forms.requiredField") }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <div className="checkbox__form checkbox__form__terms">
                      <label>
                        <input
                          {...field}
                          type="checkbox"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                        <p>{t("forms.acceptTermsPart1")} <a href={`/${i18n.language}/terminos-y-condiciones`} className="terms-url" target="_blank">{t("forms.acceptTermsLink")}</a> {t("forms.acceptTermsPart2")}*</p>
                      </label>
                      {errors.terminos_condiciones && (
                        <span>{errors.terminos_condiciones.message}</span>
                      )}
                    </div>
                  )}
                />

                <Controller
                  name="politicas_datos_personales"
                  control={control}
                  rules={{ required: t("forms.requiredField") }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <div className="checkbox__form checkbox__form__terms">
                      <label>
                        <input
                          {...field}
                          type="checkbox"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                        <p>{t("forms.acceptPrivacy")} <a href={`/${i18n.language}/politica-de-proteccion-de-datos-personales`} className="terms-url" target="_blank">{t("forms.acceptPrivacyLink")}.</a>*</p>
                      </label>
                      {errors.politicas_datos_personales && (
                        <span>{errors.politicas_datos_personales.message}</span>
                      )}
                    </div>
                  )}
                />

                <Controller
                  name="publicidad_y_promociones"
                  control={control}
                  rules={{ }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <div className="checkbox__form checkbox__form__terms">
                      <label>
                        <input
                          {...field}
                          type="checkbox"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                        <p>{`${t("forms.sendAds")}`}</p>
                      </label>
                      {errors.publicidad_y_promociones && (
                        <span>{errors.publicidad_y_promociones.message}</span>
                      )}
                    </div>
                  )}
                />
              </div>

              <button
                type="submit"
                disabled={!isValid || isLoading}
                className="btn-submit"
              >
                {isLoading ? t("forms.sending") : t("forms.send")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TarifasCorporativas;
