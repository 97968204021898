import { Card, Text } from 'components/atoms'
import { Swiper, SwiperSlide } from 'swiper/react'
import "./styles.scss"
import { Animations } from 'components/molecules'

const RestaurantsCards = ({data, title, restaurantRef, isReview}: any) => {
  return (
    <div className='restaurantscards' ref={restaurantRef}>
      <div className='restaurantscards__container'>
        <Text text={title} type='subtitle' className={'subtitle'} />

        <div className='restaurantscards__description'>
          {/* <SliderRestaurant data={data} type={'restaurant'}/> */}
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            navigation={true}
            className='restaurantscards__description--swiper'
          >
            {data.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                <Animations type='scaleMin'>
                  <Card type='restaurant' restaurant={item} isReview={isReview}/>
                </Animations>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}


export default RestaurantsCards