import { Text } from "components/atoms";
import "./styles.scss";
import CountdownTimer from "components/molecules/countdownTimer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface ICountdownProps {
  color_promocion: string;
  color_secundario: string;
  contador: string;
  logo: {
    path: string;
    alt: string;
  };
  mensaje: string;
  nombre: string;
  redireccion: string;
}

interface IBannerChronometer {
  onClose: () => void;
  countdown: ICountdownProps;
}

const BannerChronometer = ({ onClose, countdown }: IBannerChronometer) => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  return (
    <>
      {countdown !== null && (
        <div
          className="bannerchronometer"
          style={{ backgroundColor: countdown?.color_promocion }}
        >
          <div className="bannerchronometer__container" style={{ display: isScrolled ? "none" : ""}}>
            <div className="bannerchronometer__container--btnclose">
              <button
                onClick={onClose}
                className="close"
                style={{ color: countdown?.color_secundario || "#3300E5" }}
              >
                <span>x</span>
              </button>
            </div>
            <div className="bannerchronometer__container--text">
              <Text
                type="paragraph"
                text={countdown?.mensaje}
                className="title"
                style={{ color: countdown?.color_secundario || "#3300E5"}}
              />
              <div>
                {countdown?.logo && (
                  <img src={countdown.logo?.path} alt={countdown.logo?.alt} />
                )}
              </div>
            </div>

            <CountdownTimer
              contador={countdown?.contador}
              colorBg={countdown?.color_secundario || "#3300E5"}
            />

            <button
              className="redirect"
              onClick={() => navigate(countdown?.redireccion)}
              style={{
                backgroundColor: countdown?.color_secundario || "#3300E5",
              }}
            >
              Ir ahora
            </button>
            <div className="isdesktop">
              <button
                onClick={onClose}
                className="close"
                style={{ color: countdown?.color_secundario || "#3300E5" }}
              >
                <span>x</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerChronometer;
