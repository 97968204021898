import store from 'redux/store';
import * as configSlice from '../slices/config';

export const toggleDarkmode = (value: boolean) => {
  store.dispatch(configSlice.setDarkmode(value))
}

export const setLanguage = (value: string) => {
  store.dispatch(configSlice.setLanguage(value))
  localStorage.setItem('selectedLanguage', value)
}

export const setCoin = (value: any) => {
  store.dispatch(configSlice.setCoin(value));
};