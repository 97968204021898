import React, { useRef } from 'react'
import { Text } from 'components/atoms'
import { Animations, CardCustom } from 'components/molecules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import './styles.scss'

const BetterExperience = ({ data, title }: any) => {
  const SlideRef = useRef(null)

  return (
    <div className='better'>
      <div className='better__container'>
        <Text
          text={title}
          type='subtitle'
          className={'subtitle'}
        />

        <div className='better__description'>
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            navigation={true}
            className='better__description--swiper'
            ref={SlideRef}
          >
            {data.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                <Animations type='downSide'>
                  <CardCustom type={'typeHotels'} data={item} />
                </Animations>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default BetterExperience
