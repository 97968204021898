
import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setDataUtils } from "redux/actions/language";

interface IGetUtils {
  language?: string;
}

export const getUtils = async ({
  language
}: IGetUtils) => {
  try {
    const response = await Axios.request({
      method: "get",
      url: endpoints.utils,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataUtils(data)

    return data;

  } catch (error) {
    alert('No se cargo los datos de utils')
  }
}