import { Icon, Text } from 'components/atoms'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { Link } from 'react-router-dom'
import './styles.scss'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTranslation } from 'react-i18next'

interface ExploreProps {
  data: any[];
  title: string;
}

const Explore: React.FC<ExploreProps> = ({ data, title }) => {
  const { t } = useTranslation();

  return (
    <div className="events slide-left">
      <div className="events__container">
        <Text text={title} type="subtitle" className={'subtitle'} />

        <div className="events__description">
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            navigation={true}
            autoplay={{ delay: 7000, disableOnInteraction: false }}
            modules={[Autoplay]}
            className="events__description--swiper"
          >
            {data.map((better: any, index: number) => (
              <SwiperSlide key={index}>
                <Link to={better.redireccion} className="nlink">
                  <div className="events__description--card">
                    <div className="events__description--card--slider">
                      <LazyLoadImage src={better.img?.path} alt={better.img?.alt} effect="blur" />
                    </div>
                    <div className="events__description--card--container">
                      <Icon
                        name='hotel'
                        list='items'
                        color='gray'
                        width={20}
                        height={20}
                      />
                      <div className='contain'>
                        <p className='hotelssubtitle'>{better.hoteles} {
                          better.hoteles === 1
                          ? t("destinations.hotelIn")
                          : t("destinations.hotelsIn")}
                        </p>
                        <p className='titles' style={{
                          fontSize: better.nombre?.length > 18 ? '14px' : '16px'
                        }}>{better.titulo || better.nombre}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Explore;