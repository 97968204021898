import { Button, Icon, Text } from "components/atoms";
import { useIntl } from "react-intl";
import "./styles.scss";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { messages } from "utils/lang";
import { useNavigate } from "react-router-dom";
import {
  handleMail,
  handlePhoneCall,
  handlePlace,
  redirectUrlExternal,
} from "utils/function";
import { useTranslation } from 'react-i18next'

const RestaurantCity = ({ data, sectionref, title, type }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  // const itemsForLoop = data.map((item: any) => item.id);

  const typeCard = (type: any) => {
    switch (type) {
      case "column": {
        return (
          <>
            <div className="isdesktop">
              <div className="sectionrestaurantcity__column">
                {data.map((better: any, index: number) => (
                  <div
                    className="sectionrestaurantcity__column--container"
                    key={index}
                  >
                    <div className="sectionrestaurantcity__column--cardimg">
                      <Swiper
                        // loop={itemsForLoop.length > 3}
                        pagination={{
                          clickable: true,
                        }}
                        // autoplay={{
                        //   delay: 7000,
                        //   disableOnInteraction: false,
                        // }}
                        modules={[Autoplay, Pagination]}
                        className="sectionrestaurantcity__column--swiper"
                      >
                        {better.img?.map((img: any, index: number) => (
                          <SwiperSlide key={index}>
                            <div className="imgslide">
                              {better.tipo_restaurante?.id !== 5 && (
                                <div
                                  className="imgslide__logo"
                                  style={{
                                    backgroundColor:
                                      better.tipo_restaurante?.color,
                                  }}
                                >
                                  <img
                                    src={better.tipo_restaurante?.img?.path}
                                    alt={better.tipo_restaurante?.img?.alt}
                                    width={60}
                                    height={60}
                                  />
                                </div>
                              )}
                              <img src={img?.path} alt={img?.alt} />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    <div className="sectionrestaurantcity__column--cardtext">
                      <div className="contain">
                        <div>
                          <Text
                            text={better.titulo}
                            type="paragraph"
                            className="title"
                          />
                          <div className="list">
                            <ul>
                              <li>{t('restaurantsCityRestaurant.atentionType')}: {better.tipo_atencion}</li>
                              <li>{t('restaurantsCityRestaurant.foodType')}: {better.tipo_comida}</li>
                            </ul>
                          </div>

                          <Text
                            text={t('restaurantsCityRestaurant.openningHours')}
                            type="paragraph"
                            className="paragraph"
                          />

                          <div className="list">
                            <Text
                              text={better.horario_sintetizado}
                              type="paragraph"
                              className="paragraph"
                            />
                          </div>
                        </div>

                        <div
                          className="btn"
                          onClick={() => navigate(better.slug)}
                        >
                          <Text
                            text={t('restaurantsCityRestaurant.learnMore')}
                            type="paragraph"
                            className="learnmore"
                          />
                          <Icon
                            name={"right"}
                            list={"arrows"}
                            width={20}
                            height={20}
                            className="ml-0-2rem"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="ismobile">
              <Swiper
                slidesPerView={"auto"}
                grabCursor={true}
                loop={false}
                autoplay={{
                  delay: 7000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="sectionrestaurantcity__slider"
              >
                {data.map((better: any) => (
                  <SwiperSlide key={better.id}>
                    <div className="sectionrestaurantcity__slider--cardimg">
                      <Swiper
                        // loop={itemsForLoop.length > 3}
                        pagination={{
                          clickable: true,
                        }}
                        // autoplay={{
                        //   delay: 7000,
                        //   disableOnInteraction: false,
                        // }}
                        modules={[Autoplay, Pagination]}
                        className="sectionrestaurantcity__slider--swiper"
                      >
                        {better.tipo_restaurante?.imagenes.map(
                          (img: any, index: number) => (
                            <SwiperSlide key={index}>
                              <div className="imgslide">
                                {better.tipo_restaurante?.id !== 5 && (
                                  <div
                                    className="imgslide__logo"
                                    style={{
                                      backgroundColor:
                                        better.tipo_restaurante?.color,
                                    }}
                                  >
                                    <img
                                      src={better.tipo_restaurante?.img?.path}
                                      alt={better.tipo_restaurante?.img?.alt}
                                      width={60}
                                      height={60}
                                    />
                                  </div>
                                )}
                                <img src={img?.path} alt={img?.alt} />
                              </div>
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                    </div>
                    <div className="sectionrestaurantcity__slider--cardtext">
                      <div className="contain">
                        <div>
                          <Text
                            text={better.titulo}
                            type="paragraph"
                            className="title"
                          />
                          <div className="list">
                            <ul>
                              <li>{t('restaurantsCityRestaurant.atentionType')}: {better.tipo_atencion}</li>
                              <li>{t('restaurantsCityRestaurant.foodType')}: {better.tipo_comida}</li>
                            </ul>
                          </div>

                          <Text
                            text={t('restaurantsCityRestaurant.openningHours')}
                            type="paragraph"
                            className="paragraph"
                          />

                          {/* <div className='list'>
                            <Paragraph
                              html={{ __html: better.horario_atencion }}
                              family={'txnormal'}
                            />
                          </div> */}
                        </div>

                        <div className="btn" onClick={better.link}>
                          <Text
                            text={t('restaurantsCityRestaurant.atentionType')}
                            type="paragraph"
                            className="learnmore"
                          />
                          <Icon
                            name={"right"}
                            list={"arrows"}
                            width={20}
                            height={20}
                            className="ml-0-2rem"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        );
      }
      case "row": {
        return (
          <>
            <div className="isdesktop">
              <div className="sectionrestaurantcity__row">
                <div className="sectionrestaurantcity__row--container">
                  <div className="sectionrestaurantcity__row--cardimg">
                    <Swiper
                      // loop={itemsForLoop.length > 3}
                      pagination={{
                        clickable: true,
                      }}
                      // autoplay={{
                      //   delay: 7000,
                      //   disableOnInteraction: false,
                      // }}
                      modules={[Autoplay, Pagination]}
                      className="sectionrestaurantcity__row--swiper"
                    >
                      {data.img.map((img: any, index: number) => (
                        <SwiperSlide key={index}>
                          <div className="imgslide">
                            {data.tipo_restaurante?.id !== 5 && (
                              <div
                                className="imgslide__logo"
                                style={{
                                  backgroundColor: data.tipo_restaurante?.color,
                                }}
                              >
                                <img
                                  src={data.tipo_restaurante?.img?.path}
                                  alt={data.tipo_restaurante?.img?.alt}
                                  width={60}
                                  height={60}
                                />
                              </div>
                            )}
                            <img src={img?.path} alt={img?.alt} />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="sectionrestaurantcity__row--cardtext">
                    <div className="contain">
                      <div>
                        <Text
                          text={data.titulo}
                          type="paragraph"
                          className="title"
                          style={{ color: data.tipo_restaurante?.color }}
                        />
                        <Text
                          html={{ __html: data?.descripcion }}
                          type="paragraph"
                          className={"txnormal"}
                        />
                        <div className="list">
                          <ul>
                            <li>{t('restaurantsCityRestaurant.atentionType')}: {data.tipo_atencion}</li>
                            <li>{t('restaurantsCityRestaurant.foodType')}: {data.tipo_comida}</li>
                          </ul>
                        </div>

                        {data.horario_atencion && (
                          <>
                            <Text
                              text={t('restaurantsCityRestaurant.openningHours')}
                              type="paragraph"
                              className="paragraph"
                            />

                            <div className="list">
                              <Text
                                html={{ __html: data.horario_atencion }}
                                type="paragraph"
                                className={"txnormal"}
                              />
                            </div>
                          </>
                        )}

                        <Text
                          text={`${t('restaurantsCityRestaurant.address')}:`}
                          text2={data.direccion}
                          type="paragraph2"
                          className="paragraph"
                          className2="paragraph2"
                        />
                        <Text
                          text={`${t('restaurantsCityRestaurant.email')}:`}
                          text2={data.correo}
                          type="paragraph2"
                          className="paragraph"
                          className2="paragraph2"
                        />
                        <Text
                          text={`${t('restaurantsCityRestaurant.phone')}:`}
                          text2={data.telefono}
                          type="paragraph2"
                          className="paragraph"
                          className2="paragraph2"
                        />
                      </div>

                      <div className="btn">
                        { data.enlace_carta &&
                          <Text
                            text={"Ver carta"}
                            type="paragraph"
                            className="more"
                            onClick={() => redirectUrlExternal(data.enlace_carta)}
                          />
                        }

                        <Button
                          type="gold"
                          onClick={() =>
                            redirectUrlExternal(data.enlace_reserva)
                          }
                        >
                          Reservar una mesa
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ismobile">
              <div className="sectionrestaurantcity__sliderm">
                <div className="sectionrestaurantcity__sliderm--container">
                  <div className="sectionrestaurantcity__sliderm--cardimg">
                    <Swiper
                      // loop={itemsForLoop.length > 3}
                      pagination={{
                        clickable: true,
                      }}
                      // autoplay={{
                      //   delay: 7000,
                      //   disableOnInteraction: false,
                      // }}
                      modules={[Autoplay, Pagination]}
                      className="sectionrestaurantcity__sliderm--swiper"
                    >
                      {data.img.map((img: any, index: number) => (
                        <SwiperSlide key={index}>
                          <div className="imgslide">
                            {data.tipo_restaurante?.id !== 5 && (
                              <div
                                className="imgslide__logo"
                                style={{
                                  backgroundColor: data.tipo_restaurante?.color,
                                }}
                              >
                                <img
                                  src={data.tipo_restaurante?.img?.path}
                                  alt={data.tipo_restaurante?.img?.alt}
                                  width={60}
                                  height={60}
                                />
                              </div>
                            )}
                            <img src={img?.path} alt={img?.alt} />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="sectionrestaurantcity__sliderm--cardtext">
                    <div className="contain">
                      <div>
                        <Text
                          text={data.titulo}
                          type="paragraph"
                          className="title"
                          style={{ color: data.tipo_restaurante?.color }}
                        />
                        <Text
                          html={{ __html: data?.descripcion }}
                          type="paragraph"
                          className="txnormal"
                        />

                        <div className="list">
                          <ul>
                            <li>{t('restaurantsCityRestaurant.atentionType')}: {data.tipo_atencion}</li>
                            <li>{t('restaurantsCityRestaurant.foodType')}: {data.tipo_comida}</li>
                          </ul>
                        </div>

                        {data.horario_atencion && (
                          <>
                            <Text
                              text={t('restaurantsCityRestaurant.openningHours')}
                              type="paragraph"
                              className="paragraph"
                            />

                            <div className="list">
                              <Text
                                html={{ __html: data.horario_atencion }}
                                type="paragraph"
                                className={"txnormal"}
                              />
                            </div>
                          </>
                        )}

                        <Text
                          text={`${t('restaurantsCityRestaurant.address')}:`}
                          text2={data.direccion}
                          type="paragraph2"
                          className="paragraph"
                          className2="paragraph2"
                          onClick={() => handlePlace(data.direccion)}
                        />
                        <Text
                          text={`${t('restaurantsCityRestaurant.email')}:`}
                          text2={data.correo}
                          type="paragraph2"
                          className="paragraph"
                          className2="paragraph2"
                          onClick={() => handleMail(data.correo)}
                        />
                        <Text
                          text={`${t('restaurantsCityRestaurant.phone')}:`}
                          text2={data.telefono}
                          type="paragraph2"
                          className="paragraph"
                          className2="paragraph2"
                          onClick={() => handlePhoneCall(data.telefono)}
                        />
                      </div>

                      <div className="btn">
                        <Text
                          text={"Ver carta"}
                          type="paragraph"
                          className="more"
                          onClick={() => redirectUrlExternal(data.enlace_carta)}
                        />

                        <Button
                          type="gold"
                          onClick={() =>
                            redirectUrlExternal(data.enlace_reserva)
                          }
                        >
                          Reservar una mesa
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="sectionrestaurantcity" ref={sectionref}>
        <div className="sectionrestaurantcity__container">
          <Text text={title} type="subtitle" className={"subtitle"} />

          {typeCard(type)}
        </div>
      </div>
    </>
  );
};

RestaurantCity.defaultProps = {
  type: "column",
};

export default RestaurantCity;
