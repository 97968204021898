import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setDataHotelesDestination, setDataTypesHotels, setLoading } from "redux/actions/language";
import { IGetHotel, IGetHotelDestination, IGetHotelTypes } from "./props";
import { updateMetadata } from "utils/function";

export const getHotelesDestinations = async ({
  place,
  language,
  coin,
  navigate
}: IGetHotelDestination) => {
  try {
    setLoading(true);
    const destinosciudad = `/hoteles/${place}`;

    const response = await Axios.request({
      method: "get",
      url: destinosciudad,
      headers: {
        "Accept-Language": language,
        "Currency-Code": coin,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataHotelesDestination(data);
    updateMetadata({
      title: data?.meta_data?.titulo  || 'Casa Andina Hotel Destinos',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data;

  } catch (error) {
    navigate('*', { replace: false });
  } finally {
    setLoading(false);
  }
}

export const getHotelTypes = async ({
  types,
  language,
  coin,
  navigate
}: IGetHotelTypes) => {
  try {
    setLoading(true);
    // const destinosciudad = `/${endpoints.hoteles}/tipo_hotel/${types}`;
    const destinosciudad = `/hoteles/tipo_hotel/${types}`;

    const response = await Axios.request({
      method: "get",
      url: destinosciudad,
      headers: {
        "Accept-Language": language,
        "Currency-Code": coin,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataTypesHotels(data);
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Hoteles',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data;

  } catch (error) {
    navigate('*', { replace: false });
  } finally {
    setLoading(false);
  }
}

export const getHotel = async ({
  language,
  params,
  coin,
  setDataHotel,
  setLoadingHotel,
}: IGetHotel) => {
  try {
    setLoadingHotel(true);

    let paramsparse : any = {}

    for (let key in params) {
      if (params[key] !== '') {
        paramsparse[key] = params[key]
      }
    }

    const response = await Axios.request({
      method: "get",
      url: endpoints.hoteles,
      params: paramsparse,
      headers: {
        "Accept-Language": language,
        "Currency-Code": coin,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataHotel(data)
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Hotel',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data;

  } catch (error) {
    alert('No se pudo cargar la vista hotel')
  } finally {
    setLoadingHotel(false);
  }
}