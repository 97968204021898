import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppSelector } from "hooks/useAppSelector";
import { toast } from "sonner";
import { Text } from "components/atoms";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { getUtils } from "actions/utils/services";
import { COUNTRY_CODE, GENDER, MOTIVATION } from "utils/valuesForms";
import { BannerSlider } from "components/organisms";
import "./styles.scss";
import { dataBanner } from "./data";
import useRecaptcha from "hooks/useRecaptcha";
import { useTranslation } from "react-i18next";
import { logSentryErrorResponse } from "utils/function";
import * as Sentry from '@sentry/react';

interface FormData {
  nombres: string;
  motivo: string;
  correo: string;
  dst_id: string;
  telefono: string;
  direccion: string;
  pais: string;
  genero: string;
  mensaje: string;
  terminos_condiciones: boolean;
  politicas_datos_personales: boolean;
  publicidad_y_promociones: boolean;
}

const defaultValues: FormData = {
  nombres: "",
  motivo: "",
  correo: "",
  dst_id: "",
  telefono: "",
  direccion: "",
  pais: "",
  genero: "",
  mensaje: "",
  terminos_condiciones: false,
  politicas_datos_personales: false,
  publicidad_y_promociones: false,
};

const Contacto = () => {
  const { t, i18n } = useTranslation();
  const { getRecaptchaToken } = useRecaptcha();
  const language = useAppSelector((state) => state.config.language);
  const destinos = useAppSelector(
    (state) => state.language.dataUtils?.destinos || []
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    getUtils({ language });
    window.scrollTo(0, 0);
  }, [language]);

  const onSubmit = async (data: FormData) => {
    if (!isValid) return;

    const token = await getRecaptchaToken();
    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      setIsLoading(true);
      const { dst_id, terminos_condiciones, politicas_datos_personales, publicidad_y_promociones, ...rest } = data;

      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/form/contact`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          },
          body: JSON.stringify({
            ...rest,
            dst_id: parseInt(dst_id),
            terminos_condiciones: terminos_condiciones ? 1 : 0,
            politicas_datos_personales: politicas_datos_personales ? 1 : 0,
            publicidad_y_promociones: publicidad_y_promociones ? 1 : 0,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        toast.success(data.message, { position: "top-right" });
        reset();
      } else {
        logSentryErrorResponse(response, 'Contacto Form');
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }
    } catch (error) {
      Sentry.captureException(error); // Captura el error en Sentry
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <BannerSlider data={dataBanner} />
      <form onSubmit={handleSubmit(onSubmit)} className="formulariocontact">
        <div className="formulariocontact__container">
          <Text text={t("forms.messagePrompt")} type="subtitle" />

          <div className="formulariocontact__container--form">
            <Controller
              name="nombres"
              control={control}
              rules={{ required: t("forms.enterFullName") }}
              render={({ field }) => (
                <div className="input-formcontact">
                  <input
                    {...field}
                    type="text"
                    placeholder={`${t("forms.fullName")}*`}
                  />
                  {errors.nombres && <span>{errors.nombres.message}</span>}
                </div>
              )}
            />

            <Controller
              name="motivo"
              control={control}
              rules={{ required: t("forms.requiredField") }}
              render={({ field }) => (
                <div className="select-form">
                  <select {...field}>
                    <option value="">{`${t("forms.selectReason")}*`}</option>
                    {MOTIVATION.map((motivo) => (
                      <option key={motivo} value={motivo}>
                        {t(`forms.${motivo}`)}
                      </option>
                    ))}
                  </select>
                  {errors.motivo && <span>{errors.motivo.message}</span>}
                </div>
              )}
            />
            <Controller
              name="correo"
              control={control}
              rules={{
                required: t("forms.enterValidEmail"),
                pattern: {
                  value: /^\S+@\S+$/i,
                  message:  t("forms.enterValidEmail"),
                },
              }}
              render={({ field }) => (
                <div className="input-formcontact">
                  <input
                    {...field}
                    type="email"
                    placeholder={`${t("forms.email")}*`}
                  />
                  {errors.correo && <span>{errors.correo.message}</span>}
                </div>
              )}
            />

            <Controller
              name="dst_id"
              control={control}
              rules={{ required: t("forms.requiredField") }}
              render={({ field }) => (
                <div className="select-form">
                  <select {...field}>
                    <option value="">{`${t("forms.selectDestination")}*`}</option>
                    {destinos.map((data: any) => (
                      <option key={data.id} value={data.id}>
                        {data.nombre}
                      </option>
                    ))}
                  </select>
                  {errors.dst_id && <span>{errors.dst_id.message}</span>}
                </div>
              )}
            />

            <Controller
              name="telefono"
              control={control}
              rules={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: t("forms.enterOnlyNumbers"),
                },
              }}
              render={({ field }) => (
                <div className="input-formcontact">
                  <input {...field} type="tel" placeholder={t("forms.phone")} />
                  {errors.telefono && <span>{errors.telefono.message}</span>}
                </div>
              )}
            />
            <Controller
              name="direccion"
              control={control}
              render={({ field }) => (
                <div className="input-formcontact">
                  <input {...field} type="text" placeholder={t("forms.address")} />
                  {errors.direccion && <span>{errors.direccion.message}</span>}
                </div>
              )}
            />

            <Controller
              name="pais"
              control={control}
              render={({ field }) => (
                <div className="select-form">
                  <select {...field}>
                    <option value="">{t("forms.country")}</option>
                    {COUNTRY_CODE.map((pais) => (
                      <option key={pais} value={pais}>
                        {pais}
                      </option>
                    ))}
                  </select>
                  {errors.pais && <span>{errors.pais.message}</span>}
                </div>
              )}
            />

            <Controller
              name="genero"
              control={control}
              render={({ field }) => (
                <div className="select-form">
                  <select {...field}>
                    <option value="">{t("forms.gender")}</option>
                    {GENDER.map((genero) => (
                      <option key={genero} value={genero}>
                        {genero}
                      </option>
                    ))}
                  </select>
                  {errors.genero && <span>{errors.genero.message}</span>}
                </div>
              )}
            />
          </div>
          <div className="textarea-formcontact">
            <Controller
              name="mensaje"
              control={control}
              rules={{ required: t("forms.enterMessage") }}
              render={({ field }) => (
                <>
                  <textarea {...field} placeholder={`${t("forms.typeMessageHere")}*`} />
                  {errors.mensaje && <span>{errors.mensaje.message}</span>}
                </>
              )}
            />
          </div>

          <div className="text-terms">
            <Controller
              name="terminos_condiciones"
              control={control}
              rules={{ required: t("forms.requiredField") }}
              render={({ field: { onChange, value, ...field } }) => (
                <div className="checkbox__form">
                  <label>
                    <input
                      {...field}
                      type="checkbox"
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                    <p>{t("forms.acceptTermsPart1")} <a href={`/${i18n.language}/terminos-y-condiciones`} className="terms-url" target="_blank">{t("forms.acceptTermsLink")}</a> {t("forms.acceptTermsPart2")}*</p>
                  </label>
                  {errors.terminos_condiciones && (
                    <span>{errors.terminos_condiciones.message}</span>
                  )}
                </div>
              )}
            />

            <Controller
              name="politicas_datos_personales"
              control={control}
              rules={{ required: t("forms.requiredField") }}
              render={({ field: { onChange, value, ...field } }) => (
                <div className="checkbox__form">
                  <label>
                    <input
                      {...field}
                      type="checkbox"
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                    <p>{t("forms.acceptPrivacy")} <a href={`/${i18n.language}/politica-de-proteccion-de-datos-personales`} className="terms-url" target="_blank">{t("forms.acceptPrivacyLink")}.</a>*</p>
                  </label>
                  {errors.politicas_datos_personales && (
                    <span>{errors.politicas_datos_personales.message}</span>
                  )}
                </div>
              )}
            />

            <Controller
              name="publicidad_y_promociones"
              control={control}
              rules={{ }}
              render={({ field: { onChange, value, ...field } }) => (
                <div className="checkbox__form">
                  <label>
                    <input
                      {...field}
                      type="checkbox"
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                    <p>{`${t("forms.sendAds")}`}</p>
                  </label>
                  {errors.publicidad_y_promociones && (
                    <span>{errors.publicidad_y_promociones.message}</span>
                  )}
                </div>
              )}
            />
          </div>

          <button
            type="submit"
            disabled={!isValid || isLoading}
            className="submit-button"
          >
            {isLoading ? t("forms.sending") : t("forms.send")}
          </button>
        </div>
      </form>
    </>
  );
};

export default Contacto;
