
import Axios from "actions/api";
import { IGetCountdown } from "./props";
import { endpoints } from "actions/endpoints/services";

export const getCountdown = async ({
  language,
  setCountdown,
}: IGetCountdown) => {
  try {
    const response = await Axios.request({
      method: "get",
      url: endpoints.contador,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setCountdown(data)

    return data;

  } catch (error) {
    alert('No se pudo cargar el contador')
  }
}
