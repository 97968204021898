import RoutesIndex from "./routes";
import { useEffect } from "react";
import { useAppSelector } from "hooks/useAppSelector";
import { Toaster } from "sonner";
import { setCoin } from "redux/actions/config";
import { messages } from "utils/lang";
import { IntlProvider } from "react-intl";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { REACT_APP_RECAPTCHA_API_KEY } from "typings/env";
import { getCurrencies } from "actions/currencies/services";

function App() {
  const { client_info, language } = useAppSelector(state => ({
    client_info: state.language.dataCurrencies?.client_info?.currency_code,
    language: state.config.language,
  }))

  useEffect(() => {
    const getCoin = localStorage.getItem("selectedCurrency");
    setCoin(getCoin || "USD");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const currentCoin = localStorage.getItem("selectedCurrency");
    if(!currentCoin && client_info && client_info != 'USD')
    {
      setCoin(client_info || "USD");
      localStorage.setItem('selectedCurrency', client_info || "USD");
    }
  }, [client_info]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={REACT_APP_RECAPTCHA_API_KEY || ""}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: "head",
      }}
    >
      <IntlProvider locale={language} messages={messages[language]}>
        <RoutesIndex />
        <Toaster />
      </IntlProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
