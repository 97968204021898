import store from 'redux/store';
import * as languageSlice from '../slices/language';

export const setData = (value: any) => {
  store.dispatch(languageSlice.setData(value))
}

export const setDataBetterPrice = (value: any) => {
  store.dispatch(languageSlice.setDataBetterPrice(value))
}

export const setDataHome = (value: any) => {
  store.dispatch(languageSlice.setDataHome(value))
}

export const setDataPromotion = (value: any) => {
  store.dispatch(languageSlice.setDataPromotion(value))
}

export const setDataUtils = (value: any) => {
  store.dispatch(languageSlice.setDataUtils(value))
}

export const setBestPrices = (value: any) => {
  store.dispatch(languageSlice.setBestPrices(value))
}

export const setDataPromotionBanks = (value: any) => {
  store.dispatch(languageSlice.setDataPromotionBanks(value))
}

export const setDataPromotionSubPages = (value: any) => {
  store.dispatch(languageSlice.setDataPromotionSubPages(value))
}

export const setDataBoxSearch = (value: any) => {
  store.dispatch(languageSlice.setDataBoxSearch(value))
}

export const setDataBoxSearchPrice = (value: any) => {
  store.dispatch(languageSlice.setDataBoxSearchPrice(value))
}

export const setDataDestinations = (value: any) => {
  store.dispatch(languageSlice.setDataDestinations(value))
}

export const setDataCurrencies = (value: any) => {
  store.dispatch(languageSlice.setDataCurrencies(value))
}

export const setDataHoteles = (value: any) => {
  store.dispatch(languageSlice.setDataHoteles(value))
}

export const setDataTypesHotels = (value: any) => {
  store.dispatch(languageSlice.setDataTypesHotels(value))
}

export const setDataHotelesDestination = (value: any) => {
  store.dispatch(languageSlice.setDataHotelesDestination(value))
}

export const setDataRestaurants = (value: any) => {
  store.dispatch(languageSlice.setDataRestaurants(value))
}

export const setDataRestaurantsPlaces = (value: any) => {
  store.dispatch(languageSlice.setDataRestaurantsPlaces(value))
}

export const setDataRestaurantsRestaurant= (value: any) => {
  store.dispatch(languageSlice.setDataRestaurantsPlaces(value))
}

export const setDataRestaurantsCategory = (value: any) => {
  store.dispatch(languageSlice.setDataRestaurantsCategory(value))
}

export const setDataMenuOfertas = (value: any) => {
  store.dispatch(languageSlice.setDataMenuOfertas(value))
}

export const setDataMenuHoteles = (value: any) => {
  store.dispatch(languageSlice.setDataMenuHoteles(value))
}

export const setDataMenuDestinos = (value: any) => {
  store.dispatch(languageSlice.setDataMenuDestinos(value))
}

export const setLoading = (value: boolean) => {
  store.dispatch(languageSlice.setLoading(value))
}

export const setDataAgency = (value: any) => {
  store.dispatch(languageSlice.setDataAgency(value))
}
