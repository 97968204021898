import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './utils/lang/config';
import { I18nextProvider } from 'react-i18next';
import store from './redux/store';
import { Provider } from 'react-redux';
import '../src/assets/styles/index.scss'
import * as Sentry from "@sentry/react";
import { REACT_APP_SENTRY_DSN, REACT_APP_ENV } from "typings/env";

// Initialize sentry
if(REACT_APP_ENV !== 'local'){
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,  // Your Sentry DSN
    environment: REACT_APP_ENV,      // 'production', 'development', etc.
    enabled: true,  // Enable Sentry in the production environment
    beforeSend(event) {
      // Check if the error is the specific one you want to ignore
      if (event.exception && event.exception.values) {
        const exception = event.exception.values[0];
        if (exception && exception.type === 'ReferenceError' && (exception.value?.includes('web is not defined') || exception.value?.includes('gtag is not defined'))) {
          // Return null to prevent the error from being sent to Sentry
          return null;
        }
      }
      // Otherwise, send the event to Sentry
      return event;
    },
  });
}

// Create root
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
