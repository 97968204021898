import { Fragment, useEffect, useRef, useState } from "react";
import { Calendar } from "./calendar";
import { getPriceBox } from "actions/boxsearch/services";
import { Icon, Text } from "components/atoms";
import { useAppSelector } from "hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { setDataAgency } from "redux/actions/language";
import { useLocation } from "react-router-dom";
import { validateTravelClickIsInMainteinnace } from "utils/function";

interface ISearchBoxGenerla {
  searchMobile?: boolean;
  nameHotel?: string;
  themeCode?: string;
  id_hotel?: string;
  codeagencies?: string;
  adultsAgency?: string;
  currencyAgency?: string;
  hiddenPrices?: boolean;
  close?: () => void;
}

const Searchboxgeneral = ({
  searchMobile,
  nameHotel,
  adultsAgency,
  currencyAgency,
  themeCode,
  id_hotel,
  codeagencies,
  hiddenPrices = false,
  close,
}: ISearchBoxGenerla) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [showCalendar, setShowCalendar] = useState(false);

  const { boxsearch, coin, price } = useAppSelector((state) => ({
    loading: state.language.loading,
    data: state.language.dataBoxSearch,
    boxsearch: state.language.dataBoxSearch?.listado || [],
    price: state.language.dataBoxSearchPrice?.listado_precios || [],
    coin: state.config.coin,
  }));

  const [toggleCalendar, setToggleCalendar] = useState<Boolean>(false);

  const formatDateParts = (date: any) => {
    const dayNumber = date.getDate();
    const dayNames = [
      t("calendar.daysOfWeek.sunday"),
      t("calendar.daysOfWeek.monday"),
      t("calendar.daysOfWeek.tuesday"),
      t("calendar.daysOfWeek.wednesday"),
      t("calendar.daysOfWeek.thursday"),
      t("calendar.daysOfWeek.friday"),
      t("calendar.daysOfWeek.saturday"),
    ];
    const day = dayNames[date.getDay()];
    const monthNames = [
      t("calendar.months.january"),
      t("calendar.months.february"),
      t("calendar.months.march"),
      t("calendar.months.april"),
      t("calendar.months.may"),
      t("calendar.months.june"),
      t("calendar.months.july"),
      t("calendar.months.august"),
      t("calendar.months.september"),
      t("calendar.months.october"),
      t("calendar.months.november"),
      t("calendar.months.december"),
    ];
    const monthIndex = date.getMonth();
    const month = monthNames[monthIndex];
    const year = date.getFullYear() % 100;
    return { dayNumber, day, month, year };
  };

  const rangeContainerRef = useRef<HTMLDivElement>(null);

  const handleContainerClick = (e: any) => {
    if (
      rangeContainerRef.current &&
      rangeContainerRef.current.contains(e.target)
    ) {
      return;
    }
    setToggleCalendar(false);
  };

  // BUSCADOR
  const [inputValue, setInputValue] = useState(nameHotel || "");
  const [inputCode, setInputCode] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<Boolean>(false);
  const [loadingPrices, setLoadingPrices] = useState<Boolean>(false);
  const [isDropDateOpen, setDropDateOpen] = useState<Boolean>(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("");
  const [filteredBoxSearch, setFilteredBoxSearch] = useState(boxsearch);
  const [idHotel, setIdHotel] = useState(id_hotel || "");
  const [travelSlug, setTravelSlug] = useState("");

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [startDate, setStartDate] = useState<Date | null>(today);
  const [endDate, setEndDate] = useState<Date | null>(tomorrow);

  // Ordenar
  const [selectedRange, setSelectedRange] = useState<{
    from: Date | null;
    to: Date | null;
  }>({ from: null, to: null });

  const handleDateSelect = (start: Date | null, end: Date | null) => {
    if (start && end) {
      setStartDate(start);
      setEndDate(end);
    }
  };

  const handleBoxSearch = () => {
    setIsDropdownOpen(!isDropdownOpen);
    filterBoxSearch("");
    // setSelectedRange({ from: null, to: null });
    // setStartDate(today);
    // setEndDate(tomorrow);
  };

  const dateRangerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);

  const handleDropDate = () => {
    setDropDateOpen(!isDropDateOpen);
  };

  const handleClickOutside = (event: any) => {
    if (
      dateRangerRef.current &&
      !dateRangerRef.current.contains(event.target)
    ) {
      setDropDateOpen(false);
    }
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if(!location.pathname.startsWith(`/${i18n.language}/${t("routes.reservations-agents")}`)){
      setDataAgency(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleCalendarClick = () => {
    setShowCalendar(true);
  };

  const handleCityTitle = (city: any) => {
    setSelectedCity(city.titulo);
    setInputValue(city.titulo);
    setTravelSlug(city.travel_click_slug);
    setIdHotel("");
    setIsDropdownOpen(false);
  };

  const handleHotelTitle = (hotel: string, htl_id_travel_click: string) => {
    setSelectedHotel(hotel);
    setInputValue(hotel);
    setIdHotel(htl_id_travel_click);
    setSelectedCity("");
    setTravelSlug("");
    setIsDropdownOpen(false);
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    filterBoxSearch(value);
  };

  const filterBoxSearch = (value: any) => {
    const filtered = boxsearch
      .map((item: any) => {
        const titleMatch = item.titulo
          .toLowerCase()
          .includes(value.toLowerCase());
        const hotelMatches = item.hoteles.filter((hotel: any) => {
          const hotelTitleMatch = hotel.titulo
            .toLowerCase()
            .includes(value.toLowerCase());
          // const hotelDescriptionMatch = hotel.descripcion
          //   ? hotel.descripcion.toLowerCase().includes(value.toLowerCase())
          //   : false;
          return hotelTitleMatch;
        });
        return {
          ...item,
          hoteles: hotelMatches,
          shouldShowCity: titleMatch || hotelMatches.length > 0,
        };
      })
      .filter((item: any) => item.shouldShowCity);

    setFilteredBoxSearch(filtered);
  };

  const handleButtonBox = () => {
    const id = idHotel;
    const city = travelSlug;
    const code = codeagencies ?? inputCode;
    const currency = currencyAgency ?? coin;
    const formatDate = (date: Date | null) => {
      if (!date) return "";
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${month}/${day}/${year}`;
    };

    const dateIn = formatDate(startDate);
    const dateOut = formatDate(endDate);

    const adults = adultsAgency ? `&adults=${adultsAgency}` : "";
    const theme = themeCode ? `&theme_code=${themeCode}` : "";

    const bookingLink = () => {
      const maintLink = validateTravelClickIsInMainteinnace()
      if(maintLink) return maintLink;

      if (id) {
        return `https://bookings.casa-andina.com/${id}?LanguageID=2&datein=${encodeURIComponent(
          dateIn
        )}&dateout=${encodeURIComponent(
          dateOut
        )}&identifier=${code}&currency=${currency}${adults}${theme}`;
      }

      if (city) {
        return `https://search.casa-andina.com/CAN/${city}?LanguageID=2&datein=${encodeURIComponent(
          dateIn
        )}&dateout=${encodeURIComponent(
          dateOut
        )}&identifier=${code}&currency=${currency}${adults}${theme}`;
      }
    };

    const link = bookingLink();
    if (link) {
      window.open(link, "_blank");
      close?.();
    } else {
      handleBoxSearch();
    }
  };

  const formattedText = (text: string) => {
    return text.replace(/\b(select|standard|premium)\b/gi, (match) => {
      if (match.toLowerCase() === "select") {
        return `<span class="select">${match}</span>`;
      }
      if (match.toLowerCase() === "standard") {
        return `<span class="standard">${match}</span>`;
      }
      if (match.toLowerCase() === "premium") {
        return `<span class="premium">${match}</span>`;
      }
      if (match.toLowerCase() === "asociados") {
        return `<span class="asociados">${match}</span>`;
      }
      return match;
    });
  };

  const [isLoadingPrices, setIsLoadingPrices] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleContainerClick);

    return () => {
      document.removeEventListener("click", handleContainerClick);
    };
  }, []);

  useEffect(() => {
    if (idHotel && coin) {
      setIsLoadingPrices(true);
      getPriceBox({ htl_id: idHotel, coin, setLoadingPrices }).finally(() => {
        setIsLoadingPrices(false);
      });
    }
  }, [idHotel, isDropDateOpen, coin, showCalendar]);

  useEffect(() => {
    setIsLoadingPrices(true);
    setIsLoadingPrices(false);
  }, []);

  return (
    <>
      {searchMobile ? (
        <div className="searchboxgeneral">
          <div className="searchboxgeneral__container">
            {showCalendar ? (
              <>
                <div className="searchboxgeneral__container--close">
                  <Icon
                    name="close"
                    list="items"
                    width={20}
                    height={20}
                    onClick={() => {
                      setShowCalendar(false);
                    }}
                  />
                </div>
                <div className="searchboxgeneral__container--calendardate">
                  <div className="calendarmonths">
                    <Calendar
                      disabledDates={price}
                      isMonths={true}
                      selectedRange={selectedRange}
                      setSelectedRange={setSelectedRange}
                      onDateSelect={handleDateSelect}
                      hiddenPrices={hiddenPrices}
                    />
                  </div>
                  <div
                    className="icongold"
                    onClick={() => {
                      setShowCalendar(false);
                    }}
                  >
                    Listo
                  </div>
                </div>
              </>
            ) : (
              <div className="searchboxgeneral__container">
                <div className="searchboxgeneral__container--close">
                  <Icon
                    name="close"
                    list="items"
                    width={20}
                    height={20}
                    onClick={close}
                  />
                </div>
                <div className="searchboxgeneral__container--hotels">
                  <div className="searchboxgeneral__container--hotels--search">
                    <div className="searchboxgeneral__container--place">
                      <Icon name="place" list="items" className="place" />
                      <input
                        type="search"
                        placeholder="Encuentra tu hotel ideal"
                        autoComplete="off"
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={handleBoxSearch}
                      />
                      {isDropdownOpen && (
                        <div className="buscadormobile" ref={searchRef}>
                          <div className="buscadormobile__content">
                            {filteredBoxSearch?.length === 0 ? (
                              <div className="no-message">
                                No se encontraron resultados.
                              </div>
                            ) : (
                              filteredBoxSearch.map(
                                (item: any, index: number) => (
                                  <>
                                    {item.shouldShowCity && (
                                      <ul key={index}>
                                        <li
                                          className="border"
                                          onClick={() =>
                                            handleCityTitle(item)
                                          }
                                        >
                                          <Text
                                            html={{
                                              __html: formattedText(
                                                item.titulo
                                              ),
                                            }}
                                            style={{ fontWeight: 700 }}
                                          />
                                        </li>

                                        <ul>
                                          {item.hoteles.map((hotel: any) => (
                                            <ol
                                              className="border"
                                              onClick={() =>
                                                handleHotelTitle(
                                                  hotel.titulo,
                                                  hotel.htl_id_travel_click
                                                )
                                              }
                                            >
                                              <Text
                                                html={{
                                                  __html: formattedText(
                                                    hotel.titulo
                                                  ),
                                                }}
                                              />
                                            </ol>
                                          ))}
                                        </ul>
                                      </ul>
                                    )}
                                  </>
                                )
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="searchboxgeneral__container--calendar">
                  <div
                    className="searchboxgeneral__container--start"
                    onClick={handleCalendarClick}
                  >
                    <div className="startdate">
                      <Icon
                        name="calendar"
                        list="items"
                        className="place"
                        width={20}
                        height={20}
                      />
                      {startDate && (
                        <div className="startdate__content">
                          <div className="startdate__content--day">
                            {formatDateParts(startDate).dayNumber}
                          </div>
                          <div className="startdate__content--month">
                            <div className="month">
                              {formatDateParts(startDate).month}{" "}
                              {formatDateParts(startDate).year}
                            </div>
                            <div className="day">
                              {" "}
                              {formatDateParts(startDate).day}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="searchboxgeneral__container--end"
                    onClick={handleCalendarClick}
                  >
                    <div className="startend">
                      <Icon
                        name="calendar"
                        list="items"
                        className="place"
                        width={20}
                        height={20}
                      />
                      {endDate && (
                        <div className="startend__content">
                          <div className="startend__content--day">
                            {formatDateParts(endDate).dayNumber}
                          </div>
                          <div className="startend__content--month">
                            <div className="month">
                              {formatDateParts(endDate).month}{" "}
                              {formatDateParts(endDate).year}
                            </div>
                            <div className="day">
                              {formatDateParts(endDate).day}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="searchboxgeneral__container--coupon">
                  <input
                    type="text"
                    placeholder="Ingresa tu código"
                    autoComplete="off"
                    value={inputCode || codeagencies}
                    onChange={(e) => setInputCode(e.target.value)}
                  />
                </div>
                <div className="searchboxgeneral__container--button">
                  <div className="icongold" onClick={handleButtonBox}>
                    Buscar
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="searchboxgeneral2">
          <div className="searchboxgeneral2__container">
            {showCalendar ? (
              <div className="searchboxgeneral2__container--calendardate"></div>
            ) : (
              <>
                <div className="searchboxgeneral2__container--hotels">
                  <div className="searchboxgeneral2__container--hotels--search">
                    <div className="searchboxgeneral2__container--place">
                      <Icon name="place" list="items" className="place" />
                      <input
                        type="search"
                        placeholder={t("calendar.findYourIdealHotel")}
                        autoComplete="off"
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={handleBoxSearch}
                      />
                      {isDropdownOpen && (
                        <div className="buscadormobile" ref={searchRef}>
                          <div className="buscadormobile__content">
                            {filteredBoxSearch.map(
                              (item: any, index: number) => (
                                <Fragment key={index}>
                                  {item.shouldShowCity && (
                                    <ul>
                                      <li
                                        className="border"
                                        onClick={() =>
                                          handleCityTitle(item)
                                        }
                                      >
                                        <Text
                                          html={{
                                            __html: formattedText(item.titulo),
                                          }}
                                          style={{ fontWeight: 700 }}
                                        />
                                      </li>

                                      <ul>
                                        {item.hoteles.map(
                                          (hotel: any, index: number) => (
                                            <ol
                                              key={index}
                                              className="border"
                                              onClick={() =>
                                                handleHotelTitle(
                                                  hotel.titulo,
                                                  hotel.htl_id_travel_click
                                                )
                                              }
                                            >
                                              <Text
                                                html={{
                                                  __html: formattedText(
                                                    hotel.titulo
                                                  ),
                                                }}
                                              />
                                            </ol>
                                          )
                                        )}
                                      </ul>
                                    </ul>
                                  )}
                                </Fragment>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: 4,
                    backgroundColor: "#F2F2F2",
                    height: "100%",
                    minHeight: 46,
                  }}
                />
                <div className="searchboxgeneral2__container--calendar">
                  <div className="searchboxgeneral2__container--containerstartend">
                    <div
                      className="searchboxgeneral2__container--start"
                      onClick={handleDropDate}
                    >
                      <div className="startdate">
                        <Icon
                          name="calendar"
                          list="items"
                          className="place"
                          width={20}
                          height={20}
                        />
                        {startDate && (
                          <div className="startdate__content">
                            <div className="startdate__content--day">
                              {formatDateParts(startDate).dayNumber}
                            </div>
                            <div className="startdate__content--month">
                              <div className="month">
                                {formatDateParts(startDate).month}{" "}
                                {formatDateParts(startDate).year}
                              </div>
                              <div className="day">
                                {" "}
                                {formatDateParts(startDate).day}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="line" />
                    <div
                      className="searchboxgeneral2__container--end"
                      onClick={handleDropDate}
                    >
                      <div className="startend">
                        <Icon
                          name="calendar"
                          list="items"
                          className="place"
                          width={20}
                          height={20}
                        />
                        {endDate && (
                          <div className="startend__content">
                            <div className="startend__content--day">
                              {formatDateParts(endDate).dayNumber}
                            </div>
                            <div className="startend__content--month">
                              <div className="month">
                                {formatDateParts(endDate).month}{" "}
                                {formatDateParts(endDate).year}
                              </div>
                              <div className="day">
                                {formatDateParts(endDate).day}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {isDropDateOpen && (
                    <div
                      className="searchboxgeneral2__container--calendardate"
                      ref={dateRangerRef}
                    >
                      <Calendar
                        disabledDates={price}
                        isMonths={false}
                        onDateSelect={handleDateSelect}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                        hiddenPrices={hiddenPrices}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: 4,
                    backgroundColor: "#F2F2F2",
                    height: "100%",
                    minHeight: 46,
                  }}
                />

                <div className="searchboxgeneral2__container--coupon">
                  <input
                    type="text"
                    placeholder={t("calendar.enterYourCode")}
                    autoComplete="off"
                    value={inputCode || codeagencies}
                    onChange={(e) => setInputCode(e.target.value)}
                  />
                </div>

                <div className="searchboxgeneral2__container--button">
                  <div className="icongold" onClick={handleButtonBox}>
                    {t("calendar.search")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Searchboxgeneral.defaultProps = {
  searchMobile: true,
};

export default Searchboxgeneral;
