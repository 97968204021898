const place = 'Valle Sagrado'

export const ptMessages = {
  // Language
  spanish: 'Espanhol',
  english: 'Inglês',
  portuguese: 'Português',

  // Slugs
  slugdestinations: 'destinacoes',
  slughotels: 'hoteis',
  slugrestaurants: 'restaurantes',
  slugoffers: 'ofertas',
  slugevents: 'eventos',
  slugenterprises: 'empresas',
  slugbest_price: 'melhores-precos',

  // Sections
  description: 'Descrição',
  hotels: 'Hotéis',
  restaurants: 'Restaurantes',
  questions: 'Perguntas',
  place: 'Lugar',
  schedule: 'Horário',
  shift: 'Turnos',
  prices: 'Preços',

  // NavBar
  navBarBook: 'Reserva',
  navBarDestinations: 'Destinações',
  navBarHotels: 'Hotéis',
  navBarRestaurants: 'Restaurantes',
  navBarCyberCa: 'Cyber CA',
  navBarOffers: 'Ofertas',
  navBarLife: 'Life',
  navBarCorporate: 'Empresa',
  navBarEvents: 'Eventos',

  // Buttons
  buttonreservar: 'Reservar',
  buttonBanner: 'Registre-se gratuitamente',
  buttonHotels: 'Ver hotéis',
  buttonHotel: 'Ver hotel',
  buttonBackToTop: 'Voltar ao topo',

  // Section Description
  descriptionTitle: 'Descubra nosso destino',
  descriptionToogleMore: 'Ver mais',
  descriptionToogleLess: 'Ver menos',
  showless: 'Mostrar menos',
  showmore: 'Mostrar mais',

  // Section Activities
  activitiesTitle: 'Razões para nos escolher quando viajar no Peru',
  activitiesKnowLess: 'saber menos',

  // Sections Better Price
  betterPriceTitle: 'Os melhores preços do momento',

  // Sections Discounts
  discountsTitle: 'Aproveite os nossos descontos',

  // Section Everything Peru
  everythingPeruTitle: 'Conheça tudo o que temos para si, conheça o Peru.',

  // Sections Banners
  bannerTitle:
    'Ainda não é um parceiro? Torne-se nosso parceiro de Life e resgate noites grátis.',

  // Section Hotel
  sortAndFilter: 'Ordenar e filtrar',
  delete: 'Excluir',
  sortBy: 'Ordenar por',
  sortByRecommended: 'Recomendadas',
  sortByPriceLowToHigh: 'Desconto (do menor para o maior)',
  sortByPriceHighToLow: 'Desconto (do maior para o menor)',
  sortByTopRated: 'Pontuação em destaque',

  filterBy: 'Filtrar por',
  searchByName: 'Buscar por nome',
  chooseHotelOrDestination: 'Escolha um hotel ou destino',
  pricePerNight: 'Preço por noite',
  pricePerNightWithoutTax: 'Preço por noite sem impostos a partir de',
  travelType: 'Tipo de viagem',
  hotelCategory: 'Categoria do hotel',
  experiences: 'Experiências',
  services: 'Serviços',
  ready: 'Pronto',

  // Section Hotels
  hotelsTitle: 'Nossos hotéis em',
  hotelsReview: 'Avaliações',
  hotelReview: 'Avaliação',
  hotelsPriceFrom: 'A partir de',
  hotelsPricesIgv: 'incluindo impostos e taxas de serviço',
  ourServices: 'Nossos serviços',
  ourHouseServices: 'Nossos serviços de nossa casa',
  moreAmenities: 'Mais comodidades',
  ourRoomsAndSuites: 'Nossos quartos e suítes',
  enjoyPlaceInOurHouse: `Aproveite seu ${place} em nossa casa`,
  maximizeWithBestPromotions:
    'Maximize com as melhores promoções de nossa casa',
  activitiesInCity: `Atividades no ${place}, acrescente momentos de alegria e diversão à sua viagem`,
  guestReviews: 'O que nossos hóspedes dizem',
  rating: 'Avaliação',
  excelent: 'Excelente',
  discoverOurPrices: 'Conheça todos os nossos melhores preços do momento',
  allOurDestinations: 'Todos os nossos destinos',
  discoverOurDestination:
    'Conheça todos os nossos melhores destinos do momento',
  discoverOurHotels: 'Conheça nossos hotéis',

  // Section Restaurants
  learnMore: 'saber mais',
  restaurantsTitle: 'Visite nossos restaurantes',
  exploreOurRestaurants: 'Conheça nossos restaurantes',
  exploreOurRestaurantsIn: 'Conheça nossos restaurantes em',
  discoverPeruvianCulinaryRichness: 'Descubra a riqueza culinária do Peru',
  enjoyOurPromotions: 'Aproveite nossas promoções',

  // Section Promotions
  day: 'Dia',
  days: 'Dias',
  hour: 'Hora',
  hours: 'Horas',
  minute: 'Minuto',
  minutes: 'Minutos',
  second: 'Segundo',
  seconds: 'Segundos',
  comingSoon: 'Falta pouco dias para o nosso',
  specialPackagesForYou:
    'Pacotes especiais para você, viaje e viva experiências com a Casa Andina',
  enjouyAndTreatYourself: 'Aproveite e se delicie experimentando o melhor',
  findTheBestDiscounts: 'Encontre o melhor desconto para você',
  discountsyourBanks: 'Descontos com seus bancos',
  getToKnowPerufirst: 'Conheça primeiro o Peru, aproveite nossos descontos',

  // Section Explore
  exploreTitle: 'Explore além de',
  exploreHotel: 'Hotel',
  exploreHotels: 'Hotéis',
  exploreTitle2: 'Descubra o Peru em cada destino',

  // Section Better Experience
  betterExperienceTitle: 'Viva a melhor experiência nos nossos hotéis ',

  // Section Questions
  questionsTitle: 'Perguntas frecuentes sobre',
  questionsSubtitle: 'Respondemos suas perguntas',

  // Page
  morethan: 'Mais de',

  // Page Destinations City
  pageDestinationsCityTitle: 'destinos para si',
  pageDestinationsCityDescription: 'O seu hotel ideal, a sua escolha',

  // Page Hotels
  pageHotelsTitle: 'hotéis para conhecer',

  // Section Footer
  footerFollowUS: 'Siga-nos nas redes sociais',
  footerPayment: 'Métodos de pagamento',
  footerCustomerService: 'Atendimento ao cliente',
  footerCalUsForFree: 'Ligue para nós gratuitamente',
  footerContactUs: 'Entre em contato',
  footerTermsAndConditions: 'Termos e condições',
  footerSmv:
    '(SMV) Acesse informações públicas sobre o emissor divulgadas no Portal do Mercado de Valores.',
  footerPrivacyPolicy: 'Política de privacidade',
  footerAllRightsReserved: 'Todos os direitos reservados - Casa Andina',

  //Quienes somos
  quienessomosuno:
    'We are a Peruvian hotel chain founded in February 2003, focused on providing our guests with a unique travel experience. By integrating the characteristics of each destination, from architecture to gastronomy, activities, and music, we create memorable moments for our visitors.',
  quienessomosdos: '20 Anos de História: "O Caminho da Casa Andina"',
  quienessomostres: 'Guiados por um Propósito',
  quienessomoscuatro: 'Missão',
  quienessomoscinco:
    'Criamos experiências autênticas que vão além do que o viajante espera.',
  quienessomosseis: 'Visão',
  quienessomossiete:
    'Ser a cadeia de hotéis peruana referência no mundo, graças aos nossos colaboradores, excelência no serviço e resultados extraordinários.',
  quienessomosocho: 'Os Valores que nos Definem',
  quienessomosnueve: 'Cultura',
  quienessomosnueve2: 'de Integridade',
  quienessomosdiez: 'Vocação',
  quienessomosdiez2: 'para o Serviço',
  quienessomosonce: 'Trabalho',
  quienessomosonce2: 'em Equipe',
  quienessomosdoce: 'Atenção aos Detalhes',
  quienessomostrece: 'Espírito de Superação',
  quienessomoscatorce: 'Por Que Nos Escolher',
  quienessomosquince:
    'Casa Andina vai além de ser uma cadeia de hotéis; somos sua conexão autêntica com o Peru.',
  quienessomosdieciseis: 'Variedade para Todos os Gostos',
  quienessomosdiecisiete:
    'Com 5 tipos de hotéis, adaptamos nossas ofertas às necessidades, garantindo que cada visita seja exatamente o que desejam.',
  quienessomosdieciocho: 'Equipe Apaixonada',
  quienessomosdiecinueve:
    'Somos cerca de 1000 colaboradores unidos pela paixão pelo serviço, boa energia e amor pelo Peru.',
  quienessomosveinte: 'Líderes no Peru',
  quienessomosveintiuno:
    'Com mais de 19 anos, somos a principal cadeia de hotéis do país, proporcionando não apenas quartos, mas uma experiência incomparável.',
  quienessomosveintidos:
    '¨Quando pensar em viajar pelo Peru, pense na Casa Andina¨',
  quienessomosveintitres: 'Nosso Time',
  quienessomosveinticuatro: 'Nossos Reconhecimentos',
  quienessomosveinticinco: 'Nossas Conquistas',

  // Responsabilida Social
  responsabilidadSocialuno: 'PERU CHAMPS',
  responsabilidadSocialdos: 'Qual solução o Peru Champs oferece?',
  responsabilidadSocialtres:
    'Ele identifica estudantes de baixa renda com alto talento acadêmico, artístico ou esportivo e concede bolsas de estudo para que recebam uma educação de excelência* e se tornem futuros líderes de um Peru sustentável.',
  responsabilidadSocialcuatro:
    'Com o apoio da Casa Andina, a educação de milhares de crianças de baixa renda com talentos excepcionais continua sendo aprimorada.',
  responsabilidadSocialcinco: 'Por que doar para o Peru Champs?',
  responsabilidadSocialseis:
    'Porque eles possuem um modelo educacional único e de alto impacto que concedeu aproximadamente 3.000 bolsas de estudo, com a meta de conceder 1.000 a cada ano.',
  responsabilidadSocialsiete:
    'Além disso, para garantir seu desenvolvimento abrangente, oferecem 9 atividades de liderança, como mentoria, coaching, empreendedorismo, entre outras. Eles não apenas fornecem bolsas de estudo, mas também fortalecem as habilidades interpessoais dos Champs.',
  responsabilidadSocialocho: 'Saiba mais em',

  // eventos
  eventosUno: 'Eventos',
  eventosDos: 'ORGANIZE SEU EVENTO CONOSCO',
  eventosTres: 'SALA',
  eventosCuatro: 'DESTINOS',
  eventosCinco: 'Café da manhã',
  eventosSeis: 'Coffee break',
  eventosSiete: 'Almoços/jantar',
  eventosOcho: 'Coquetel',
  eventosNueve: 'Brunch/Tea Time',
  eventosDiez: 'Box lunch',
  eventosOnce: 'Cardápio fixo/buffet',
  eventosDoce: 'Bar aberto',
  eventosTrece: 'Preencha o formulário para entrar em contato',
  eventosCatorce: 'Selecione o tipo de evento',
  eventosQuince: 'Evento Corporativo',
  eventosDieciséis: 'Evento Social',
  eventosDiecisiete: 'Nome',
  eventosDieciocho: 'Celular',
  eventosDiecinueve: 'E-mail',
  eventosVeinte: 'CNPJ (Cadastro Nacional da Pessoa Jurídica)',
  eventosVeintiuno: 'Nome da Empresa',
  eventosVeintidós: 'Data do Evento',
  eventosVeintitrés: 'dd/mm/aaaa',
  eventosVeinticuatro: 'Duração do Evento',
  eventosVeinticinco: 'Alimentação necessária',
  eventosVeintiséis: 'Alimentação necessária',
  eventosVeintisiete: 'Capacidade de pessoas',
  eventosVeintiocho: 'Número de pessoas (de 15 a 300 pessoas)',
  eventosVeintinueve: 'Selecione o destino de seu interesse',
  eventosTreinta:
    'Li e aceito o envio de publicidade e promoções, e a política de proteção de dados pessoais',
  eventosTreintaYuno: 'Solicitar informações',
  eventosTreintaYdos: 'Por que escolher celebrar seu evento conosco?',
  eventosTreintaYtres: 'Cobertura nacional e localização estratégica',
  eventosTreintaYcuatro: 'Mais de 65 salas em 10 destinos',
  eventosTreintaYcinco: '1 contato para eventos em todo o país',
  eventosTreintaYseis: 'Tarifas especiais',
  eventosTreintaYsiete: 'Opções audiovisuais',
  eventosTreintaYocho: 'Facilidades de pagamento',
  eventosTreintaYnueve: 'Alimentação',
  eventosCuarenta: 'Eventos do Seu Jeito com a Casa Andina',
  eventosCuarentaYuno: 'Escolha a sua sala',
  eventosCuarentaYdos:
    'Na Casa Andina, queremos oferecer a você a melhor experiência para você e todos os seus convidados. Temos a melhor infraestrutura e um serviço que supera as expectativas com salas modernas que se adaptam às suas necessidades.',
  eventosCuarentaYtres: 'Montagem da sala',
  eventosCuarentaYcuatro: 'Escolha seus equipamentos',
  eventosCuarentaYcinco: 'Projetor',
  eventosCuarentaYseis: 'Tela',
  eventosCuarentaYsiete: 'Microfones',
  eventosCuarentaYocho: 'Sistema de som',
  eventosCuarentaYnueve: 'Laptop',
  eventosCincuenta: 'TV 75"',
  eventosCincuentaYuno: 'Alimentação',
  eventosCincuentaYdos: 'Café da manhã',
  eventosCincuentaYtres: 'Coffee break',
  eventosCincuentaYcuatro: 'Almoço/jantar',
  eventosCincuentaYcinco: 'Coquetel',
  eventosCincuentaYseis: 'Brunch/Tea Time',
  eventosCincuentaYsiete: 'Box lunch',
  eventosCincuentaYocho: 'Cardápio fixo/buffet',
  eventosCincuentaYnueve: 'Bar Aberto',
  eventosSesenta: 'Quer conhecer todas as opções para o seu evento?',
  eventosSesentaYuno:
    'Conheça as opções para o seu evento, reuniões e convidados.',
  eventosSesentaYdos: 'Solicitar informações',

  // reserva-agente
  reservasAgentesUno: 'Insira seu código de agência para continuar navegando',
  reservasAgentesDos: 'Insira seu código',
  reservasAgentesTres: 'Enviar',
  reservasAgentesCuatro: 'JÁ ESTAMOS ABERTOS',
  reservasAgentesCinco: 'HOTEL PARACAS',
  reservasAgentesSeis:
    'Reserve agora e ofereça aos seus clientes uma estadia inesquecível!',
  reservasAgentesSiete: 'Escolha um destino, hotel',
  reservasAgentesTrece:
    'Por favor, observe que apenas até 3 adultos e 2 crianças menores de 10 anos podem ficar por quarto, até um máximo de pessoas.',
  reservasAgentesCatorce: 'QUARTO 1',
  reservasAgentesQuince: 'Adultos',
  reservasAgentesDieciséis: 'Crianças',
  reservasAgentesDiecisiete: 'Adicionar quartos',
  reservasAgentesDieciocho: 'Continuar',
  reservasAgentesDiecinueve: 'Insira seu código',
  reservasAgentesVeinte: 'Reservar',
  reservasAgentesVeintiuno: 'Cancelar reserva',
  reservasAgentesVeintidós:
    'Junte-se ao nosso canal de transmissão, onde compartilhamos novidades e ferramentas úteis. Clique aqui para participar.',
  reservasAgentesVeintitrés: 'Participar do grupo',
  reservasAgentesVeinticuatro:
    'Explore Conosco: Fichas Técnicas, Menus e Datas',
  reservasAgentesVeinticinco: 'Fichas técnicas',
  reservasAgentesVeintiséis: 'Ver',
  reservasAgentesVeintisiete: 'Menus turísticos',
  reservasAgentesVeintiocho: 'Ver',
  reservasAgentesVeintinueve: 'Datas especiais',
  reservasAgentesTreinta: 'Ver',
  reservasAgentesTreintaYuno: 'Fotos dos nossos hotéis Casa Andina',
  reservasAgentesTreintaYdos: 'Ver',

  //dog friendly
  dogFriendlyUno: 'Dog Friendly',
  dogFriendlyDos: 'POLÍTICAS DE ADMISSÃO E CUIDADOS COM ANIMAIS DE ESTIMAÇÃO',
  dogFriendlyTres:
    'Sabemos que os animais de estimação são uma parte importante da família. Para garantir que sua estadia e a de seu animal de estimação sejam o mais agradáveis possível e não causem nenhum inconveniente para você e para os demais hóspedes, é necessário que você conheça e aceite as Políticas de Admissão e Cuidados com Animais de Estimação na CASA ANDINA, detalhadas a seguir:',
  dogFriendlyCuatro:
    'A CASA ANDINA permite a entrada de hóspedes acompanhados de seus cães (Animais de Estimação), desde que as políticas de admissão atuais sejam seguidas.',
  dogFriendlyCinco:
    'A entrada de gatos é permitida; no entanto, os artigos oferecidos para a estadia são para cães (não inclui caixa de areia para gatos ou qualquer item adicional para gatos, apenas equipamentos amigáveis para cães).',
  dogFriendlySeis:
    'Reservas Dog Friendly podem ser feitas através do departamento de Reservas, Contact Center, canais online e presencialmente.',
  dogFriendlySiete:
    'De preferência, a reserva deve ser solicitada com antecedência de 2 dias úteis.',
  dogFriendlyOcho:
    'Somente a entrada de dois animais de estimação por quarto será permitida.',
  dogFriendlyNueve:
    'Os Hotéis Associados Fundo San José Parque Ecológico & Lodge, Empedrada Ranch & Lodge, Maresta, Hotel Andino Club, El Sauce Resort, Hotel Presidente Huancayo, Hotel Presidente Huancavelica, Irapay Amazon Lodge, AltaVista CasaHotel, La Xalca Hotel e La Ensenada não são pet friendly.',
  dogFriendlyDiez:
    'No momento da reserva, o hóspede deve fornecer certificados emitidos por um veterinário licenciado (ou órgão governamental competente) comprovando as vacinações e desparasitações atualizadas do seu animal de estimação, com uma antiguidade não superior a 1 ano. É necessário portar esses certificados durante a estadia no hotel e apresentá-los se solicitado pela equipe da CASA ANDINA.',
  dogFriendlyOnce:
    'No caso de cães guias, deve-se apresentar o certificado do CONADIS, comprovando que é necessária a companhia do cão (deficiência visual, apoio emocional, medição de glicose ou outro tipo). Se a certificação do CONADIS não estiver disponível, o hóspede assumirá o custo por noite da estadia do seu animal de estimação.',
  dogFriendlyDoce:
    'Somente são permitidos animais de estimação com peso máximo de 18 quilos.',
  dogFriendlyTrece:
    'Não será admitida a entrada de raças potencialmente perigosas, conforme a legislação peruana, como American Pitbull, fila brasilera, tosa japonesa, bullmastiff, doberman e rottweiler, bem como aqueles treinados para lutas ou que tenham participado delas, aqueles com histórico de agressividade contra pessoas e que não possam garantir sua sociabilidade, temperamento ou caráter.',
  dogFriendlyCatorce:
    'A entrada do seu animal de estimação tem um custo de US$ 49.00 (incluindo impostos e serviços) por noite. O preço é por animal de estimação.',
  dogFriendlyQuince:
    'Deverá ser deixado como garantia o valor correspondente a uma noite de estadia faturada por quarto. A CASA ANDINA não se responsabilizará pela alimentação do seu animal de estimação. Durante a estadia, o seu animal de estimação poderá usar, em condições de empréstimo, o seguinte: (i) cama para cães; (ii) comedouros; e (iii) tapete. A CASA ANDINA presenteia, durante a estadia do animal, (i) um chaveiro com sacos descartáveis; e (ii) almofadas absorventes.',
  dogFriendlyDieciséis:
    'O seu animal de estimação deve fazer suas necessidades nas almofadas absorventes colocadas no banheiro do quarto. Em qualquer outro caso, você será responsável por recolher os resíduos deixados pelo seu animal de estimação e deve informar imediatamente a equipe de limpeza da CASA ANDINA. Não é permitido o uso da roupa de cama do hotel para recolher resíduos e para a higiene do seu animal de estimação.',
  dogFriendlyDiecisiete:
    'O seu animal de estimação deve permanecer sempre em sua companhia durante a estadia na CASA ANDINA. Não é permitido que o seu animal de estimação suba nas camas, sofás, poltronas e outros móveis dentro do quarto. Da mesma forma, não é permitido deixar o seu animal de estimação sozinho no quarto ou em qualquer área comum da CASA ANDINA, a equipe do restaurante o colocará nas mesas designadas para esse programa.',
  dogFriendlyDieciocho:
    'Nossos restaurantes são pet-friendly, portanto, você pode trazer seu animal de estimação para as áreas sob supervisão.',
  dogFriendlyDiecinueve:
    'Quando transitar pelas áreas comuns do hotel, o seu animal de estimação deve estar sempre com coleira.',
  dogFriendlyVeinte:
    'Você será o único responsável pelo cuidado e comportamento do seu animal de estimação, devendo evitar latidos, ruídos e comportamentos que possam causar desconforto aos demais hóspedes.',
  dogFriendlyVeintiuno:
    'Qualquer dano ou incidente causado pelo seu animal de estimação a qualquer pessoa será integralmente assumido por você, e você deverá arcar com todas as despesas e custos de reparação, incluindo, mas não se limitando a despesas médicas das pessoas afetadas. As despesas e custos incorridos pela CASA ANDINA deverão ser reembolsados imediatamente por você.',
  dogFriendlyVeintidós:
    'Os danos causados pelo seu animal de estimação ao mobiliário dentro do quarto e em outras instalações da CASA ANDINA serão integralmente assumidos por você no momento do Check Out, levando em consideração o seguinte:',
  dogFriendlyVeintitrés:
    '-US$75 por danos a cada móvel ou utensílio doméstico.',
  dogFriendlyVeinticuatro:
    '-US$90 por danos a roupa de cama (lençóis, travesseiros, toalhas, etc.) e outros bens.',
  dogFriendlyVeinticinco: '-US$350 por danos a cada colchão.',
  dogFriendlyVeintiséis:
    'O acima não impede que a CASA ANDINA exija o pagamento de valores mais altos se os bens danificados tiverem uma avaliação maior.',
  dogFriendlyVeintisiete:
    'Qualquer ato de maus-tratos ou crueldade contra seu animal de estimação, ou atos que coloquem em risco a saúde pública, são estritamente proibidos. De acordo com a legislação peruana, essas ações constituem crimes que podem ser punidos com prisão. A CASA ANDINA comunicará imediatamente essas ações às autoridades competentes.',
  dogFriendlyVeintiocho:
    'Os hóspedes que entrarem com cães guia devem apresentar certificados que atestem essa condição, emitidos por entidades competentes.',
  dogFriendlyVeintinueve:
    'Agradecemos e esperamos que você tenha uma estadia agradável na CASA ANDINA, acompanhado do seu animal de estimação.',

  //terminos

  terminosYcondicionesUno:
    'Termos e Condições de Reservas nos Hotéis Casa Andina',
  terminosYcondicionesDos: 'Termos do Contrato',
  terminosYcondicionesTres:
    'Prestadora de serviços de hospedagem.',
  terminosYcondicionesCuatro:
    'CLIENTE: Passageiro que utiliza os serviços da CASA ANDINA (maior de idade).',
  terminosYcondicionesCinco:
    'CHECK-IN: Processo de registro do cliente no hotel antes de entrar no quarto.',
  terminosYcondicionesSeis:
    'CHECK-OUT: Processo de registro de saída do cliente ao encerrar o uso dos serviços do hotel.',
  terminosYcondicionesSiete:
    'NO-SHOW: A não apresentação do passageiro no hotel antes das 23h59 no dia previsto de chegada, sem comunicação prévia do cliente.',
  terminosYcondicionesOcho:
    'RESERVA: Acordo entre a Casa Andina e o Cliente para reservar o(s) quarto(s) para as datas solicitadas pelo cliente em qualquer hotel da cadeia CASA ANDINA.',
  terminosYcondicionesNueve:
    'TAXA DE SERVIÇO: Sobretaxa de 10% sobre o consumo de serviços da Casa Andina.',
  terminosYcondicionesDiez:
    'IMPOSTO GERAL SOBRE VENDAS (IGV): Imposto correspondente a 18% da tarifa.',
  terminosYcondicionesOnce: 'Condições Gerais'
}
