import React from 'react'
import { IIconLink } from './props'
import { Icon } from 'components/atoms'

const IconLink = ({ iconName, itemList, linkIcon, className }: IIconLink) => {
  return (
    <a href={linkIcon} target='_blank' rel='noreferrer' className={className}>
      <Icon name={iconName} list={itemList} width={30} height={30} />
    </a>
  )
}

export default IconLink
