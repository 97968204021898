import React, { Fragment, useEffect, useState } from "react";
import { IHotel, IOption, IOptions } from "./props";
import Input from "components/atoms/input";
import "./styles.scss";
import { useDetectClickOutside } from "react-detect-click-outside";
import { messages } from "utils/lang";
import { useIntl } from "react-intl";
import Text from "../text";
import Icon from "../icon";

const SearchDropdown = ({
  options,
  setSelectedTitle,
  selectedTitle,
  inputValue,
  setInputValue,
}: IOptions) => {
  const { locale } = useIntl();

  const [isDropdownOpen, setIsDropdownOpen] = useState<Boolean>(false);
  const [filteredBoxSearch, setFilteredBoxSearch] = useState(options);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  const filterBoxSearch = (value: any) => {
    const filtered = options
      .map((item: any) => {
        const titleMatch = item.titulo
          .toLowerCase()
          .includes(value.toLowerCase());
        const hotelMatches = item.hoteles.filter((hotel: any) => {
          const hotelTitleMatch = hotel.htl_titulo
            ?.toLowerCase()
            .includes(value.toLowerCase());
          // const hotelDescriptionMatch = hotel.descripcion
          //   ? hotel.descripcion.toLowerCase().includes(value.toLowerCase())
          //   : false;
          return hotelTitleMatch;
        });
        return {
          ...item,
          hoteles: hotelMatches,
          shouldShowCity: titleMatch || hotelMatches.length > 0,
        };
      })
      .filter((item: any) => item.shouldShowCity);

    // console.log('filtered', filtered)

    setFilteredBoxSearch(filtered);
  };

  const handleCityTitle = (city: string) => {
    setInputValue(city);
    setSelectedTitle(city);
    setIsDropdownOpen(false);
  };

  const handleBoxSearch = () => {
    setIsDropdownOpen(!isDropdownOpen);
    filterBoxSearch("");
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    filterBoxSearch(value);
  };

  const formattedText = (text: string) => {
    return text?.replace(/\b(select|standard|premium)\b/gi, (match) => {
      if (match.toLowerCase() === "select") {
        return `<span class="select">${match}</span>`;
      }
      if (match.toLowerCase() === "standard") {
        return `<span class="standard">${match}</span>`;
      }
      if (match.toLowerCase() === "premium") {
        return `<span class="premium">${match}</span>`;
      }
      if (match.toLowerCase() === "asociados") {
        return `<span class="asociados">${match}</span>`;
      }
      return match;
    });
  };

  const handleHotelTitle = (hotel: string) => {
    setInputValue(hotel);
    setSelectedTitle(hotel);
    setIsDropdownOpen(false);
  };

  return (
    <div className="search-dropdown" ref={ref}>
      <div className="boxsearch">
        <Icon name="search" list="items" className="place" />
        <input
          type="search"
          placeholder="Encuentra tu hotel ideal"
          autoComplete="off"
          value={inputValue}
          onChange={handleInputChange}
          onClick={handleBoxSearch}
        />
      </div>
      {isDropdownOpen && (
        <div className="dropdown-list">
          {filteredBoxSearch.length === 0 ? (
            <div className="no-message">No se encontraron resultados.</div>
          ) : (
            filteredBoxSearch.map((item: any, index: number) => (
              <Fragment key={index}>
                {item.shouldShowCity && (
                  <ul>
                    <li
                      className="border"
                      onClick={() => handleCityTitle(item.titulo)}
                    >
                      <Text
                        html={{
                          __html: formattedText(item.titulo),
                        }}
                        style={{ fontWeight: 700 }}
                      />
                    </li>

                    <ul>
                      {item.hoteles.map((hotel: any, index: number) => (
                        <ol
                          key={index}
                          className="border"
                          onClick={() => handleHotelTitle(hotel.htl_titulo)}
                        >
                          <Text
                            html={{
                              __html: formattedText(hotel.htl_titulo),
                            }}
                          />
                        </ol>
                      ))}
                    </ul>
                  </ul>
                )}
              </Fragment>
            ))
          )}

          {/* {filteredHotels?.map((hotel: any, index: number) => (
            <li
              key={index}
              className={`ophoteles ${
                hotel === selectedOption ? 'selectedcity' : ''
              }`}
              onClick={() => handleHotelClick(hotel)}
            >
              <li className='bordero'>
                <Paragraph text={hotel.htl_titulo} family={'txfilter2'} />
              </li>
            </li>
          ))} */}
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
