import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setBestPrices } from "redux/actions/language";

interface IBestMoments {
  language?: string;
}

export const getBestPrices = async ({
  language
}: IBestMoments) => {
  try {
    const response = await Axios.request({
      method: "get",
      url: endpoints.mejoresmomentos,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setBestPrices(data)

    return data;

  } catch (error) {
    alert('No se cargo los datos de utils')
  }
}