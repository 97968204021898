import React, { useEffect } from "react";
import { Banner, BannerSlider } from "components/organisms";
import SectionCardsTypes from "components/organisms/section-cards-types";
import SectionCardGrid from "components/organisms/section-card-grid";
import Loader from "components/molecules/loader";
import { getRestaurants } from "actions/restaurants/services";
import { useAppSelector } from "hooks/useAppSelector";
import { useTranslation } from "react-i18next";

const Restaurants: React.FC = () => {
  const { t } = useTranslation();

  const {
    language,
    loading,
    data,
    coin,
    tipos_restaurantes,
    restaurantes,
    categoria,
    tira_banner,
  } = useAppSelector((state: any) => ({
    language: state.config.language,
    loading: state.language.loading,
    coin: state.config.coin,
    data: state.language.dataRestaurants,
    tipos_restaurantes: state.language.dataRestaurants?.tipos_restaurante || [],
    restaurantes: state.language.dataRestaurants?.restaurantes || [],
    categoria:
      state.language.dataRestaurants?.categoria_promocion_restaurante || [],
    tira_banner: state.language.dataRestaurants?.banners_tira || [],
  }));

  useEffect(() => {
    if (language && coin) {
      getRestaurants({ language, coin });
      window.scrollTo(0, 0);
    }
  }, [language, coin]);

  if (loading || !data) return <Loader />;

  return (
    <>
      {data.banners?.length > 0 && (
        <BannerSlider data={data?.banners} gradiente />
      )}

      {tipos_restaurantes.length > 0 && (
        <SectionCardsTypes
          data={tipos_restaurantes}
          type="tipos-restaurante"
          title={data?.subtitulos ? data?.subtitulos[0].subtitulo : t("restaurants.discoverOurRestaurants")}
        />
      )}

      {restaurantes.length > 0 && (
        <SectionCardsTypes
          data={restaurantes}
          type="hotel-direccion"
          title={data?.subtitulos ? data?.subtitulos[1].subtitulo : t("restaurants.discoverTheCulinaryRichnessOfPeru")}
        />
      )}

      {tira_banner.length > 0 && <Banner data={tira_banner} />}

      {categoria.length > 0 && (
        <SectionCardGrid
          data={categoria}
          type="restaurant-food"
          title={data?.subtitulos ? data?.subtitulos[2].subtitulo : t("restaurants.enjoyOurPromotions")}
        />
      )}

    </>
  );
};

export default Restaurants;
