import { BannerSlider } from 'components/organisms'
import PromotionsCard from 'components/organisms/promotions-card'
import PromotionsYTQP from 'components/organisms/promotions-ytqp'

const PromotionsCampaign = ({data, idFilter, skeleton}: any) => {
  return (
    <>
      <BannerSlider data={data.banners} />

      <PromotionsYTQP data={data} />

      <PromotionsCard
        title='Aprovecha nuestros descuentos'
        data={data.hoteles}
        bank={data.entidad}
        codigo={data.codigo}
        type='campaign'
        idFilter={idFilter}
        skeleton={skeleton}
        pT={32}
      />
    </>
  )
}

export default PromotionsCampaign
