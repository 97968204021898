
import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";

export const getMenuOferta = async ({
  language,
  setDataMenu
}: any) => {
    Axios.request({
      method: "get",
      url: endpoints.menuofertas,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const data = response.data;
      setDataMenu(data);
      return data;
    }).catch((err : unknown) => {
      console.log("[Actions - getMenuOferta] err : ", err);
      alert('No se pudo cargar menu')
    })

}
