import { useMemo } from "react";
import { Button, Paragraph, Text } from "components/atoms";
import { Banner } from "components/molecules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import { motion } from "framer-motion";

import "swiper/css";
import "swiper/css/pagination";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

interface BannerData {
  id: number;
  img_desktop: any;
  img_mobile: any;
  tipo_banner: {
    titulo: string;
  };
  titulo: string;
  subtitulo: string;
  subtitulo2?: string;
  description?: string;
  redireccion_boton: string;
  descuento?: string;
  subtitulo_2?: string;
  color_fondo_logo?: string;
  img_logos?: { path: string; alt?: string; name?: string }[];
  img_logo?: { path: string; alt?: string; name?: string };
  descripcion_boton?: string;
}

interface BannerSliderProps {
  banners: BannerData;
  textLarge?: boolean;
  onClick: () => void | undefined;
  bannerRef: React.RefObject<any> | undefined;
  gradiente: boolean | undefined;
}

const BannersTypes: React.FC<BannerSliderProps> = ({
  banners,
  textLarge,
  gradiente,
  bannerRef,
  onClick,
}) => {
  const navigate = useNavigate();
  const {
    titulo,
    subtitulo,
    subtitulo2,
    description,
    redireccion_boton: redireccionButton,
    tipo_banner,
  } = banners;

  // console.log("landings", textLarge)
  const bg =
    window.innerWidth > 768
      ? banners.img_desktop?.path
      : banners.img_mobile?.path || banners.img_desktop?.path;

  const handleRedirect = (redireccionButton: string) => {
    if (redireccionButton.startsWith("/")) {
      navigate(redireccionButton);
    } else if (
      redireccionButton.startsWith("http://") ||
      redireccionButton.startsWith("https://") ||
      redireccionButton.startsWith("www.")
    ) {
      const url = redireccionButton.startsWith("www.")
        ? `https://${redireccionButton}`
        : redireccionButton;
      window.open(url, "_blank");
    } else {
      console.log("No hay redirección necesaria");
    }
  };

  switch (tipo_banner?.titulo.toLowerCase()) {
    // BANNER: Subtitulo/título collage
    case "subtitulo/título collage": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocions"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
          gradiente={gradiente}
        >
          <div className="bannerpromocions__content">
            <Text
              type="paragraph"
              text={titulo}
              className="bannerpromocions__content--title"
            />
            <Text
              type="paragraph"
              text={subtitulo}
              className="bannerpromocions__content--subtitle"
            />
            {description && (
              <Text
                type="paragraph"
                text={description}
                className="bannerpromocions__content--description"
              />
            )}
          </div>
        </Banner>
      );
    }
    // BANNER: Descuento banco
    case "descuento banco": {
      return (
        <Banner
          bgImg={bg}
          className="bankdiscount"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
          gradiente={gradiente}
        >
          <div className="bankdiscount__content">
            <Text className={"title"} text={banners.titulo} />
            <div className="bankdiscount__content--box">
              <Text className="discount" text={banners.descuento} />
              <div className="bankdiscount__content--right">
                <Text className="percentage" text={"%"} />
                <Text className="dscto" text={"dscto."} />
              </div>
            </div>
          </div>
          <div className="bankdiscount__cardcredit">
            <Text className={"subtitle"} text={banners.subtitulo} />
            <div
              className="bankdiscount__cardcredit--cardcredit"
              style={{ backgroundColor: banners?.color_fondo_logo }}
            >
              <div className="bankdiscount__cardcredit--img">
                <img
                  src={banners.img_logos ? banners.img_logos[0]?.path : ""}
                  alt={banners.img_logos ? banners.img_logos[0]?.name : ""}
                />
              </div>
            </div>
          </div>
        </Banner>
      );
    }
    case "título/subtitulo logo centrado ": {
      return (
        <Banner
          bgImg={bg}
          className="bankdiscount"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
          gradiente={gradiente}
        >
          <div className="bankdiscount__content">
            <Text className={"title"} text={banners.titulo} />
            <div className="bankdiscount__content--box">
              <Text className="discount" text={banners.descuento} />
              <div className="bankdiscount__content--right">
                <Text className="percentage" text={"%"} />
                <Text className="dscto" text={"dscto."} />
              </div>
            </div>
          </div>
          <div className="bankdiscount__cardcredit">
            <Text className={"subtitle"} text={banners.subtitulo} />
            <div
              className="bankdiscount__cardcredit--cardcredit"
              style={{ backgroundColor: banners?.color_fondo_logo }}
            >
              <div className="bankdiscount__cardcredit--img">
                <img
                  src={banners.img_logos ? banners.img_logos[0].path : ""}
                  alt={banners.img_logos ? banners.img_logos[0].name : ""}
                />
              </div>
            </div>
          </div>
        </Banner>
      );
    }
    // BANNER: Subtitulo/título collage logos
    case "subtitulo/título collage logos": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocion"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
          gradiente={gradiente}
        >
          <div className="bannerpromocion__content">
            <Text
              className="bannerpromocion__content--title"
              text={banners.titulo}
            />

            <Text
              className="bannerpromocion__content--subtitle"
              text={banners.subtitulo}
            />
            <div className="bannerpromocion__content--box">
              {banners.img_logos &&
                banners.img_logos.map((items: any, index: number) => (
                  <div className="bannerimglogo" key={index}>
                    <img src={items?.path} alt={items?.alt} />
                  </div>
                ))}
            </div>
          </div>
        </Banner>
      );
    }
    // BANNER: Título/subtitulo collage logo
    case "título/subtitulo collage logo": {
      return (
        <Banner
          bgImg={banners.img_desktop?.path}
          className="bannerpromocionimg"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
        >
          <div className="bannerpromocionimg__content">
            <Text
              text={subtitulo2}
              type="paragraph"
              className="bannerpromocionimg__content--subtitle"
            />
            <Text
              text={titulo}
              type="paragraph"
              className="bannerpromocionimg__content--title"
            />
            <Text
              text={subtitulo}
              type="paragraph"
              className="bannerpromocionimg__content--subtitle"
            />

            {banners.img_logos && banners.img_logos.length > 0 && (
              <div className="bannerpromocionimg__content--box">
                {banners?.img_logos?.map((items: any, index: number) => (
                  <div className="bannerimglogo" key={index}>
                    <img src={items.path} alt={items.name} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Banner>
      );
    }
    // BANNER: Título/subtitulo botón
    case " título/subtitulo botón ":
      return (
        <Banner
          bgImg={bg}
          className="bannerdestinociudad"
          bannerRef={bannerRef}
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
        >
          <div className="bannerdestinociudad__content">
            <Text
              text={titulo}
              className="bannerdestinociudad__content--title"
              type="title"
            />
            <Text
              text={subtitulo}
              className="bannerdestinociudad__content--subtitle"
              type="paragraph"
            />
          </div>
          {redireccionButton && (
            <div className="bannerdestinociudad__button">
              <Button type="icongold" onClick={onClick}>
                {banners.descripcion_boton}
              </Button>
            </div>
          )}
        </Banner>
      );

    case "título centrado collage ": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocioncursive"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
        >
          <div
            className="bannerpromocioncursive__content"
            style={{ color: banners.color_fondo_logo || "white" }}
          >
            <Text
              className="bannerpromocioncursive__content--title"
              text={titulo}
              type="paragraph"
            />
            {banners.img_logos && (
              <div className="logo-ca">
                <img
                  src={banners.img_logos[0]?.path}
                  alt={banners.img_desktop[0]?.alt}
                />
              </div>
            )}
          </div>
        </Banner>
      );
    }
    // BANNER: Título logo
    case " título logo": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocionlogo"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
        >
          <div className="bannerpromocionlogo__content">
            <Text
              text={titulo}
              type="paragraph"
              className="bannerpromocionlogo__content--title"
            />

            <div className="bannerpromocionlogo__content--box">
              <img
                src={banners.img_logos ? banners.img_logos[0]?.path : ""}
                alt={banners.img_logos ? banners.img_logos[0]?.alt : ""}
              />
            </div>
          </div>
        </Banner>
      );
    }
    // BANNER: LANDINGS
    case "landings": {
      return (
        <Banner
          bgImg={banners.img_desktop?.path}
          className="bannerlandings"
          key={banners.id}
          gradiente={gradiente}
        >
          <div className="bannerlandings__content">
            <Text
              text={subtitulo2}
              type="paragraph"
              className="bannerlandings__content--subtitle"
            />
            <Text
              text={titulo}
              type="paragraph"
              className={
                textLarge
                  ? "bannerlandings__content--titleLarge"
                  : "bannerlandings__content--title"
              }
            />
            <Text
              text={subtitulo}
              type="paragraph"
              className="bannerlandings__content--subtitle"
            />
          </div>
        </Banner>
      );
    }

    case "texto izquierda": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocionsizquierda"
          key={banners.id}
        >
          <div className="bannerpromocionsizquierda__content">
            <Paragraph family={"txbannersliderdsct"} text={titulo} />
            <Paragraph family={"txbannerslider"} text={subtitulo} />
          </div>
        </Banner>
      );
    }
    case "centrado multiples imagenes": {
      return (
        <Banner
          bgImg={bg}
          className="bannerbancos"
          key={banners.id}
          gradiente={gradiente}
        >
          <div className="bannerbancos__content">
            <Paragraph family={"txbannersliderdsct"} text={banners.subtitulo} />
            <Paragraph family={"txbannerslider"} text={banners.titulo} />

            <div className="bannerbancos__content--content">
              {banners.img_logos &&
                banners.img_logos.map((items: any, index: number) => (
                  <div
                    className="box"
                    key={index}
                    style={{
                      backgroundColor: banners?.color_fondo_logo || "black",
                    }}
                  >
                    <div className="bannerimglogo">
                      <img src={items?.path} alt={items?.alt} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Banner>
      );
    }
    case "centrado titulo imagen": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocionimg"
          key={banners.id}
          gradiente={gradiente}
        >
          <div className="bannerpromocionimg__content">
            <Paragraph family={"txbannerslider"} text={banners.titulo} />

            <div className="bannerpromocionimg__content--box">
              {banners.img_logos &&
                banners.img_logos.map((items: any, index: number) => (
                  <div className="bannerimglogo" key={index}>
                    <img src={items?.path} alt={items.name} />
                  </div>
                ))}
            </div>
          </div>
        </Banner>
      );
    }
    case "restaurante": {
      return (
        <Banner
          bgImg={bg}
          className="bannerrestaurante"
          key={banners.id}
          gradiente={gradiente}
        >
          <div className="bannerrestaurante__content">
            <div className="logo-ca">
              <img
                src={banners.img_logos ? banners.img_logos[0]?.path : ""}
                alt={banners.img_logos ? banners.img_logos[0]?.alt : ""}
              />
            </div>
            <Paragraph family={"txbannersliderss"} text={banners.subtitulo} />
          </div>
        </Banner>
      );
    }
    case "otros": {
      return (
        <Banner
          bgImg={bg}
          className="bannercentrado"
          key={banners.id}
          gradiente={gradiente}
        >
          <div className="bannercentrado__content">
            <Paragraph family={"txbannerslider"} text={banners.titulo} />
            <div className="bannercentrado__content--box">
              <Paragraph family="ttbannerslider" text={banners.descuento} />
              <div className="right">
                <Paragraph family="txtbannerssliderprc" text={"%"} />
                <Paragraph family="txbannersliderdsct" text={"dscto."} />
              </div>
            </div>
          </div>
          <div className="bannercentrado__cardcredit">
            <Paragraph
              family={"txbannersliderdsct"}
              text={"Exclusivo con tu tarjeta"}
            />
            <div className="card-credit">
              <img
                src={banners.img_logo?.path}
                alt={banners.img_logo?.name}
                className="card-credit__img"
              />
            </div>
          </div>
        </Banner>
      );
    }
    /*****BANNER CON IMAGEN DE BANCO CENTRADO*****/
    case "solo imagen": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocion"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
        >
          <div className="bannerpromocion__content"></div>
        </Banner>
      );
    }
    case "banner con video": {
      return (
        <Banner bgImg={bg} className="bannerpromocion" key={banners.id}>
          <section
            className="section__banner"
            ref={bannerRef}
            onClick={onClick}
          >
            <div className="section__banner--video">
              <video
                autoPlay
                loop
                playsInline
                id="background-video"
                muted
                className="banner__video"
              >
                <source
                  src={
                    "https://s3.us-east-1.amazonaws.com/ca-webprod/media/hoteles-zorritos.mp4"
                  }
                  type="video/mp4"
                />
              </video>
              <div className="bannerdestinovideo__content">
                {banners.titulo && (
                  <Text
                    type="title"
                    className={"bannerdestinovideo__content--title"}
                    text={banners.titulo}
                  />
                )}
                {banners.subtitulo && (
                  <Text
                    type="title"
                    className={"bannerdestinovideo__content--subtitle"}
                    text={banners.subtitulo}
                  />
                )}
              </div>
              {banners.redireccion_boton && (
                <div className="bannerdestinovideo__button">
                  <div
                    className="bannerdestinovideo__button--btn"
                    onClick={() => {
                      navigate(banners.redireccion_boton);
                    }}
                  >
                    {/* <IconText
                      text={banners.descripcion_boton}
                      nameIcon="down"
                      listIcon="arrows"
                      w={24}
                      h={24}
                      color="white"
                      type="right"
                    /> */}
                    {banners.descripcion_boton}
                  </div>
                </div>
              )}
            </div>
          </section>
        </Banner>
      );
    }
    /*****PAGINA YTQPLANE CAMBIAR RIPO DE BANNER*****/
    case "promociones con imagen": {
      return (
        <Banner
          bgImg={banners.img_desktop?.path}
          className="bannerpromocionimg"
          key={banners.id}
        >
          <div className="bannerpromocionimg__content">
            <Paragraph family={"txbannerslider"} text={banners.titulo} />

            <div className="bannerpromocionimg__content--box">
              {banners.img_logos &&
                banners.img_logos.map((items: any, index: number) => (
                  <div className="bannerimglogo" key={index}>
                    <img src={items?.path} alt={items.name} />
                  </div>
                ))}
            </div>
          </div>
        </Banner>
      );
    }
    /*****PAGINA YTQPLANE CAMBIAR RIPO DE BANNER*****/
    case "bancos": {
      return (
        <Banner
          onClick={() => handleRedirect(redireccionButton)}
          bgImg={
            banners.img_desktop?.path ||
            "https://s3.us-east-1.amazonaws.com/mvp-media-casa-andina/HomeBanner/banner-home.png"
          }
          className="bannerbancos"
          key={banners.id}
        >
          <div className="bannerbancos__content">
            <div className="bannerbancos__content--text">
              <Text className="subtitle" text={banners.subtitulo} />
              <Text className="title" text={banners.titulo} />
            </div>
            <div className="bannerbancos__content--content">
              {banners.img_logos &&
                banners.img_logos.map((items: any, index: number) => (
                  <div className="box" key={index}>
                    <div className="bannerimglogo">
                      <img src={items?.path} alt={items?.alt} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Banner>
      );
    }
    case "cursive": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocioncursive"
          key={banners.id}
          onClick={() => handleRedirect(redireccionButton)}
        >
          <div
            className="bannerpromocioncursive__content"
            style={{ color: banners.color_fondo_logo || "white" }}
          >
            <Paragraph family={"ttcbannerslider"} text={titulo} />
            <div className="logo-ca">
              <img
                src={banners.img_logos ? banners.img_logos[0]?.path : ""}
                alt={banners.img_desktop[0]?.alt}
              />
            </div>
          </div>
        </Banner>
      );
    }
    case "banner paquete exclusivo": {
      return (
        <Banner
          bgImg={bg}
          className="bannerpromocionpackage"
          key={banners.id}
          gradiente={gradiente}
        >
          <div className="bannerpromocionpackage__content">
            <Text
              type="paragraph"
              text={banners.titulo}
              className="bannerpromocionpackage__content--title"
            />
            <Text
              type="paragraph"
              text={banners.subtitulo}
              className="bannerpromocionpackage__content--subtitle"
            />
            <Text
              type="paragraph"
              text={banners.subtitulo_2}
              className="bannerpromocionpackage__content--description"
            />
          </div>
        </Banner>
      );
    }
  }
};

const BannerSlider: React.FC<BannerSliderProps | any> = ({
  data,
  textLarge,
  onClick,
  bannerRef,
  gradiente,
}) => {
  const renderedBanners = useMemo(() => {
    return data.map((banners: any) => (
      <SwiperSlide key={banners.id}>
        <BannersTypes
          banners={banners}
          gradiente={gradiente}
          bannerRef={bannerRef}
          onClick={onClick}
          textLarge={textLarge}
        />
      </SwiperSlide>
    ));
  }, [data, gradiente, textLarge, bannerRef, onClick]);

  const itemsForLoop = data.map((item: any) => item.id);

  return (
    <>
      <Swiper
        className="bannerslider"
        loop={itemsForLoop.length > 3}
        rewind={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
      >
        {data && Array.isArray(data) && data.length > 0 ? (
          <>{renderedBanners}</>
        ) : (
          <section className="section__banner">
            <motion.div
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                repeatType: "reverse",
              }}
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#ccc9",
              }}
            >
              <div className={`section__banner-content`} />
            </motion.div>
          </section>
        )}
      </Swiper>
    </>
  );
};

export default BannerSlider;
