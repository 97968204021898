import React from 'react'
import './styles.scss'
import { Icon, Text } from 'components/atoms'
import { Link } from 'react-router-dom'

const DestinationsLink = ({data}: any) => {

  const columns1 = [
    data.slice(0, 15),
    data.slice(15, 36),
  ];

  const columns2 = [
    data.slice(0, 12),
    data.slice(12, 25),
    data.slice(25, 36)
  ];

  const columns3 = [
    data.slice(0, 8),
    data.slice(8, 16),
    data.slice(16, 24),
    data.slice(24, 36),
  ];

  return (
    <div className='destinationslink'>
      <div className='destinationslink__container'>
        <div className="columns-container1">
          {columns1.map((column, columnIndex) => (
            <div className="column" key={columnIndex}>
              {column.map((item: any, index: number) => (
                <div className="destination-item" key={index}>
                  <Link className='linkdestination link ta-left' to={item.redireccion}>
                    <Text
                      type='paragraph'
                      className='paragraph'
                      text={item.nombre}
                    />
                    <Icon name='arrow-right' list='arrows' className='iconactive' />
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="columns-container2">
          {columns2.map((column, columnIndex) => (
            <div className="column" key={columnIndex}>
              {column.map((item: any, index: number) => (
                <div className="destination-item" key={index}>
                  <Link className='linkdestination link ta-left' to={item.redireccion}>
                    <Text
                      type='paragraph'
                      className='paragraph'
                      text={item.nombre}
                    />
                    <Icon name='arrow-right' list='arrows' className='iconactive' />
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="columns-container3">
          {columns3.map((column, columnIndex) => (
            <div className="column" key={columnIndex}>
              {column.map((item: any, index: number) => (
                <div className="destination-item" key={index}>
                  <Link className='linkdestination link ta-left' to={item.redireccion}>
                    <Text
                      type='paragraph'
                      className='paragraph'
                      text={item.nombre}
                    />
                    <Icon name='arrow-right' list='arrows' className='iconactive' />
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DestinationsLink