import React, { useState } from 'react'
import { IDropdown } from './props'
import './styles.scss'
import Paragraph from '../paragraph'
import Icon from '../icon'
import { useDetectClickOutside } from 'react-detect-click-outside'
import Text from '../text'

const Dropdown = ({
  ordenarPor,
  selectedOption,
  onSelect,
  isfilter,
  placeholder,
  title
}: IDropdown) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const handleSelect = (option: any) => {
    onSelect(option)
    setIsOpen(false)
  }

  const ref = useDetectClickOutside({ onTriggered: closeDropdown })

  return (
    <div className='dropdown' ref={ref}>
      <div className='dropdown-toggle' onClick={() => setIsOpen(!isOpen)}>
        <div className='d-flex jc-space-between ai-center'>
          {isfilter ? (
            <>
              <div>
                <Paragraph text={title} className={'title'} />
                <Paragraph text={selectedOption} className={'option'} />
              </div>
              <Icon
                name={isOpen ? 'up' : 'down'}
                list='arrows'
                width={24}
                height={24}
              />
            </>
          ) : (
            <>
              <div>
                <Text text={selectedOption ? selectedOption : placeholder } type={'paragraph'} className='selecetplace' />
              </div>
              <Icon
                name={isOpen ? 'up' : 'down'}
                list='arrows'
                width={24}
                height={24}
              />
            </>
          )}
        </div>
      </div>
      {isOpen && (
        <ul className={`${isfilter} ? dropdown-list : dropdown-selecetplace `}>
          {ordenarPor?.map((option: any) => (
            <li key={option.id} onClick={() => handleSelect(option)}>
              {option.titulo}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}


Dropdown.defaultProps = {
  isfilter: true,
  title: 'Ordenar por'
}

export default Dropdown
