import React from "react";
import { motion } from "framer-motion";
import { IAnimations } from "./props";

const Animations = ({ children, type, className }: IAnimations) => {
  const animationTypes = () => {
    switch (type) {
      case "scaleMin": {
        return (
          <motion.div
            whileHover={{ scale: [null, 0.99] }}
            transition={{ duration: 0.3 }}
            className={className}
          >
            {children}
          </motion.div>
        );
      }
      case "scaleHover": {
        return (
          <motion.div
            whileHover={{ scale: [null, 1.1] }}
            transition={{ duration: 0.3 }}
            className={className}
          >
            {children}
          </motion.div>
        );
      }
      case "leftSide": {
        return (
          // <motion.div
          //   initial={{ x: '-100%' }}
          //   whileInView={{ opacity: 1, scale: 1, x: 0 }}
          //   transition={{ duration: 0.5 }}
          //   className={className}
          // >
          //   {children}
          // </motion.div>
          <motion.div
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -10, opacity: 0 }}
            transition={{ duration: 0.8 }}
          >
            {children}
          </motion.div>
        );
      }
      case "rightSideDesk": {
        return (
          <motion.div
            initial={{ opacity: 0, scale: 0, x: -500 }}
            whileInView={{ opacity: 1, scale: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className={className}
          >
            {children}
          </motion.div>
        );
      }
      case "rightSide": {
        return (
          <motion.div
            initial={{ x: "100%" }}
            whileInView={{ opacity: 1, scale: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className={className}
          >
            {children}
          </motion.div>
        );
      }
      case "scaleLeftTitle": {
        return (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
            className={className}
          >
            {children}
          </motion.div>
        );
      }
      case "downSide": {
        return (
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.8 }}
          >
            {children}
          </motion.div>
        );
      }
      case "skeleton": {
        return (
          <motion.div
            initial={{ opacity: 0.5, borderRadius: "50%" }}
            animate={{ opacity: 1, borderRadius: "10px" }}
            transition={{
              duration: 0.8,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            style={{
              width: "100px",
              height: "20px",
              backgroundColor: "#ccc",
            }}
          ></motion.div>
        );
      }
      case "skeletonContent": {
        return (
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.8,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#ccc",
            }}
          >
            {children}
          </motion.div>
        );
      }
    }
  };

  return <>{animationTypes()}</>;
};

export default Animations;
