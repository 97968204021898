import React, { useState } from "react";
import { Icon, IconText, Button, Paragraph, Text } from "components/atoms";
import { CardHotel } from "components/molecules";
import { SliderHotel } from "components/organisms";
import { Modal } from "components/molecules";
import { MIN_CARD_LENGTH } from "utils/values";
import { Tooltip } from "react-tooltip";
import { IHotels } from "./props";
import "./styles.scss";
import { Link } from "react-router-dom";
import PromotionsCard from "../promotions-card";
import { useTranslation } from "react-i18next";

const Hotels = ({
  data,
  hotelesRef,
  destino,
  title,
  isTitle,
  type,
}: IHotels) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);

  const toogleModal = () => setModal(!modal);

  const renderTypes = () => {
    switch (type) {
      case "hotel":
        return (
          <div className="hotels padding__general" ref={hotelesRef}>
            <div className="hotels__containers">
              <div className="hotels__description">
                <div className="hotels__description--cardhotel">
                  <div className="ismobile">
                    <PromotionsCard
                      title={title}
                      data={data}
                      type="hoteles"
                      isFilter={false}
                    />
                  </div>
                </div>

                <div className="isdesktop">
                  <div>
                    {isTitle && (
                      <Text
                        type="subtitle"
                        className={"subtitle"}
                        text={title}
                      />
                    )}
                  </div>
                  {data.map((hoteles: any) => (
                    <div className="cardholteld__colum" key={hoteles.id}>
                      <div className="cardholteld__colum--hotel">
                        <SliderHotel
                          images={hoteles.img}
                          tipo={hoteles.tipo_hotel?.titulo}
                          city={hoteles.destino}
                          bgColor={hoteles.tipo_hotel?.color}
                        />
                      </div>
                      <div className="cardholteld__colum--description">
                        <div className="cardheader">
                          <div className="cardheader__content">
                            <Text
                              text={hoteles.titulo}
                              type="subtitle2"
                              className={"title"}
                            />
                            <p className="review">
                              {hoteles.calificacion}
                              <span>
                                <Icon
                                  name="star"
                                  list="items"
                                  width={14}
                                  height={14}
                                  className="mr-0-2rem"
                                />
                              </span>
                              (
                              {hoteles.reseña && hoteles.reseña !== null
                                ? hoteles.reseña
                                : 0}{" "}
                              {hoteles.reseña === "1"
                                ? `${t("betterPrices.review")}`
                                : `${t("betterPrices.reviews")}`}
                              )
                            </p>
                            <IconText
                              type="left"
                              nameIcon="place"
                              color="gray"
                              listIcon="items"
                              text={hoteles.direccion}
                              className="place"
                              w={20}
                              h={20}
                            />
                            <Text
                              html={{
                                __html:
                                  hoteles.descripcion &&
                                  hoteles.descripcion.length > MIN_CARD_LENGTH
                                    ? `${hoteles.descripcion.slice(
                                        0,
                                        MIN_CARD_LENGTH
                                      )}...`
                                    : hoteles.descripcion,
                              }}
                              type="paragraph"
                              className={"description"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="cardholteld__colum--price">
                        <div className="cardprices">
                          <div className="cardprices__card">
                            <Text
                              text={t("destinationCity.pricePerNight")}
                              type="paragraph"
                              className="ttcardprices"
                            />
                            {parseFloat(hoteles.precio_anterior.precio) >
                              parseFloat(hoteles.precio.precio) && (
                              <Text
                                text={`${hoteles.precio_anterior.simbolo} ${hoteles.precio_anterior.precio}`}
                                type="paragraph"
                                className="pricebefore"
                              />
                            )}
                            <Text
                              text={`${hoteles.precio.simbolo} ${hoteles.precio.precio}`}
                              type="paragraph"
                              className="sbcardprices"
                            />
                            {hoteles.igv !== null && hoteles.igv.precio > 0 && (
                              <div className="sbcardigvs">
                                <Text
                                  text={`+ ${hoteles.igv.simbolo} ${hoteles.igv.precio} ${hoteles.texto_tooltip_igv_card}`}
                                  type="paragraph"
                                />
                                <Icon
                                  name="question-card"
                                  color="gray"
                                  list="items"
                                  width={14}
                                  height={14}
                                  className={`tooltip-price-${hoteles.index}`}
                                />
                                <Tooltip
                                  anchorSelect={`.tooltip-price-${hoteles.index}`}
                                  place="left-start"
                                  opacity={1}
                                  style={{
                                    backgroundColor: "white",
                                    color: "#222",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                                    maxWidth: "440px",
                                    width: "100%",
                                  }}
                                >
                                  {hoteles.texto_tooltip_igv}
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          <Link to={hoteles.slug} className="nlink">
                            <Button type="gold">
                              {t("destinationCity.seeHotel")}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      case "restaurant":
        return (
          <div className="hotels" ref={hotelesRef}>
            <div className="hotels__containers">
              <div className="px-1rem">
                <Text type="subtitle" family={"tsection"} text={title} />
              </div>

              <div className="hotels__description">
                <div className="hotels__description--cardhotel">
                  <CardHotel cards={data} type={"hotelMobile"} />
                </div>

                <div className="isdesktop">
                  {data.map((hoteles: any) => (
                    <div
                      className="cardholteld__colum mb-3rem"
                      key={hoteles.id}
                    >
                      <div className="cardholteld__colum--hotel">
                        <SliderHotel
                          images={hoteles.img}
                          tipo={hoteles.tipo_hotel?.titulo}
                        />
                      </div>
                      <div className="cardholteld__colum--description">
                        <div className="cardheader">
                          <p className="title">{hoteles.titulo}</p>
                          <p className="review">{hoteles.subtitulo}</p>
                          <p className="review">
                            {hoteles.calificacion}
                            <span>
                              <Icon
                                name="star"
                                list="items"
                                width={14}
                                height={14}
                                className="mr-0-2rem"
                              />
                            </span>
                            (
                            {hoteles.reseña && hoteles.reseña !== null
                              ? hoteles.reseña
                              : 0}{" "}
                            {hoteles.reseña === "1"
                              ? `${t("betterPrices.review")}`
                              : `${t("betterPrices.reviews")}`}
                            )
                          </p>
                        </div>
                        <div className="cardprices">
                          <div className="cardprices__card">
                            <Paragraph
                              html={{
                                __html:
                                  hoteles.description &&
                                  hoteles.description.length > MIN_CARD_LENGTH
                                    ? `${hoteles.description.slice(
                                        0,
                                        MIN_CARD_LENGTH
                                      )}...`
                                    : hoteles.description,
                              }}
                              family={"txnormal"}
                            />
                          </div>
                          <div className="termino">
                            <p className="review">
                              *Revisa los términos y condiciones
                              <span onClick={toogleModal} className="txtlink">
                                {" "}
                                aquí
                              </span>
                            </p>
                          </div>
                          <Link className="nlink" to={"/"}>
                            <IconText
                              type="left2"
                              text={"Ir al restaurante"}
                              nameIcon="arrow-right"
                              listIcon={"arrows"}
                              className="iconactive"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="cardholteld__colum--price">
                        <div className="cardprices">
                          <div className="cardprices__card">
                            <div className="cardfood__text">
                              <Text
                                type="paragraph"
                                family="txnormal"
                                text={hoteles.texto_encima_precio || "2X"}
                              />
                              <Text
                                type="paragraph"
                                family="tcarddiscount"
                                text={`S/${hoteles.precio}`}
                              />
                              <Text
                                type="paragraph"
                                family="txsnormal"
                                text={
                                  hoteles.condicion_abajo_precio ||
                                  "en cócteles"
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="pt-0-4rem">
                          <Button type="gold">Reservar</Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      {renderTypes()}

      <Modal
        isTitle={true}
        showModal={modal}
        close={toogleModal}
        title={"Términos y condiciones de la promoción"}
      >
        <div className="modal__terminos">
          <Text
            html={{ __html: data.terminos_condiciones }}
            family={"txnormal"}
          />
        </div>
      </Modal>
    </>
  );
};

Hotels.defaultProps = {
  isTitle: false,
  type: "hotel",
};

export default Hotels;
