export const endpoints = {
  agencias: '/agencias',
  cajabusqueda: '/caja_busqueda',
  currencies: '/divisas',
  destinos: '/destinos',
  home: '/home',
  hoteles: '/hoteles',
  mejoresprecios: '/mejores-precios',
  menuofertas: '/menu',
  promociones: '/promociones',
  contador: '/promociones/contador',
  relajate: '/relajate',
  restaurantes: '/restaurantes',
  utils: '/utils',
  mejoresmomentos: '/form/imagenes_momentos_casa_andina',
} as const;
