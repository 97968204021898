import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BannerSlider, BetterPrices, Nights, Visit } from 'components/organisms'
import { useAppSelector } from 'hooks/useAppSelector'
import { getHome } from 'actions/home/services'
import SectionCards from 'components/organisms/section-cards'
import BannerTira from 'components/organisms/banners/banner'

const Home = () => {
  const { t } = useTranslation()

  const { language, coin, data } = useAppSelector(state => ({
    language: state.config.language,
    coin: state.config.coin,
    data: state.language.dataHome,
  }))

  const { banners, mejoresPrecios, paraTiData, promociones, banner_tira, razones } = useMemo(() => ({
    banners: data?.banners || [],
    banner_tira: data?.banners_tira || [],
    mejoresPrecios: data?.mejores_precios || [],
    paraTiData: data?.para_ti || [],
    promociones: data?.promociones || [],
    razones: data?.razones || []
  }), [data])

  const fetchHome = () => getHome({ language, coin });

  useEffect(() => {
    if (language && coin) {
      window.scrollTo(0, 0);
      fetchHome();
    } else {
      window.scrollTo(0, 0);
    }
  }, [language, coin])

  return (
    <>
      {banners.length > 0 && <BannerSlider data={banners} />}
      {paraTiData.length > 0 && <Visit data={paraTiData} title={data?.subtitulos ? data?.subtitulos[0].subtitulo : t('home.everythingPeruTitle')} />}
      {mejoresPrecios.length > 0 && <BetterPrices data={mejoresPrecios} title={data?.subtitulos ? data?.subtitulos[1].subtitulo : t('home.betterPriceTitle')} />}
      {banner_tira.length > 0 && <BannerTira data={banner_tira} />}
      {razones.length > 0 && <Nights data={razones} isTitle={true} title={data?.subtitulos ? data?.subtitulos[2].subtitulo : t('home.activitiesTitle') } />}
      {promociones.length > 0 && <SectionCards data={promociones} title={data?.subtitulos ? data?.subtitulos[3].subtitulo : t('home.discountsTitle') } height={287} />}
    </>
  )
}

export default Home