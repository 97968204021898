
import Axios from "actions/api";
import { IGetCurrencies } from "./props";
import { endpoints } from "actions/endpoints/services";
import { setDataCurrencies, setLoading } from "redux/actions/language";

export const getCurrencies = async ({
  language,
}: IGetCurrencies) => {
  try {
    setLoading(true);
    const response = await Axios.request({
      method: "get",
      url: endpoints.currencies,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataCurrencies(data);

    return data;

  } catch (error) {
    alert('No se pudo cargar las divisas')
  } finally {
    setLoading(false);
  }
}
