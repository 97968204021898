import { Button, IconText, Text } from "components/atoms";
import Section from "components/molecules/section";
import { BannerSlider } from "components/organisms";
import "./styles.scss";
import Texture from "assets/images/bkg-texture.webp";
import { Modal } from "components/molecules";
import { useEffect, useState } from "react";
import { banner_data_centrado } from "pages/news/data";
import Searchboxgeneral from "components/organisms/navbar/searchboxgeneral";
import { Controller, useForm } from "react-hook-form";
import { getUtils } from "actions/utils/services";
import { useAppSelector } from "hooks/useAppSelector";
import { toast } from "sonner";
import useRecaptcha from "hooks/useRecaptcha";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { quienes_somos } from "assets/images/quienes-somos";
import Ficha from "assets/images/agencies/ficha.webp";
import Menu from "assets/images/agencies/menus.webp";
import { setDataAgency } from "redux/actions/language";
import { validateTravelClickIsInMainteinnace } from "utils/function";

const Agencies = () => {
  const navigate = useNavigate();
  const { getRecaptchaToken } = useRecaptcha();
  const { t, i18n } = useTranslation();

  const {
    control: controlFirst,
    register: registerFirst,
    handleSubmit: handleSubmitFirst,
    setError: setErrorFirst,
    formState: { errors: errorsFirst, isValid: isValidFirst },
    reset: resetFirst,
  } = useForm({ mode: "onChange" });

  const {
    register: registerSecond,
    handleSubmit: handleSubmitSecond,
    reset: resetSecond,
    formState: { errors: errorsSecond, isValid: isValidSecond },
  } = useForm({ mode: "onChange" });

  const {
    register: registerThird,
    handleSubmit: handleSubmitThird,
    reset: resetThird,
    formState: { errors: errorsThird, isValid: isValidThird },
  } = useForm({ mode: "onChange" });

  const { coin, language, hoteles } = useAppSelector((state) => ({
    coin: state.config.coin,
    language: state.config.language,
    hoteles: state.language.dataUtils?.hoteles || [],
  }));

  const [modal, setModal] = useState(true);
  const [modalReserve, setModalReserve] = useState(false);
  const [modalCloseReserve, setModalCloseReserve] = useState(false);
  const [validatedAgencyCode, setValidatedAgencyCode] = useState<string>();
  const [currencyAgency, setCurrencyAgency] = useState<string>();
  const [adultsAgency, setAdultsAgency] = useState<string>();

  const [isLoading, setIsLoading] = useState(false);
  const [themeCode, setThemeCode] = useState("");

  const toogleModalReserve = () => {
    setModalReserve(!modalReserve);
  };

  const toogleModalCloseReserve = () => {
    setModalCloseReserve(!modalCloseReserve);
  };

  const onSubmit = async (data: object) => {
    const token = await getRecaptchaToken();

    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      setIsLoading(true);
      const { codeagency } = data as { codeagency: string };

      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/agencias/validar_codigo`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          },
          body: JSON.stringify({
            codigo: codeagency,
          }),
        }
      );

      if (response.status == 203) {
        throw new Error(`Código Capthca Inválido`);
      }

      if (!response.ok) {
        throw new Error("Código Inválido");
      }

      const result = await response.json();

      setDataAgency({
        ...result.agencia,
        codeagency: codeagency,
      });
      setValidatedAgencyCode(codeagency);
      setModal(!modal);
      setThemeCode(result.agencia.tema);
      setCurrencyAgency(result.agencia.moneda);
      setAdultsAgency(result.agencia.cantidad_personas);
      resetFirst();
    } catch (error: any) {
      setErrorFirst("codeagency", {
        type: "manual",
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitModalSecond = (data: any) => {
    redirectLink(data.hoteles2, data.codigo_reserva2);
    resetSecond();
    setModalCloseReserve(false);
  };

  const onSubmitModalThird = (data: any) => {
    redirectLink(data.hoteles3, data.codigo_reserva3);
    resetThird();
    setModalReserve(false);
  };

  const redirectLink = (hoteles: string, codigo_reserva: string) => {
    const id = hoteles;
    const codeagencies = codigo_reserva;
    const currency = currencyAgency;

    const bookingLink = () => {
      const maintLink = validateTravelClickIsInMainteinnace()
      if(maintLink) return maintLink;

      if (id) {
        return `https://bookings.casa-andina.com/${id}?LanguageID=2&theme_code=${themeCode}&adults=${adultsAgency}&currency=${currency}&confirmID=${codeagencies}&identifier=${validatedAgencyCode}&pum_form_popup_id=${31310}`;
      }

      return "";
    };

    const link = bookingLink();
    if (link) {
      window.open(link, "_blank");
    } else {
      toogleModalCloseReserve();
    }
  };

  const banner_data_centrado = [
    {
      id: 1,
      titulo: "HOTEL PARACAS",
      subtitulo: t("reservationAgents.reserveNow"),
      subtitulo2: t("reservationAgents.nowOpen"),
      color_fondo_logo: "rgba(62, 145, 41, 1)",
      img_desktop: {
        name: "banner-home",
        path: "https://s3.us-east-1.amazonaws.com/ca-webprod/Hoteles/16933f02-0e94-45f8-8c36-c9d356de0d73.jpg",
        width: 1440,
        height: 400,
        size: 794666,
        title: "Banner News",
        alt: "Banner News",
        description: null,
        caption: "Banner News",
      },
      img_mobile: [],
      descuento: "",
      descripcion_boton: "",
      redireccion_boton: "",
      img_logos: [],
      tipo_banner: {
        id: 1,
        titulo: "Título/subtitulo collage logo",
      },
    },
  ];

  const cardsAgencies = [
    {
      id: 1,
      title: t("reservationAgents.touristMenu"),
      img: Ficha,
      alt: "fichas",
      bgImg: quienes_somos.fichasTecnicas,
      url: "https://casaandina.sharepoint.com/sites/KITDEAGENCIASDEVIAJE/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FKITDEAGENCIASDEVIAJE%2FDocumentos%20compartidos%2FKIT%20AGENCIAS%20DE%20VIAJE%2F3%5FAlimentos%20y%20bebidas%20%2D%20Food%20and%20Beverages&p=true&ga=1&LOF=1",
    },
    {
      id: 2,
      title: t("reservationAgents.technicalSheets"),
      img: Menu,
      alt: "menu",
      bgImg: quienes_somos.menuTuristico,
      url: "https://casaandina.sharepoint.com/sites/KITDEAGENCIASDEVIAJE/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FKITDEAGENCIASDEVIAJE%2FDocumentos%20compartidos%2FKIT%20AGENCIAS%20DE%20VIAJE%2F4%5FFichas%20fichas%20t%C3%A9cnicas%20%2D%20Fact%20Sheet&p=true&ga=1&LOF=1",
    },
    // { id: 3, title: 'Fechas especiales', img: Fechas, alt: 'fechas', bgImg: quienes_somos.fechasEspeciales, url: '' },
    {
      id: 4,
      title:  t("reservationAgents.hotelPhotos"),
      img: "",
      alt: "fotos",
      bgImg: quienes_somos.nuestrosHoteles,
      url: "https://www.flickr.com/photos/casa-andina/albums/with/72177720310086238",
    },
  ];
  useEffect(() => {
    getUtils({ language });
    window.scrollTo(0, 0);
  }, [language]);

  return (
    <>
      <BannerSlider data={banner_data_centrado} />

      <Section bgImage={Texture} isTitle={false} isBg>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            width: "100%",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9,
            padding: "16px",
          }}
        >
          <Searchboxgeneral
            searchMobile={false}
            codeagencies={validatedAgencyCode || ""}
            themeCode={themeCode}
            currencyAgency={currencyAgency || "2"}
            adultsAgency={adultsAgency}
            hiddenPrices={true}
          />
        </div>
        <div className="inputreserva">
          <button className="inputreservabtn" onClick={toogleModalReserve}>
            <IconText
              nameIcon="calendar"
              listIcon="items"
              text={t("reservationAgents.modifyReservation")}
              type="paragraph"
              className="inputreservabtn-btn"
              w={24}
              h={24}
            />
          </button>
          <button className="inputreservabtn" onClick={toogleModalCloseReserve}>
            <IconText
              nameIcon="calendar"
              listIcon="items"
              text={t("reservationAgents.cancelReservation")}
              type="paragraph"
              className="inputreservabtn-btn"
              w={24}
              h={24}
            />
          </button>
        </div>
      </Section>

      {/* <Banner data={bannerTiraAgencies} type="icon-button" /> */}

      <Section title={t("reservationAgents.exploreWithUsTitle")}>
        <div className="agenciespage">
          <div className="agenciespage__cards">
            <div className="agenciespage__cards--grid">
              <div className="cards">
                {cardsAgencies.map((item, index) => {
                  return (
                    <div
                      className="cards__card"
                      key={index}
                      style={{
                        backgroundImage: `url(${item.bgImg})`,
                        width: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="cards__cardend">
                        <div>
                          <Text
                            text={item.title}
                            type="paragraph"
                            className="paragraph"
                          />
                        </div>
                        <div className="btn">
                          <Button
                            type="borders"
                            onClick={() => window.open(item.url, "_blank")}
                          >
                            {t("reservationAgents.view")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Modal
        isTitle={false}
        showModal={modalCloseReserve}
        close={toogleModalCloseReserve}
        changeWidth={true}
      >
        <div>
          <form
            onSubmit={handleSubmitSecond(onSubmitModalSecond)}
            className="forminput"
          >
            <p>{t("reservationAgents.cancelReservationInstructions")}</p>
            <div>
              <select
                id="hoteles2"
                {...registerSecond("hoteles2", {
                  required: true,
                })}
              >
                <option value="">{t("reservationAgents.chooseHotel")}</option>
                {hoteles.map((data: any) => (
                  <option key={data.id} value={data.htl_id_travel_click}>
                    {data.nombre}
                  </option>
                ))}
              </select>
              {errorsSecond.hoteles2 && <span>{t("forms.requiredField")}</span>}
            </div>

            <div>
              <input
                type="text"
                id="codigo_reserva2"
                placeholder={t("reservationAgents.reservationCode")}
                inputMode="numeric"
                {...registerSecond("codigo_reserva2", {
                  required: t("forms.requiredField"),
                })}
              />
              {errorsSecond.codigo_reserva2 && (
                <span>{t("forms.requiredField")}</span>
              )}
            </div>

            <div>
              <button
                type="submit"
                disabled={!isValidSecond}
                className="btnagencies"
              >
                {t("reservationAgents.search")}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isTitle={false}
        showModal={modalReserve}
        close={toogleModalReserve}
        changeWidth={true}
      >
        <div>
          <form
            onSubmit={handleSubmitThird(onSubmitModalThird)}
            className="forminput"
          >
            <p>{t("reservationAgents.modifyReservationInstructions")}</p>
            <div>
              <select
                id="hoteles3"
                {...registerThird("hoteles3", {
                  required: true,
                })}
              >
                <option value="">{t("reservationAgents.chooseHotel")}</option>
                {hoteles.map((data: any) => (
                  <option key={data.id} value={data.htl_id_travel_click}>
                    {data.nombre}
                  </option>
                ))}
              </select>
              {errorsThird.hoteles3 && <span>{t("forms.requiredField")}</span>}
            </div>

            <div>
              <input
                type="text"
                id="codigo_reserva3"
                placeholder={t("reservationAgents.reservationCode")}
                inputMode="numeric"
                {...registerThird("codigo_reserva3", {
                  required: t("forms.requiredField"),
                })}
              />
              {errorsThird.codigo_reserva3 && (
                <span>{t("forms.requiredField")}</span>
              )}
            </div>

            <div>
              <button
                type="submit"
                disabled={!isValidThird}
                className="btnagencies"
              >
                {t("reservationAgents.search")}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isTitle={false}
        showModal={modal}
        onlyClose={true}
        changeWidth={true}
      >
        <form onSubmit={handleSubmitFirst(onSubmit)} className="forminput">
          <Text
            text={t("reservationAgents.enterAgencyCode")}
            type="paragraph"
          />
          <div>
            <div className="input-form">
              <Controller
                name="codeagency"
                control={controlFirst}
                defaultValue=""
                rules={{ required: t("reservationAgents.codeRequired") }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    id="codeagency"
                    placeholder={t("reservationAgents.enterYourCode")}
                  />
                )}
              />
              <span>
                {errorsFirst.codeagency && (
                  <>{errorsFirst.codeagency.message}</>
                )}
              </span>
            </div>

            <div className="description">
              <p>
                {t("reservationAgents.notAgentOrCompany")}
                <span onClick={() =>navigate(`/${i18n.language}/home`)}>
                 {" "}{t("reservationAgents.returnHome")}
                </span>
              </p>
            </div>

            <button
              type="submit"
              disabled={!isValidFirst}
              className="btnagencies"
            >
              {isLoading
                ? t("reservationAgents.validating")
                : t("reservationAgents.enter")}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Agencies;
