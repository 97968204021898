import { Text } from "components/atoms";
import { Animations, TabIndex } from "components/molecules";
import { INights } from "./props";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "./styles.scss";
import "swiper/css";
import "swiper/css/pagination";

const Night = ({ data, isTitle, title }: INights) => {
  return (
    <section>
      <div className="ismobile activityslidermob">
        {isTitle ? (
          <Text text={title} type="subtitle" className={"subtitle"} />
        ) : (
          ""
        )}
        <Swiper
          pagination={{ clickable: true }}
          modules={[Pagination, Navigation]}
          className="activityslider"
        >
          {data && Array.isArray(data) && data.length > 0 ? (
            <>
              {data.map((activity: any) => (
                <SwiperSlide key={activity.titulo}>
                  <div className="activityslider__container">
                    <div className="activityslider__container--img">
                      <img
                        src={activity.img?.path}
                        alt={activity.img?.alt}
                        loading="lazy"
                      />
                    </div>

                    <div className="activityslider__container--description2">
                      <div className="activityslider__container--trapezoidcont">
                        <div className="trapezoid">
                          <div>
                            <Text
                              text={activity.titulo}
                              type="paragraph"
                              className="title"
                            />
                            <Text
                              type="paragraph"
                              className="description"
                              html={{ __html: activity.descripcion }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </>
          ) : (
            <>
              <SwiperSlide>
                <div className="activityslider__container">
                  <div className="activityslider__container--img">
                    <Animations type="skeletonContent" >
                      <img
                        style={{ height : 240 }}
                      />
                    </Animations>
                  </div>
                  <div className="activityslider__container--description2">
                    <div className="activityslider__container--trapezoidcont">
                      <div className="trapezoid">
                        <div>
                          <div style={{ maxWidth : 250, height : 30 }}>
                            <Animations type="skeletonContent" >
                              <Text
                                text={"Lorem ipsum dolor sit amet"}
                                style={{ color : "transparent" }}
                                type="paragraph"
                                className="title"
                              />
                            </Animations>
                          </div>
                          <Animations type="skeletonContent">
                            <Text
                              style={{ marginTop : 15, color : "transparent" }}
                              text={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste earum dolores repellat placeat obcaecati debitis odit recusandae non nisi illo illum error atque veniam eos, voluptates officia aut qui facilis?"}
                              type="paragraph"
                              className="description"
                            />
                          </Animations>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </div>

      <div className="isdesktop">
        <div className="activityslidertab">
          <div className="activityslidertab__container">
            {isTitle ? (
              <Text text={title} type="subtitle" className={"subtitle"} />
            ) : (
              ""
            )}
            <TabIndex data={data} />
          </div>
        </div>
      </div>
    </section>
  );
};

Night.defaultProps = {
  title: false,
};

export default Night;
