import React, { useEffect } from "react";
import { getRestaurantsRestaurant } from "actions/restaurants/services";
import Loader from "components/molecules/loader";
import {
  BannerRestaurants,
  BannerSlider,
  RestaurantCity,
} from "components/organisms";
import { useAppSelector } from "hooks/useAppSelector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RestaurantCategory = () => {
  const { restaurant } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [route, setRoute] = React.useState<string | undefined>(undefined);

  const { loading, language, data, banner, place } = useAppSelector(
    (state) => ({
      loading: state.language.loading,
      language: state.config.language,
      data: state.language.dataRestaurantsPlaces,
      place: state.language.dataRestaurantsPlaces?.restaurante,
      banner: state.language.dataRestaurantsPlaces?.banner,
    })
  );

  const dataSchema = {
    "@context": "https://schema.org",
    "@type": "Restaurant",
    acceptsReservations: true,
    address: place?.direccion,
    description: place?.descripcion,
    email: place?.email,
    hasMenu: place?.enlace_carta,
    logo: place?.tipo_restaurante?.img?.path,
    name: place?.titulo,
    telephone: place?.telefono,
    openingHours: place?.horario_atencion,
  };

  useEffect(() => {
    if (location.pathname !== route) {
      setRoute(location.pathname);
    }
  }, [location.pathname, route]);

  useEffect(() => {
    if (restaurant && language) {
      getRestaurantsRestaurant({ restaurant, language, navigate });
      window.scrollTo(0, 0);
    }
  }, [restaurant, language, navigate]);

  if (loading || !data) return <Loader />;

  return (
    <>
      <script type="application/ld+json">{JSON.stringify(dataSchema)}</script>

      <BannerRestaurants
        types="tipo_restaurante"
        activeIcons={false}
        place={place?.tipo_restaurante}
        data={banner}
        direccion={place?.direccion}
        google={place?.enlace_google}
        telefono={place?.telefono}
        city={banner?.titulo}
      />

      {data.banner_tira && data.banner_tira.length > 0 && (
        <BannerSlider data={data.banner_tira} />
      )}

      <RestaurantCity
        data={place}
        title={t("restaurantsCityRestaurant.discoverOurRestaurant", {
          restaurant: data.restaurante?.titulo,
          city: banner?.titulo,
        })}
        type="row"
      />
    </>
  );
};

export default RestaurantCategory;
