import { Paragraph, Text, IconText } from 'components/atoms'
import { IBannersHeaderB } from './props'
import './styles.scss'
import { Banner } from 'components/molecules'
import { useTranslation } from 'react-i18next'

const BannersHeaderB = ({
  data,
  type,
  onClick,
  bannerRef,
  hoteles
}: IBannersHeaderB) => {
  const { t } = useTranslation();

  const bannerDirection = () => {
    switch (type) {
      case 'center':
        return (
          <Banner
            bgImg={data.img?.path}
            className='bannerdestino'
            bannerRef={bannerRef}
            gradiente
          >
            <div className='bannerdestino__content'>
              <Text
                type='title'
                className='bannerdestino__content--title'
                text={
                  hoteles.length === 1
                  ? t("destinationCity.hotelIn", { city: data.titulo })
                  : t("destinationCity.hotelsIn", { city: data.titulo })
                }
              />
              <Text className='bannerdestino__content--subtitle' type='subtitle2' text={data.subtitulo} />
            </div>
            <div className='bannerdestino__button'>
              <div className='bannerdestino__button--btn' onClick={onClick}>
                {/* {messages[locale].buttonHotels}
                <Icon name='down' list='arrows' width={24} height={24} color='white' /> */}
                <IconText
                  text={hoteles?.length === 1 ? t("destinationCity.seeHotel") : t("destinationCity.seeHotels")}
                  nameIcon='down'
                  listIcon='arrows'
                  w={24}
                  h={24}
                  color='white'
                  type='right'
                />
              </div>
            </div>
          </Banner>
        )
      case 'left':
        return (
          <section className='bannerheader' ref={bannerRef}>
            <img
              src={data.img?.path}
              alt={data.img?.alt}
              className='bannerheader__img'
            />
            <div className='bannerheader__container'>
              <div className='bannerheader__container--text ta-right'>
                <div>
                  <Paragraph text={data.titulo} family='tbanner' />
                </div>
              </div>
            </div>
          </section>
        )
      // case 'right':
      //   return (

      //   )
    }
  }

  return <>{bannerDirection()}</>
}

BannersHeaderB.defaultProps = {
  default: 'center'
}

export default BannersHeaderB
