export const dataBanner = [
  {
    id: 1,
    titulo: 'ENTREMOS EN',
    subtitulo: 'CONTACTO',
    color_fondo_logo: 'rgba(62, 145, 41, 1)',
    img_desktop: {
      name: 'banner-home',
      path: 'https://s3.us-east-1.amazonaws.com/multimediaqa.casa-andina.com/system/images/960X540_55555.jpg',
      width: 1440,
      height: 400,
      size: 794666,
      title: 'Banner Home',
      alt: 'Banner Home',
      description: null,
      caption: 'Banner Home'
    },
    img_mobile: [],
    descuento: '25',
    descripcion_boton: '',
    redireccion_boton: '',
    img_logos: [],
    tipo_banner: {
      id: 1,
      titulo: 'Subtitulo/título collage'
    }
  }
]
