import React from 'react'
import { Tooltip } from 'react-tooltip'
import { Icon } from 'components/atoms'
import { SliderHotelMobile } from 'components/organisms'
import { messages } from 'utils/lang'
import { useIntl } from 'react-intl'
import { ICardSlider } from './props'
import './styles.scss'
import { Link } from 'react-router-dom'

const CardHotel = ({ type, cards, className }: ICardSlider) => {
  const { locale } = useIntl()


  const typeCard = ({ type, cards }: any) => {
    switch (type) {
      case 'hotelMobile':
        return (
          <>
            {cards.map((hoteles: any, index: number) => (
              <div className='card__restaurant ismobile' key={index}>
                <div className='card__restaurant--slider'>
                  <SliderHotelMobile
                    images={hoteles.img}
                    tipo={hoteles.tipo_hotel?.titulo}
                  />
                </div>
                <div className='card__restaurant--container'>
                  <div className='cardheaderr'>
                    <h3 className='title'>{hoteles.titulo}</h3>
                    <p className='review'>
                      {hoteles.calificacion}
                      <span>
                        <Icon
                          name='star'
                          list='items'
                          width={12}
                          height={12}
                          className='mr-0-2rem'
                        />
                      </span>
                      ({hoteles.reseña && hoteles.reseña !== null ? hoteles.reseña : 0} {
                        hoteles.reseña === '1'
                          ? `${messages[locale].hotelReview}`
                          : `${messages[locale].hotelsReview}`
                      })
                    </p>
                  </div>
                  <div className='cardprices'>
                    <div className='cardprices__card'>
                      <p className='ttcardprices'>
                        Desde
                        <span>{hoteles.precio_anterior.simbolo} {hoteles.precio_anterior.precio}</span>
                      </p>
                      <p className='sbcardprices'>{hoteles.precio.simbolo} {hoteles.precio.precio}</p>
                      {hoteles.igv !== null && hoteles.igv.precio > 0 && (<p className='sbbcardprices'>
                        + {hoteles.igv.simbolo} {hoteles.igv.precio}{' '}
                        <span>
                          {' '}
                          {hoteles.texto_tooltip_igv_card}
                          <Icon
                            name='info'
                            list='items'
                            width={14}
                            height={14}
                            className='mr-0-2rem tooltip-price'
                          />
                          <Tooltip
                            anchorSelect='.tooltip-price'
                            place='top-end'
                            openOnClick={false}
                            opacity={1}
                            style={{
                              backgroundColor: 'white',
                              fontFamily: 'Sofia',
                              color: '#222',
                              boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 15px'
                            }}
                          >
                            Card Hoteles
                          </Tooltip>
                        </span>
                      </p>)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )
      case 'hotelPoolM':
        return (
          <>
            {cards.map((hoteles: any) => (
              <Link
                key={hoteles.id}
                to={hoteles.redireccion}
                className='nlink'
              // onMouseOver={() => {
              //   console.log(hoteles.titulo);
              // }}
              >
                <div className='card__restaurant'>
                  <div className='card__restaurant--slider'>
                    <SliderHotelMobile
                      images={hoteles.img}
                      tipo={hoteles.tipo_hotel?.titulo}
                    />
                  </div>
                  <div className='card__restaurant--container'>
                    <div className='cardheaderr'>
                      <p className='title'>{hoteles.nombre_corto}</p>
                      <p className='review'>
                        {hoteles.calificacion}
                        <span>
                          <Icon
                            name='star'
                            list='items'
                            width={12}
                            height={12}
                            className='mr-0-2rem'
                          />
                        </span>
                        ({hoteles.reseña && hoteles.reseña !== null ? hoteles.reseña : 0} {
                          hoteles.reseña === '1'
                            ? `${messages[locale].hotelReview}`
                            : `${messages[locale].hotelsReview}`
                        })
                      </p>
                    </div>
                    <div className='cardprices'>
                      <div className='cardprices__card'>
                        <p className='ttcardprices'>
                          Desde
                          <span>{hoteles.precio_anterior.simbolo} {hoteles.precio_anterior.precio}</span>
                        </p>
                        <p className='sbcardprices'>{hoteles.precio.simbolo} {hoteles.precio.precio}</p>
                        {hoteles.igv !== null && hoteles.igv.precio > 0 && (<p className='sbbcardprices'>
                          + {hoteles.igv.simbolo} {hoteles.igv.precio}{' '}
                          <span>
                            {' '}
                            {hoteles.texto_tooltip_igv_card}
                            <Icon
                              name='info'
                              list='items'
                              width={14}
                              height={14}
                              className='mr-0-2rem tooltip-price'
                            />
                            <Tooltip
                              anchorSelect='.tooltip-price'
                              place='top-end'
                              openOnClick={false}
                              opacity={1}
                              style={{
                                backgroundColor: 'white',
                                fontFamily: 'Sofia',
                                color: '#222',
                                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 15px'
                              }}
                            >
                              Card Hoteles
                            </Tooltip>
                          </span>
                        </p>)}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </>
        )
      case 'hotelPoolD':
        return (
          <>
            {cards.map((hoteles: any) => (
              <div className='card__restaurant' key={hoteles.id}>
                <div className='card__restaurant--slider'>
                  <SliderHotelMobile
                    images={hoteles.img}
                    tipo={hoteles.tipo_tit}
                  />
                </div>
                <div className='card__restaurant--container'>
                  <div className='cardheaderr'>
                    <p className='title'>{hoteles.titulo}</p>
                    <p className='review'>
                      {hoteles.calificacion}
                      <span>
                        <Icon
                          name='star'
                          list='items'
                          width={12}
                          height={12}
                          className='mr-0-2rem'
                        />
                      </span>
                      ({hoteles.reseña} {messages[locale].hotelsReview})
                    </p>
                  </div>
                  <div className='cardprices'>
                    <div className='cardprices__card'>
                      <p className='ttcardprices'>
                        Desde
                        <span>{hoteles.precio_anterior.simbolo} {hoteles.precio_anterior.precio}</span>
                      </p>
                      <p className='sbcardprices'>{hoteles.precio.simbolo} {hoteles.precio.precio}</p>
                      {hoteles.igv !== null && hoteles.igv.precio > 0 && (<p className='sbbcardprices'>
                        + {hoteles.igv.simbolo} {hoteles.igv.precio}{' '}
                        <span>
                          {' '}
                          {hoteles.texto_tooltip_igv_card}
                          <Icon
                            name='info'
                            list='items'
                            width={14}
                            height={14}
                            className='mr-0-2rem tooltip-price'
                          />
                          <Tooltip
                            anchorSelect='.tooltip-price'
                            place='top-end'
                            openOnClick={false}
                            opacity={1}
                            style={{
                              backgroundColor: 'white',
                              fontFamily: 'Sofia',
                              color: '#222',
                              boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 15px'
                            }}
                          >
                            Card Hoteles
                          </Tooltip>
                        </span>
                      </p>)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )
      case 'tour':
        return 'card__restaurant--tour'
      default:
        return 'card__restaurant--hotel'
    }
  }

  return <>{typeCard({ type, cards })}</>
}

export default CardHotel
