import { useState } from "react";
import "./styles.scss";
import { Icon, IconText } from "components/atoms";

interface PriceInfo {
  precio: string;
  simbolo: string;
  moneda: string;
}

interface DisabledDateInfo {
  disponible: number;
  fecha: string;
  precio: PriceInfo;
}

interface CalendarProps {
  disabledDates: DisabledDateInfo[];
  isMonths: boolean;
  onDateSelect: (startDate: Date | null, endDate: Date | null) => void;
  selectedRange: { from: Date | null; to: Date | null };
  setSelectedRange: (range: { from: Date | null; to: Date | null }) => void;
  hiddenPrices: boolean;
}

interface CalendarDayProps {
  date: Date;
  dateString: string;
  isPastDate: boolean;
  isDisabled: boolean;
  disabledDateInfo?: DisabledDateInfo;
  selectedRange: { from: Date | null; to: Date | null };
  handleDateClick: (date: Date, dateString: string) => void;
  showStartTooltip: boolean;
  showEndTooltip: boolean;
  showSecondTooltip: string | null;
  showInvalidRangeTooltip: string | null;
  hiddenPrices: boolean;
}

const CalendarDay: React.FC<CalendarDayProps> = ({
  date,
  dateString,
  isPastDate,
  isDisabled,
  disabledDateInfo,
  selectedRange,
  handleDateClick,
  showStartTooltip,
  showEndTooltip,
  showSecondTooltip,
  showInvalidRangeTooltip,
  hiddenPrices
}) => {
  const isSelectedFrom = selectedRange.from?.getTime() === date.getTime();
  const isSelectedTo = selectedRange.to?.getTime() === date.getTime();
  const isInRange =
    selectedRange.from &&
    selectedRange.to &&
    date > selectedRange.from &&
    date < selectedRange.to;
  const isDisabledInRange = isInRange && isDisabled;
  const displaySecondTooltip = showSecondTooltip === dateString;
  const displayInvalidRangeTooltip = showInvalidRangeTooltip === dateString;

  const isToday =
    date.getDate() === new Date().getDate() &&
    date.getMonth() === new Date().getMonth() &&
    date.getFullYear() === new Date().getFullYear();

  const className = isPastDate
    ? "day daysPass"
    : isDisabled
    ? "day daysNotAvailable"
    : isInRange
    ? "day daysInRange"
    : "day daysAvailable";

  const calculateDaysBetween = () => {
    if (selectedRange.from && selectedRange.to) {
      const diffTime = Math.abs(
        selectedRange.to.getTime() - selectedRange.from.getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return `${diffDays} ${diffDays === 1 ? "noche" : "noches"}`;
    }
    return "";
  };

  const isDayMonday = date.getDay() === 1;
  const isDaySunday = date.getDay() === 0;

  return (
    <div
      className={`${className} ${
        isSelectedFrom
          ? "selectedInit"
          : isSelectedTo
          ? "selectedFinish"
          : isDisabledInRange
          ? "disabledInRange"
          : ""
      } ${isToday ? "todayOn" : ""}`}
      onClick={() => !isPastDate && handleDateClick(date, dateString)}
    >
      <p className="date-day">{date.getDate()}</p>
      <span className="date-text">
        {isPastDate
          ? ""
          : isDisabled
          ? "Solo salidas"
          : hiddenPrices
          ? ""
          : disabledDateInfo
          ? `${disabledDateInfo.precio.simbolo}${disabledDateInfo.precio.precio}`
          : ""}
      </span>

      {isSelectedFrom && showStartTooltip && !selectedRange.to && (
        <div className="tooltip-container">
          <div
            className={`tooltipcalendar ${
              isDayMonday
                ? "tooltipcalendar-monday"
                : isDaySunday
                ? "tooltipcalendar-sunday"
                : ""
            }`}
          >
            {isDisabled ? "Fecha no disponible" : "Fecha de entrada"}
          </div>
        </div>
      )}
      {isSelectedTo && showEndTooltip && (
        <div className="tooltip-container">
          <div
            className={`tooltipcalendar ${
              isDaySunday
                ? "tooltipcalendar-sunday"
                : isDayMonday
                ? "tooltipcalendar-monday"
                : ""
            }`}
          >
            <p className="checkout-text">Fecha de salida</p>
            <IconText
              nameIcon="moon"
              listIcon="items"
              w={12}
              h={12}
              text={calculateDaysBetween()}
            />
          </div>
        </div>
      )}
      {displaySecondTooltip && (
        <div className="tooltip-container">
          <div
            className={`tooltipcalendar ${
              isDayMonday
                ? "tooltipcalendar-monday"
                : isDaySunday
                ? "tooltipcalendar-sunday"
                : ""
            }`}
          >
            Solo salidas
          </div>
        </div>
      )}
      {displayInvalidRangeTooltip && (
        <div className="tooltip-container">
          <div
            className={`tooltipcalendar ${
              isDayMonday
                ? "tooltipcalendar-monday"
                : isDaySunday
                ? "tooltipcalendar-sunday"
                : ""
            }`}
          >
            Rango de fechas no disponible
          </div>
        </div>
      )}
    </div>
  );
};

export const Calendar: React.FC<CalendarProps> = ({
  disabledDates,
  isMonths,
  onDateSelect,
  selectedRange,
  hiddenPrices,
  setSelectedRange,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showStartTooltip, setShowStartTooltip] = useState<boolean>(true);
  const [showEndTooltip, setShowEndTooltip] = useState<boolean>(true);
  const [showSecondTooltip, setShowSecondTooltip] = useState<string | null>(
    null
  );
  const [showInvalidRangeTooltip, setShowInvalidRangeTooltip] = useState<
    string | null
  >(null);

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    if (
      newDate >= new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ) {
      setCurrentDate(newDate);
    }
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const daysInMonth = (month: number, year: number) =>
    new Date(year, month + 1, 0).getDate();

  const getDisabledDateInfo = (dateString: string) => {
    return disabledDates.find((dateInfo) => dateInfo.fecha === dateString);
  };

  const handleDateClick = (date: Date, dateString: string) => {
    const disabledDateInfo = getDisabledDateInfo(dateString);
    setShowSecondTooltip(null);
    setShowInvalidRangeTooltip(null);

    if (!selectedRange.from) {
      // Si no hay fecha inicial seleccionada, seleccionamos esta fecha como inicial
      handleNewRange(date, dateString, disabledDateInfo);
    } else if (selectedRange.from && !selectedRange.to) {
      // Si ya hay fecha inicial pero no final
      if (date.getTime() === selectedRange.from.getTime()) {
        // Si se hace clic en la misma fecha inicial, deseleccionamos todo
        setSelectedRange({ from: null, to: null });
        setShowStartTooltip(false);
        setShowEndTooltip(false);
        onDateSelect(null, null);
      } else {
        // Si es una fecha diferente, la tratamos como fecha final
        handleSelectEndDate(date, dateString, disabledDateInfo);
      }
    } else {
      // Si ya hay un rango completo seleccionado, empezamos un nuevo rango
      handleNewRange(date, dateString, disabledDateInfo);
    }
  };

  const handleNewRange = (
    date: Date,
    dateString: string,
    disabledDateInfo: DisabledDateInfo | undefined
  ) => {
    if (disabledDateInfo && disabledDateInfo.disponible === 0) {
      setSelectedRange({ from: null, to: null });
      setShowStartTooltip(false);
      setShowEndTooltip(false);
      setShowSecondTooltip(dateString);
    } else {
      setSelectedRange({ from: date, to: null });
      setShowStartTooltip(true);
      setShowEndTooltip(false);
      onDateSelect(date, null);
    }
  };

  const handleSelectEndDate = (
    date: Date,
    dateString: string,
    disabledDateInfo: DisabledDateInfo | undefined
  ) => {
    if (date >= selectedRange.from!) {
      let isRangeValid = true;
      let firstDisabledDate: Date | null = null;

      for (
        let d = new Date(selectedRange.from!);
        d <= date;
        d.setDate(d.getDate() + 1)
      ) {
        const checkDateString = d.toISOString().split("T")[0];
        const checkDisabledDateInfo = getDisabledDateInfo(checkDateString);
        if (checkDisabledDateInfo && checkDisabledDateInfo.disponible === 0) {
          isRangeValid = false;
          if (firstDisabledDate === null) {
            firstDisabledDate = new Date(d);
          }
        }
      }

      if (isRangeValid) {
        // console.log(
        //   "Rango válido seleccionado desde",
        //   selectedRange.from,
        //   "hasta",
        //   date
        // );
        setSelectedRange({ ...selectedRange, to: date });
        onDateSelect(selectedRange.from, date);
      } else {
        if (date > firstDisabledDate!) {
          // console.log(
          //   "Rango no válido debido a fechas no disponibles en el medio. Solo seleccionando fecha inicial:",
          //   selectedRange.from
          // );
          setSelectedRange({ from: selectedRange.from, to: null });
          onDateSelect(selectedRange.from, null);
          setShowInvalidRangeTooltip(dateString);
        } else {
          // console.log(
          //   "Rango no válido debido a fechas no disponibles. Seleccionando hasta la primera fecha no disponible:",
          //   firstDisabledDate
          // );
          setSelectedRange({ from: selectedRange.from, to: firstDisabledDate });
          onDateSelect(selectedRange.from, firstDisabledDate);
        }
      }
      setShowEndTooltip(true);
      setShowStartTooltip(false);
    } else {
      // console.log(
      //   "Fecha de salida seleccionada es anterior a la fecha de entrada. Reiniciando selección."
      // );
      setSelectedRange({ from: date, to: null });
      setShowStartTooltip(true);
      setShowEndTooltip(false);
      onDateSelect(date, null);
    }
  };

  const generateCalendar = (date: Date) => {
    const calendar = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    const days = daysInMonth(month, year);

    const today = new Date().toLocaleString("en-US", {
      timeZone: "America/Lima",
    });
    const todayDate = new Date(today);
    todayDate.setHours(0, 0, 0, 0);

    // Comienza en Lunes
    const firstDayOfMonth = (new Date(year, month, 1).getDay() + 6) % 7;

    for (let i = 0; i < firstDayOfMonth; i++) {
      calendar.push(<div key={`empty-${i}`} className="day empty"></div>);
    }

    for (let i = 1; i <= days; i++) {
      const date = new Date(year, month, i);
      const dateString = date.toISOString().split("T")[0];
      const isPastDate = date < todayDate;

      const disabledDateInfo = getDisabledDateInfo(dateString);
      const isDisabled = disabledDateInfo
        ? disabledDateInfo.disponible === 0
        : isPastDate;

      calendar.push(
        <CalendarDay
          key={dateString}
          date={date}
          dateString={dateString}
          isPastDate={isPastDate}
          isDisabled={isDisabled}
          disabledDateInfo={disabledDateInfo}
          selectedRange={selectedRange}
          handleDateClick={handleDateClick}
          showStartTooltip={showStartTooltip}
          showEndTooltip={showEndTooltip}
          showSecondTooltip={showSecondTooltip}
          showInvalidRangeTooltip={showInvalidRangeTooltip}
          hiddenPrices={hiddenPrices}
        />
      );
    }

    return calendar;
  };

  const daysOfWeek = ["L", "M", "M", "J", "V", "S", "D"];

  const monthName1 = currentDate.toLocaleString("default", { month: "long" });
  const year1 = currentDate.getFullYear();
  const nextMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1
  );
  const monthName2 = nextMonthDate.toLocaleString("default", { month: "long" });
  const year2 = nextMonthDate.getFullYear();

  const renderMobile = () => {
    const months = [];
    for (let i = 0; i < 18; i++) {
      const monthDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + i
      );
      const monthName = monthDate.toLocaleString("default", { month: "long" });
      const year = monthDate.getFullYear();

      months.push(
        <div key={i} className="calendar-month">
          <div className="calendar-header">
            <p>{`${
              monthName.charAt(0).toUpperCase() + monthName.slice(1)
            } ${year}`}</p>
          </div>
          <div className="calendar-grid">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="day-header">
                {day}
              </div>
            ))}
            {generateCalendar(monthDate)}
          </div>
        </div>
      );
    }
    return months;
  };

  const renderDesktop = () => {
    const isPreviousMonthDisabled =
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1) <
      new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    return (
      <>
        <div className="calendar-month1">
          <div className="calendar-header1">
            <button
              onClick={handlePreviousMonth}
              disabled={isPreviousMonthDisabled}
            >
              <Icon
                name={"leftp"}
                list="arrows"
                className="iconactive"
                color={isPreviousMonthDisabled ? "" : "#D69C4F"}
              />
            </button>

            <div className="monthw">
              <p>{`${
                monthName1.charAt(0).toUpperCase() + monthName1.slice(1)
              } ${year1}`}</p>
            </div>
          </div>
          <div className="calendar-grid">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="day-header">
                {day}
              </div>
            ))}
            {generateCalendar(currentDate)}
          </div>
        </div>

        <hr className="line-calendar" />

        <div className="calendar-month2">
          <div className="calendar-header2">
            <div className="monthw">
              <p>{`${
                monthName2.charAt(0).toUpperCase() + monthName2.slice(1)
              } ${year2}`}</p>
            </div>
            <button onClick={handleNextMonth}>
              <Icon name={"rightp"} list="arrows" className="iconactive" />
            </button>
          </div>

          <div className="calendar-grid">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="day-header">
                {day}
              </div>
            ))}
            {generateCalendar(nextMonthDate)}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="calendar">
      <div className="calendar-body">
        {isMonths ? renderMobile() : renderDesktop()}
      </div>
    </div>
  );
};
