import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BannerSlider } from "components/organisms";
import { banner_data_centrado } from "./data";
import { toast } from "sonner";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import useRecaptcha from "hooks/useRecaptcha";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { logSentryErrorResponse } from "utils/function";
import * as Sentry from '@sentry/react';

interface FormData {
  dni: string;
  terminos_condiciones: boolean;
  politicas_datos_personales: boolean;
  publicidad_y_promociones: boolean;
}

const defaultValues: FormData = {
  dni: "",
  terminos_condiciones: false,
  politicas_datos_personales: false,
  publicidad_y_promociones: false,
};

const FriendsFamily = () => {
  const { t, i18n } = useTranslation();
  const { getRecaptchaToken } = useRecaptcha();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: FormData) => {
    if (!isValid) return;

    const token = await getRecaptchaToken();
    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      setIsLoading(true);
      const { dni, terminos_condiciones, politicas_datos_personales, publicidad_y_promociones } = data;

      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/form/friends_and_family`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          },
          body: JSON.stringify({
            dni: parseInt(dni),
            terminos_condiciones : terminos_condiciones  ? 1 : 0,
            politicas_datos_personales : politicas_datos_personales  ? 1 : 0,
            publicidad_y_promociones : publicidad_y_promociones  ? 1 : 0,
          }),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message, { position: "top-right" });
        reset();
      } else {
        logSentryErrorResponse(response, 'Friends And Family Form');
        toast.error(responseData.message, { position: "top-right" });
      }

      setIsLoading(false);
    } catch (error) {
      Sentry.captureException(error); // Captura el error en Sentry
      console.error("Error al enviar los datos a la API:", error);
    }
  };

  return (
    <>
      <BannerSlider data={banner_data_centrado} />
      <div className="friends_family friends_family-element">
        <div className="friends_family__container">
          <section className="section-descripcion-fyf">
            <p>{t("friendsFamily.friendsFamilyProgram")}</p>
          </section>
          <section className="section-beneficios-colaboradores">
            <h2 className="title txt-celeste">
              {t("friendsFamily.benefitsForEmployees")}
            </h2>
            <p>{t("friendsFamily.earnFreeNights")}</p>
            <div className="section-value">
              <div className="value-dato">
                <span className="value-num">15</span>
                <p>{t("friendsFamily.referredNights")}</p>
              </div>
              <span className="value-simbolo">=</span>
              <div className="value-dato">
                <span className="value-num">1</span>
                <p>{t("friendsFamily.freeNight")}</p>
              </div>
            </div>
            <p>{t("friendsFamily.enjoyFreeNights")}</p>
          </section>
          <section className="section-beneficios-referidos">
            <h2 className="title txt-celeste">
              {t("friendsFamily.benefitsForReferred")}
            </h2>
            <div className="section-percent">
              <span className="percent-num">30</span>
              <div className="percent-text">
                <span className="percent-simbolo">%</span>
                Dscto.
              </div>
            </div>
            <p>{t("friendsFamily.atAnyHotel")}</p>
          </section>
          <section className="section-participa">
            <h2 className="title txt-celeste">
              {t("friendsFamily.howToParticipate")}
            </h2>
            <div className="section-paso">
              <div className="item-p">
                <img
                  src="https://referidos.aplicacionescasaandina.com/images/icon1.png"
                  alt="step-1"
                ></img>
                <h3>{t("friendsFamily.step1")}</h3>
                <p>
                  {t("friendsFamily.enrollInProgram")}
                  <br />
                  {t("friendsFamily.allEmployeesCanBeAmbassadors")}
                </p>
              </div>
              <div className="item-p">
                <img
                  src="https://referidos.aplicacionescasaandina.com/images/icon2.png"
                  alt="step-2"
                ></img>
                <h3>{t("friendsFamily.step2")}</h3>
                <p>
                  {t("friendsFamily.referFriend")}{" "}
                  <a href="mailto:centraldereservas@casa-andina.com">
                    centraldereservas@casa-andina.com
                  </a>{" "}
                  {t("friendsFamily.subjectCode")}{" "}
                  <span className="txt-celeste">FRIFAM</span>.<br />
                  {t("friendsFamily.reservationConfirmed")}
                </p>
              </div>
            </div>
            <h2 className="title txt-celeste">
              {t("friendsFamily.checkAccumulatedNights")}
              <br />
              {t("friendsFamily.accumulatedNights")}
            </h2>
            <p>{t("friendsFamily.monthlyStatement")}</p>
            <p>
              <span className="txt-celeste">
                {t("friendsFamily.freeNightEarned")}
              </span>{" "}
              {t("friendsFamily.prizeLetter")}
            </p>
          </section>
          <section id="sec-g" className="section-formulario">
            <h2 className="title txt-blanco">
              {t("friendsFamily.registerInProgram")}
            </h2>
            <p>{t("friendsFamily.acceptTerms")}</p>

            <form
              className="form-afiliado"
              id="formulario"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="dni"
                control={control}
                rules={{ required: t("friendsFamily.enterDocument") }}
                render={({ field }) => (
                  <div className="input-container">
                    <input
                      {...field}
                      type="text"
                      className="form-text input"
                      placeholder={`${t("forms.requiredField")}*`}
                    />
                    {errors.dni && <span>{errors.dni.message}</span>}
                  </div>
                )}
              />

              <Controller
                name="terminos_condiciones"
                control={control}
                rules={{ required: t("forms.requiredField") }}
                render={({ field: { onChange, value, ...field } }) => (
                  <div className="checkbox__form">
                    <label>
                      <input
                        {...field}
                        type="checkbox"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                      <p>{t("forms.acceptTermsPart1")} <a href={`/${i18n.language}/terminos-y-condiciones`} className="terms-url" target="_blank">{t("forms.acceptTermsLink")}</a> {t("forms.acceptTermsPart2")}*</p>
                    </label>
                    {errors.terminos_condiciones && (
                      <span>{errors.terminos_condiciones.message}</span>
                    )}
                  </div>
                )}
              />

              <Controller
                name="politicas_datos_personales"
                control={control}
                rules={{ required: t("forms.requiredField") }}
                render={({ field: { onChange, value, ...field } }) => (
                  <div className="checkbox__form">
                    <label>
                      <input
                        {...field}
                        type="checkbox"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                      <p>{t("forms.acceptPrivacy")} <a href={`/${i18n.language}/politica-de-proteccion-de-datos-personales`} className="terms-url" target="_blank">{t("forms.acceptPrivacyLink")}.</a>*</p>
                    </label>
                    {errors.politicas_datos_personales && (
                      <span>{errors.politicas_datos_personales.message}</span>
                    )}
                  </div>
                )}
              />

              <Controller
                name="publicidad_y_promociones"
                control={control}
                rules={{ }}
                render={({ field: { onChange, value, ...field } }) => (
                  <div className="checkbox__form">
                    <label>
                      <input
                        {...field}
                        type="checkbox"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                      <p>{`${t("forms.sendAds")}`}</p>
                    </label>
                    {errors.publicidad_y_promociones && (
                      <span>{errors.publicidad_y_promociones.message}</span>
                    )}
                  </div>
                )}
              />

              <button
                className="submit-button"
                type="submit"
                title="Enviar"
                disabled={!isValid || isLoading}
              >
                {isLoading ? t("forms.sending") : t("forms.send")}
              </button>

            </form>
          </section>
          <section className="section-nota">
            <p>
              {t("friendsFamily.discounts")}
              <br />
              {t("friendsFamily.campaigns")}
            </p>
            <p>
              {t("friendsFamily.viewTerms")}{" "}
              <a
                href="https://referidos.aplicacionescasaandina.com/images/CAH_TyC_Friends&amp;Family.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("friendsFamily.here")}
              </a>
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default FriendsFamily;
