import React, { Fragment, useState } from "react";
import { Button, Dropdown, Icon, IconText, Text } from "components/atoms";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "components/molecules";
import { SliderHotelMobile } from "components/organisms";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import { messages } from "utils/lang";
import "./styles.scss";
import {
  estadiasDate,
  getDateWithoutTime,
  handleTravelClick,
  handleTravelClickWhithoutDate,
  redirectUrlExternal,
} from "utils/function";
import { IPromotionsCards } from "./props";
import {
  DESCRIPTIONTOOLTIP,
  MIN_ATRIBUTO_BANK_LENGTH,
  MIN_ATRIBUTO_LENGTH,
} from "utils/values";
import { useAppSelector } from "hooks/useAppSelector";
import { useTranslation } from "react-i18next";

const PromotionsCard = ({
  data,
  codigo,
  restaurantRef,
  isTag,
  bank,
  type,
  title,
  isFilter,
  skeleton,
  pTS,
  pBS,
  pT,
  pB,
  idFilter,
}: IPromotionsCards) => {
  const { locale } = useIntl();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(false);
  const loading = useAppSelector((state) => state.language.loading);

  const [modal, setModal] = useState(false);

  const coin = useAppSelector((state) => state.config.coin);

  const [modalIndex, setModalIndex] = useState(null);

  const toogleModal = (index: any) => {
    setModalIndex(index === modalIndex ? null : index);
  };

  const handleSelect = (option: any) => {
    setSelectedOption(option.titulo);
    idFilter(option.id);
  };

  const filtro = {
    ordenar_por: [
      {
        id: 1,
        titulo: t("betterPrices.sortByRecommended"),
      },
      {
        id: 2,
        titulo: t("betterPrices.sortByPriceLowToHigh"),
      },
      {
        id: 3,
        titulo: t("betterPrices.sortByPriceHighToLow"),
      },
      {
        id: 4,
        titulo: t("betterPrices.sortTopRated"),
      },
    ],
  };

  const [tooltipStates, setTooltipStates] = useState<boolean[]>(
    new Array(data?.length).fill(false)
  );

  const handleTooltipClick = (e: any, index: number) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipStates(newTooltipStates);
    e.stopPropagation();
  };

  const cardsType = ({ better, index, bgLife }: any) => {
    switch (type) {
      case "better-prices": {
        return (
          <Link to={better.slug} className="nlink">
            <div
              className="betterprice__description--card"
              onClick={() => handleTravelClick(better, coin)}
            >
              <div className="betterprice__description--card--slider">
                <SliderHotelMobile
                  tipo={better.tipo_hotel?.titulo}
                  images={better.img}
                  city={better.destino}
                  bgColor={better.tipo_hotel?.color}
                />
              </div>
              <div className="betterprice__description--card--container">
                {better.etiqueta_precio.texto && (
                  <div
                    className="tagposition"
                    style={{
                      backgroundColor: better.etiqueta_precio.color,
                    }}
                  >
                    <Text
                      text={better.etiqueta_precio.texto}
                      type="paragraph"
                      className="tag"
                    />
                  </div>
                )}
                <div
                  className="cardheaderr"
                >
                  <Text
                    text={better.nombre_corto}
                    type="paragraph"
                    className="title"
                  />
                  <div>
                    {better.calificacion > 0 && (
                      <div className="review">
                        <Text text={better.calificacion} type="paragraph" />

                        <IconText
                          text={`(${
                            better.reseña !== null ? better.reseña : 0
                          } ${messages[locale].hotelsReview})`}
                          nameIcon="star2"
                          listIcon="items"
                          w={16}
                          h={16}
                          type="zero"
                        />
                      </div>
                    )}
                    <div className="estilosicon">
                      <img
                        src={
                          better.atributo_destacado_hotel
                            ?.icon_atributo_destacado?.path
                        }
                        alt={
                          better.atributo_destacado_hotel
                            ?.icon_atributo_destacado?.alt
                        }
                        width={12}
                        height={12}
                      />
                      <Text
                        text={
                          better?.atributo_destacado_hotel.atributo_destacado &&
                          better?.atributo_destacado_hotel?.atributo_destacado
                            .length > MIN_ATRIBUTO_LENGTH
                            ? `${better?.atributo_destacado_hotel.atributo_destacado.slice(
                                0,
                                MIN_ATRIBUTO_LENGTH
                              )}`
                            : better?.atributo_destacado_hotel
                                .atributo_destacado
                        }
                        className="atributo"
                        type="paragraph"
                      />
                    </div>
                  </div>
                </div>
                <div className="betterprice__description--card--content">
                  <div
                    className="cardprices"
                    // style={{
                    //   paddingTop:
                    //     better.precio_anterior.precio >
                    //     better.precio.precio
                    //       ? '0px'
                    //       : '20px'
                    // }}
                  >
                    <div className="cardprices__services">
                      <div className="cardprices__services--better">
                        {better.atributo_destacado
                          .slice(0, 4)
                          .map((item: any, index: number) => (
                            <div className="estilos" key={index}>
                              <Icon
                                name={"check"}
                                list="items"
                                width={12}
                                height={12}
                                color={item.color ? "#14C67A" : "#7E7E7E"}
                              />
                              <p
                                style={{
                                  color: item.color || "#7E7E7E",
                                }}
                              >
                                {item.texto}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="cardprices__card">
                      <div>
                        <p
                          className="ttcardprices"
                          style={{
                            marginBottom:
                              better.precio_anterior.precio >
                              better.precio.precio
                                ? "0px"
                                : "20px",
                          }}
                        >
                          {t("betterPrices.nightFrom")}
                          {parseFloat(better.precio_anterior.precio) >
                            parseFloat(better.precio.precio) && (
                            <span>
                              {better.precio_anterior.simbolo}{" "}
                              {better.precio_anterior.precio}
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="carddescuento">
                        {parseFloat(better.precio_anterior.precio) >
                          parseFloat(better.precio.precio) && (
                          <Text
                            text={`- ${better.descuento}%`}
                            className="ttdescuento"
                            style={{
                              backgroundColor:
                                better.etiqueta_precio.color ||
                                better.tipo_hotel?.color,
                            }}
                            type="paragraph"
                          />
                        )}
                        <Text
                          text={`${better.precio.simbolo} ${better.precio.precio}`}
                          className="sbcardprices"
                          type="paragraph"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="date">
                      {better.fecha_inicio && better.fecha_fin && (
                        <div>
                          Estadías del <br />{" "}
                          {getDateWithoutTime(better.fecha_inicio)} al{" "}
                          {getDateWithoutTime(better.fecha_fin)}
                        </div>
                      )}
                    </div>
                    <div className="column">
                      <div className="sbbcardprices">
                        {better.igv !== null && better.igv.precio > 0 && (
                          <>
                            <Text
                              text={`+ ${better.igv.simbolo} ${better.igv.precio} ${better.texto_tooltip_igv_card}`}
                              type="paragraph"
                            />
                            <Icon
                              name="info2"
                              list="items"
                              color="gray"
                              width={14}
                              height={14}
                              className={`tooltip-price-${better.id}`}
                            />
                          </>
                        )}
                        <Tooltip
                          anchorSelect={`.tooltip-price-${better.id}`}
                          place="top-end"
                          opacity={1}
                          style={{
                            backgroundColor: "white",
                            color: "#222",
                            boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                            width: "100%",
                            maxWidth: "280px",
                            zIndex: 101,
                          }}
                        >
                          {better.texto_tooltip_igv}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      }
      case "hoteles-mob": {
        return (
          <Link to={better.slug} className="nlink">
            <div className="hotelesmob__description--card">
              <div className="hotelesmob__description--card--slider">
                <SliderHotelMobile
                  tipo={better.tipo_hotel?.titulo}
                  images={better.img}
                  city={better.destino}
                  bgColor={better.tipo_hotel?.color}
                />
              </div>
              <div className="hotelesmob__description--card--container">
                <div
                  className="cardheaderr"
                >
                  <Text
                    text={better.nombre_corto}
                    type="paragraph"
                    className="title"
                  />
                  <div
                    style={{ display: "flex", gap: 6, flexDirection: "column" }}
                  >
                    {better.calificacion > 0 && (
                      <div className="review">
                        <Text text={better.calificacion} type="paragraph" />

                        <IconText
                          text={`(${
                            better.reseña !== null ? better.reseña : 0
                          } ${messages[locale].hotelsReview})`}
                          nameIcon="star2"
                          listIcon="items"
                          w={16}
                          h={16}
                          type="zero"
                        />
                      </div>
                    )}
                    {better?.atributo_destacado_hotel.atributo_destacado && (
                      <div className="estilosicon">
                        {better.atributo_destacado_hotel
                          ?.icon_atributo_destacado === null ? (
                          <div>
                            <Icon
                              name="check"
                              list="items"
                              width={14}
                              height={14}
                            />
                          </div>
                        ) : (
                          <img
                            src={
                              better.atributo_destacado_hotel
                                ?.icon_atributo_destacado?.path
                            }
                            alt={
                              better.atributo_destacado_hotel
                                ?.icon_atributo_destacado?.alt
                            }
                            width={12}
                            height={12}
                          />
                        )}
                        <Text
                          text={
                            better?.atributo_destacado_hotel
                              .atributo_destacado &&
                            better?.atributo_destacado_hotel?.atributo_destacado
                              .length > MIN_ATRIBUTO_BANK_LENGTH
                              ? `${better?.atributo_destacado_hotel.atributo_destacado.slice(
                                  0,
                                  MIN_ATRIBUTO_BANK_LENGTH
                                )}...`
                              : better?.atributo_destacado_hotel
                                  .atributo_destacado
                          }
                          className="atributo"
                          type="paragraph"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="hotelesmob__description--card--content">
                  <div
                    className="cardprices"
                    // style={{
                    //   paddingTop:
                    //     better.precio_anterior.precio >
                    //     better.precio.precio
                    //       ? '0px'
                    //       : '20px'
                    // }}
                  >
                    <div className="cardprices__card">
                      <div style={{ marginBottom: "8px" }}>
                        <p className="ttcardprices">
                          {t("betterPrices.nightFrom")}
                          {parseFloat(better.precio_anterior.precio) >
                            parseFloat(better.precio.precio) && (
                            <span>
                              {better.precio_anterior.simbolo}{" "}
                              {better.precio_anterior.precio}
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="carddescuento">
                        {parseFloat(better.precio_anterior.precio) >
                          parseFloat(better.precio.precio) && (
                          <Text
                            text={`- ${better.descuento}%`}
                            className="ttdescuento"
                            style={{
                              backgroundColor:
                                better.etiqueta_precio.color ||
                                better.tipo_hotel?.color,
                            }}
                            type="paragraph"
                          />
                        )}
                        <Text
                          text={`${better.precio.simbolo} ${better.precio.precio}`}
                          className="sbcardprices"
                          type="paragraph"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="date">
                      {better.fecha_inicio && better.fecha_fin && (
                        <div>
                          Estadías del <br />{" "}
                          {getDateWithoutTime(better.fecha_inicio)} al{" "}
                          {getDateWithoutTime(better.fecha_fin)}
                        </div>
                      )}
                    </div>
                    <div className="column">
                      <div className="sbbcardprices">
                        {better.igv !== null && better.igv.precio > 0 && (
                          <>
                            <Text
                              text={`+ ${better.igv.simbolo} ${better.igv.precio} ${better.texto_tooltip_igv_card}`}
                              type="paragraph"
                            />
                            <Icon
                              name="info2"
                              list="items"
                              color="gray"
                              width={14}
                              height={14}
                              className={`tooltip-price-${better.id}`}
                            />
                          </>
                        )}
                        <Tooltip
                          isOpen={tooltipStates[better.id]}
                          anchorSelect={`.tooltip-price-${better.id}`}
                          place="top-start"
                          opacity={1}
                          clickable={true}
                          style={{
                            backgroundColor: "white",
                            color: "#222",
                            boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                            width: "280px",
                            zIndex: 101,
                          }}
                        >
                          {better.texto_tooltip_igv}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      }
      case "hoteles": {
        return (
          <div
            className="card__hotelesprices nlink"
            onClick={() => navigate(better.slug)}
            // to={better.slug}
          >
            <div className="card__hotelesprices--slider">
              <SliderHotelMobile
                tipo={better.tipo_hotel.titulo}
                bgColor={better.tipo_hotel?.color}
                city={better.destino}
                images={better.img}
              />
            </div>
            <div className="card__hotelesprices--container">
              <div className="cardheaderr">
                <p className="title">{better.titulo}</p>
                <div>
                  <p className="review">
                    {better.calificacion}
                    <span>
                      <Icon
                        name="star"
                        list="items"
                        width={12}
                        height={12}
                        className="mr-0-2rem"
                      />
                    </span>
                    (
                    {better.reseña && better.reseña !== null
                      ? better.reseña
                      : 0}{" "}
                    {better.reseña === "1"
                      ? `${t("betterPrices.review")}`
                      : `${t("betterPrices.reviews")}`}
                    )
                  </p>
                </div>
              </div>
              <div className="cardprices">
                <div className="cardprices__card">
                  <p className="ttcardprices">
                    {t("general.from")}
                    <span>
                      {better.precio_anterior.simbolo}{" "}
                      {better.precio_anterior.precio}
                    </span>
                  </p>
                  <p className="sbcardprices">
                    {better.precio.simbolo} {better.precio.precio}
                  </p>
                  <div onClick={(e) => e.stopPropagation()}>
                    {better.igv !== null && better.igv.precio > 0 && (
                      <div
                        onClick={(e) => handleTooltipClick(e, better.id)}
                        className="sbbcardprices"
                      >
                        <Text
                          text={`+ ${better.igv.simbolo} ${better.igv.precio} ${better.texto_tooltip_igv_card}`}
                          type="paragraph"
                        />
                        <Icon
                          name="question-card"
                          color="gray"
                          list="items"
                          width={14}
                          height={14}
                          className={`tooltip-price-${better.id}`}
                        />
                      </div>
                    )}
                    <Tooltip
                      isOpen={tooltipStates[better.id]}
                      anchorSelect={`.tooltip-price-${better.id}`}
                      place="top-end"
                      clickable={true}
                      opacity={1}
                      style={{
                        backgroundColor: "white",
                        color: "#222",
                        boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                        width: "100%",
                        maxWidth: "280px",
                        fontSize: "12px",
                        fontFamily: "Quicksand",
                        zIndex: 101,
                      }}
                    >
                      {better.texto_tooltip_igv}
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      case "campaign": {
        return (
          <div>
            {loading ? (
              <div className="bankcard__description--card">
                <div className="bankcard__description--card--slider">
                  <div className="skeletonbox">
                    <div className="skeletonbox__container"></div>
                  </div>
                </div>
                <div className="bankcard__description--card--container">
                  <div className="cardheaderr">
                    <div className="skeleton">
                      <div className="skeleton__container"></div>
                    </div>
                  </div>
                  <div className="skeleton">
                    <div className="skeleton__container"></div>
                  </div>
                  <div className="bankcard__description--card--content">
                    <div className="skeleton">
                      <div className="skeleton__container"></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="bankcard__description--card"
                onClick={() =>
                  handleTravelClickWhithoutDate(better, coin, codigo)
                }
              >
                <div className="bankcard__description--card--slider">
                  <SliderHotelMobile
                    tipo={better.tipo_hotel?.titulo}
                    images={better.img}
                    city={better.destino}
                    bgColor={better.tipo_hotel?.color}
                  />
                </div>
                <div className="bankcard__description--card--container">
                  {bank && bank.logo?.path && (
                    <div
                      className="tagbank"
                      style={{
                        backgroundColor: bank?.color || "gray",
                      }}
                    >
                      <img src={bank.logo?.path} alt={bank.titulo} />
                    </div>
                  )}
                  {!bank?.logo?.path && better.etiqueta_precio?.texto &&  (
                      <div
                        className="tagposition"
                        style={{
                          backgroundColor: better.etiqueta_precio?.color,
                        }}
                      >
                        <Text
                          text={better.etiqueta_precio?.texto}
                          type="paragraph"
                          className="tag"
                        />
                      </div>
                    )}
                  <div
                    className="cardheaderr"
                  >
                    <Text
                      text={better.nombre_corto}
                      type="subtitle2"
                      className="title"
                    />
                    <div>
                      {better.calificacion > 0 && (
                        <div className="review">
                          <Text text={better.calificacion} type="paragraph" />

                          <IconText
                            text={`(${
                              better.reseña !== null ? better.reseña : 0
                            } ${messages[locale].hotelsReview})`}
                            nameIcon="star2"
                            listIcon="items"
                            w={16}
                            h={16}
                            type="zero"
                          />
                        </div>
                      )}
                      {better?.atributo_destacado_hotel.atributo_destacado && (
                        <div className="estilosicon">
                          {better.atributo_destacado_hotel
                            ?.icon_atributo_destacado === null ? (
                            <div>
                              <Icon
                                name="check"
                                list="items"
                                width={14}
                                height={14}
                              />
                            </div>
                          ) : (
                            <img
                              src={
                                better.atributo_destacado_hotel
                                  ?.icon_atributo_destacado?.path
                              }
                              alt={
                                better.atributo_destacado_hotel
                                  ?.icon_atributo_destacado?.alt
                              }
                              width={12}
                              height={12}
                            />
                          )}
                          <Text
                            text={
                              better?.atributo_destacado_hotel
                                .atributo_destacado &&
                              better?.atributo_destacado_hotel
                                ?.atributo_destacado.length >
                                MIN_ATRIBUTO_BANK_LENGTH
                                ? `${better?.atributo_destacado_hotel.atributo_destacado.slice(
                                    0,
                                    MIN_ATRIBUTO_BANK_LENGTH
                                  )}...`
                                : better?.atributo_destacado_hotel
                                    .atributo_destacado
                            }
                            className="atributo"
                            type="paragraph"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bankcard__description--card--content">
                    <div
                      className="cardprices"
                      // style={{
                      //   paddingTop:
                      //     better.precio_anterior.precio >
                      //     better.precio.precio
                      //       ? '0px'
                      //       : '20px'
                      // }}
                    >
                      <div className="cardprices__services">
                        <div className="cardprices__services--better">
                          {better.atributo_destacado
                            .slice(0, 4)
                            .map((item: any, index: number) => (
                              <div className="estilos" key={index}>
                                <Icon
                                  name={"check"}
                                  list="items"
                                  width={12}
                                  height={12}
                                  color={item.color ? "#14C67A" : "#7E7E7E"}
                                />
                                <p
                                  style={{
                                    color: item.color || "#7E7E7E",
                                  }}
                                >
                                  {item.texto}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div
                        className="cardprices__card"
                        style={{
                          gap:
                            parseFloat(better.precio.precio.length) >= 5
                              ? "5px"
                              : "10px",
                        }}
                      >
                        <div>
                          <p
                            className="ttcardprices"
                            style={{
                              marginBottom:
                                better.precio_anterior.precio >
                                better.precio.precio
                                  ? "0px"
                                  : "20px",
                            }}
                          >
                            {t("betterPrices.nightFrom")}
                            {parseFloat(better.precio_anterior.precio) >
                              parseFloat(better.precio.precio) && (
                              <span
                                style={{
                                  fontSize:
                                    parseFloat(better.precio.precio.length) >= 5
                                      ? "12px"
                                      : "14px",
                                }}
                              >
                                {better.precio_anterior.simbolo}{" "}
                                {better.precio_anterior.precio}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="carddescuento">
                          {parseFloat(better.precio_anterior.precio) >
                            parseFloat(better.precio.precio) && (
                            <Text
                              text={`- ${better.descuento}%`}
                              className="ttdescuento"
                              style={{
                                backgroundColor:
                                  better.etiqueta_precio.color ||
                                  better.tipo_hotel?.color,
                              }}
                              type="paragraph"
                            />
                          )}
                          <Text
                            text={`${better.precio.simbolo} ${better.precio.precio}`}
                            className="sbcardprices"
                            type="paragraph"
                            style={{
                              fontSize:
                                parseFloat(better.precio.precio.length) >= 5
                                  ? "18px"
                                  : "25px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text">
                      <div className="date">
                        {better.fecha_inicio && better.fecha_fin && (
                          <div>
                            <Text
                              html={{
                                __html: estadiasDate(
                                  better.fecha_inicio,
                                  better.fecha_fin,
                                  t,
                                  false
                                ),
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="column">
                        <div className="sbbcardprices">
                          {better.igv !== null && better.igv.precio > 0 && (
                            <>
                              <Text
                                text={`+ ${better.igv.simbolo} ${better.igv.precio} ${better.texto_tooltip_igv_card}`}
                                type="paragraph"
                              />
                              <Icon
                                name="question-card"
                                list="items"
                                color="gray"
                                width={14}
                                height={14}
                                className={`tooltip-price-${better.id}`}
                              />
                            </>
                          )}
                          <Tooltip
                            anchorSelect={`.tooltip-price-${better.id}`}
                            place="top-end"
                            opacity={1}
                            style={{
                              backgroundColor: "white",
                              color: "#222",
                              boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                              width: "280px",
                              zIndex: 101,
                            }}
                          >
                            {better.texto_tooltip_igv}
                          </Tooltip>
                        </div>
                        {better.descuento_life !== null &&
                          better.descuento_life !== 0 && (
                            <div className="bg">
                              <Icon
                                name={"tag"}
                                list={"items"}
                                width={14}
                                height={14}
                                className="tag"
                              />
                              <Text
                                text={t("betterPrices.dsctoLife", { descuento_life: better.descuento_life})}
                                type="paragraph"
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }
      case "campaign2": {
        return (
          <div>
            {loading ? (
              <div className="bankcard__description--card">
                <div className="bankcard__description--card--slider">
                  <div className="skeletonbox">
                    <div className="skeletonbox__container"></div>
                  </div>
                </div>
                <div className="bankcard__description--card--container">
                  <div className="cardheaderr">
                    <div className="skeleton">
                      <div className="skeleton__container"></div>
                    </div>
                  </div>
                  <div className="skeleton">
                    <div className="skeleton__container"></div>
                  </div>
                  <div className="bankcard__description--card--content">
                    <div className="skeleton">
                      <div className="skeleton__container"></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="bankcard__description--card"
                onClick={() =>
                  handleTravelClickWhithoutDate(better, coin, codigo)
                }
              >
                <div className="bankcard__description--card--slider">
                  <SliderHotelMobile
                    tipo={better.tipo_hotel?.titulo}
                    images={better.img}
                    city={better.destino}
                    bgColor={better.tipo_hotel?.color}
                  />
                </div>
                <div className="bankcard__description--card--container">
                  {bank && bank.logo?.path && (
                    <div
                      className="tagbank"
                      style={{
                        backgroundColor: bank?.color || "gray",
                      }}
                    >
                      <img src={bank.logo?.path} alt={bank.titulo} />
                    </div>
                  )}
                  <div
                    className="cardheaderr"
                  >
                    <Text
                      text={better.nombre_corto}
                      type="subtitle2"
                      className="title"
                    />
                    <div>
                      {better.calificacion > 0 && (
                        <div className="review">
                          <Text text={better.calificacion} type="paragraph" />

                          <IconText
                            text={`(${
                              better.reseña !== null ? better.reseña : 0
                            } ${messages[locale].hotelsReview})`}
                            nameIcon="star2"
                            listIcon="items"
                            w={16}
                            h={16}
                            type="zero"
                          />
                        </div>
                      )}
                      {better?.atributo_destacado_hotel.atributo_destacado && (
                        <div className="estilosicon">
                          {better.atributo_destacado_hotel
                            ?.icon_atributo_destacado === null ? (
                            <div>
                              <Icon
                                name="check"
                                list="items"
                                width={14}
                                height={14}
                              />
                            </div>
                          ) : (
                            <img
                              src={
                                better.atributo_destacado_hotel
                                  ?.icon_atributo_destacado?.path
                              }
                              alt={
                                better.atributo_destacado_hotel
                                  ?.icon_atributo_destacado?.alt
                              }
                              width={12}
                              height={12}
                            />
                          )}
                          <Text
                            text={
                              better?.atributo_destacado_hotel
                                .atributo_destacado &&
                              better?.atributo_destacado_hotel
                                ?.atributo_destacado.length >
                                MIN_ATRIBUTO_BANK_LENGTH
                                ? `${better?.atributo_destacado_hotel.atributo_destacado.slice(
                                    0,
                                    MIN_ATRIBUTO_BANK_LENGTH
                                  )}...`
                                : better?.atributo_destacado_hotel
                                    .atributo_destacado
                            }
                            className="atributo"
                            type="paragraph"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bankcard__description--card--content">
                    <div
                      className="cardprices"
                      // style={{
                      //   paddingTop:
                      //     better.precio_anterior.precio >
                      //     better.precio.precio
                      //       ? '0px'
                      //       : '20px'
                      // }}
                    >
                      <div className="cardprices__services">
                        <div className="cardprices__services--better">
                          {better.atributo_destacado
                            .slice(0, 4)
                            .map((item: any, index: number) => (
                              <div className="estilos" key={index}>
                                <Icon
                                  name={"check"}
                                  list="items"
                                  width={12}
                                  height={12}
                                  color={item.color ? "#14C67A" : "#7E7E7E"}
                                />
                                <p
                                  style={{
                                    color: item.color || "#7E7E7E",
                                  }}
                                >
                                  {item.texto}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div
                        className="cardprices__card"
                        style={{
                          gap:
                            parseFloat(better.precio.precio.length) >= 5
                              ? "5px"
                              : "10px",
                        }}
                      >
                        <div>
                          <p
                            className="ttcardprices"
                            style={{
                              marginBottom:
                                better.precio_anterior.precio >
                                better.precio.precio
                                  ? "0px"
                                  : "20px",
                            }}
                          >
                            {t("betterPrices.nightFrom")}
                            {parseFloat(better.precio_anterior.precio) >
                              parseFloat(better.precio.precio) && (
                              <span
                                style={{
                                  fontSize:
                                    parseFloat(better.precio.precio.length) >= 5
                                      ? "12px"
                                      : "14px",
                                }}
                              >
                                {better.precio_anterior.simbolo}{" "}
                                {better.precio_anterior.precio}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="carddescuento">
                          {parseFloat(better.precio_anterior.precio) >
                            parseFloat(better.precio.precio) && (
                            <Text
                              text={`- ${better.descuento}%`}
                              className="ttdescuento"
                              style={{
                                backgroundColor:
                                  better.etiqueta_precio.color ||
                                  better.tipo_hotel?.color,
                              }}
                              type="paragraph"
                            />
                          )}
                          <Text
                            text={`${better.precio.simbolo} ${better.precio.precio}`}
                            className="sbcardprices"
                            type="paragraph"
                            style={{
                              fontSize:
                                parseFloat(better.precio.precio.length) >= 5
                                  ? "18px"
                                  : "25px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text">
                      <div className="date">
                        {better.fecha_inicio && better.fecha_fin && (
                          <div>
                            <Text
                              html={{
                                __html: estadiasDate(
                                  better.fecha_inicio,
                                  better.fecha_fin,
                                  t,
                                  false
                                ),
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="column">
                        <div className="sbbcardprices">
                          {better.igv !== null && better.igv.precio > 0 && (
                            <p>Incluyen impuestos y servicios</p>
                          )}
                        </div>

                        {better.descuento_life !== null &&
                          better.descuento_life !== 0 && (
                            <div className="bg">
                              <Icon
                                name={"tag"}
                                list={"items"}
                                width={14}
                                height={14}
                                className="tag"
                              />
                              <Text
                                text={t("betterPrices.dsctoLife", { descuento_life: better.descuento_life})}
                                type="paragraph"
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }
      case "promotions": {
        return (
          <div
            className="card__banks"
            onClick={() => handleTravelClickWhithoutDate(better, coin, codigo)}
          >
            <div className="card__banks--slider">
              <SliderHotelMobile
                tipo={better.tipo_hotel.titulo}
                bgColor={better.tipo_hotel?.color}
                city={better.destino}
                images={better.img}
              />
            </div>
            <div className="card__banks--container">
              {bank.logo?.path && (
                <div
                  className="tagbank"
                  style={{ backgroundColor: bank?.color || "gray" }}
                >
                  <img src={bank.logo?.path} alt={bank.logo?.alt} />
                </div>
              )}
              <div className="cardheaderr">
                <p className="title">{better.nombre_corto}</p>
                <div>
                  <p className="review">
                    {better.calificacion}
                    <span>
                      <Icon
                        name="star"
                        list="items"
                        width={12}
                        height={12}
                        className="mr-0-2rem"
                      />
                    </span>
                    ({better.reseña} {messages[locale].hotelsReview})
                  </p>
                </div>
              </div>
              <div className="cardprices">
                <div className="cardprices__card">
                  <div>
                    <p className="ttcardprices">
                      {t("betterPrices.nightFrom")}
                      <span className="tc-status-color1">
                        {better.precio_anterior.simbolo}{" "}
                        {better.precio_anterior.precio}
                      </span>
                    </p>
                  </div>
                  <div className="carddescuento">
                    {better.descuento > 0 && (
                      <p
                        className="ttdescuento"
                        style={{ backgroundColor: bank.color }}
                      >
                        {better.descuento}%
                      </p>
                    )}
                    <p className="sbcardprices">
                      {better.precio.simbolo} {better.precio.precio}
                    </p>
                  </div>
                </div>
                <div className="estancia">
                  <div>
                    Estadías del <br />{" "}
                    {getDateWithoutTime(better.fecha_inicio)} al{" "}
                    {getDateWithoutTime(better.fecha_fin)}
                  </div>
                  {better.igv !== null && better.igv.precio > 0 && (
                    <div className="sbbcardprices">
                      <p className="sbbcardprices__text">
                        + {better.igv.simbolo} {better.igv.precio} de igv y
                        servicios
                        <Icon
                          name="question-card"
                          list="items"
                          width={14}
                          height={14}
                          className={`ml-0-2rem tooltip-price-${better.id}`}
                        />
                        <Tooltip
                          anchorSelect={`.tooltip-price-${better.id}`}
                          place="top-end"
                          opacity={1}
                          style={{
                            backgroundColor: "white",
                            color: "#222",
                            boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                            width: "280px",
                            zIndex: 101,
                          }}
                        >
                          {DESCRIPTIONTOOLTIP}
                        </Tooltip>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
      case "restaurant": {
        return (
          <Link
            className="card__restaurantplace nlink"
            to={better.slug}
            ref={restaurantRef}
          >
            <div className="card__restaurantplace--slider">
              <div
                className="card-type"
                style={{ backgroundColor: better.tipo_restaurante?.color }}
              >
                <img
                  src={better.tipo_restaurante?.img?.path}
                  alt={better.tipo_restaurante?.img?.alt}
                  width={60}
                  height={60}
                />
              </div>
              <div className="card-type-img">
                <img src={better?.img[0]?.path} alt={better?.img[0]?.alt} />
              </div>
            </div>
            <div className="card__restaurantplace--container">
              <div className="cardheaderr">
                <p
                  className="title"
                  style={{ color: better.tipo_restaurante?.color }}
                >
                  {better.titulo?.length > 48
                    ? `${better.titulo.slice(0, 48)}...`
                    : better.titulo}
                </p>
                <div>
                  <p className="review">
                    {better.calificacion}
                    <span>
                      <Icon
                        name="star"
                        list="items"
                        width={12}
                        height={12}
                        className="mr-0-2rem"
                      />
                    </span>
                    ({better.reseña} {messages[locale].hotelsReview})
                  </p>
                </div>
              </div>
              <div className="cardprices">
                <IconText
                  nameIcon="place"
                  listIcon={"items"}
                  w={24}
                  h={24}
                  text={better.direccion}
                  className={"place"}
                />
                <div className="cardprices__card">
                  <Text
                    html={{
                      __html:
                        better.descripcion?.length > 72
                          ? `${better.descripcion?.slice(0, 76)}...`
                          : better.descripcion,
                    }}
                    className="txrestaurant"
                  />
                  <IconText
                    type="left"
                    nameIcon="right"
                    listIcon="arrows"
                    text={messages[locale].learnMore}
                    className={"btnrestaurant"}
                    w={20}
                    h={20}
                  />
                </div>
              </div>
            </div>
          </Link>
        );
      }
      case "restaurant-logo": {
        return (
          <>
            <div className="card__restaurantcategory nlink" ref={restaurantRef}>
              <div className="card__restaurantcategory--slider">
                <div
                  className="card-type"
                  style={{ backgroundColor: better.restaurante?.tipo?.color }}
                >
                  <img
                    src={
                      better.restaurante?.tipo?.img?.path ||
                      better.tipo_restaurante?.img?.path
                    }
                    alt={
                      better.restaurante?.tipo?.img?.alt ||
                      better.tipo_restaurante?.img.alt
                    }
                    width={60}
                    height={60}
                  />
                </div>
                <div className="card-type-img">
                  <img
                    src={better.card?.img?.path}
                    alt={better.card?.img?.path}
                  />
                </div>
              </div>
              <div className="card__restaurantcategory--container">
                <div className="cardcategory">
                  <div className="cardcategory__body">
                    <div className="cardcategory__body--text">
                      <div className="cardcategory__body--title">
                        <p
                          className="title"
                          style={{ color: better.restaurante?.tipo?.color }}
                        >
                          {better.card?.titulo}
                        </p>
                      </div>
                      <div className="cardcategory__body--description">
                        <div>
                          <p
                            className="title"
                            style={{ color: better.restaurante?.tipo?.color }}
                          >
                            {better.restaurante?.titulo}
                          </p>
                          <Text
                            html={{ __html: better.card?.descripcion }}
                            className="description"
                          />
                          <ul className="subtitle">
                            <li>{better.card?.subtitulo}</li>
                          </ul>

                          {better.card.terminos_y_condiciones && (
                            <div className="termino">
                              <p className="terms">
                                {t("restaurants.checkTheTermsAndConditions")}{" "}
                                <span
                                  onClick={() => toogleModal(better.id)}
                                  className="txtlink"
                                >
                                  {t("restaurants.here")}
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {parseFloat(better.card?.precio?.precio) !== 0 && (
                      <div className="cardcategory__body--price">
                        <p className="adult">
                          {better.card?.texto_encima_precio}
                        </p>
                        <p className="price">
                          {better.card?.precio?.simbolo}{" "}
                          {better.card?.precio?.precio}
                        </p>

                        {parseFloat(better.card?.precio_nino?.precio) !== 0 ? (
                          <p className="discount">
                            por niño {better.card?.precio_nino?.simbolo}{" "}
                            {better.card?.precio_nino?.precio}
                            <Icon
                              name="question-card"
                              list="items"
                              width={14}
                              height={14}
                              className={`ml-0-2rem tooltip-price-${better.id}`}
                            />
                            <Tooltip
                              isOpen={tooltipStates[data.id]}
                              anchorSelect={`.tooltip-price-${better.id}`}
                              place="top-end"
                              clickable={true}
                              opacity={1}
                              style={{
                                backgroundColor: "white",
                                color: "#222",
                                boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                                width: "280px",
                                zIndex: 101,
                              }}
                            >
                              {DESCRIPTIONTOOLTIP}
                            </Tooltip>
                          </p>
                        ) : (
                          better.card.condicion_abajo_precio && (
                            <p className="discount">
                              {better.card.condicion_abajo_precio}
                              <Icon
                                name="question-card"
                                list="items"
                                width={14}
                                height={14}
                                className={`ml-0-2rem tooltip-price-${better.id}`}
                              />
                              <Tooltip
                                isOpen={tooltipStates[data.id]}
                                anchorSelect={`.tooltip-price-${better.id}`}
                                place="top-end"
                                clickable={true}
                                opacity={1}
                                style={{
                                  backgroundColor: "white",
                                  color: "#222",
                                  boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                                  width: "280px",
                                  zIndex: 101,
                                }}
                              >
                                {DESCRIPTIONTOOLTIP}
                              </Tooltip>
                            </p>
                          )
                        )}
                      </div>
                    )}
                    <div className="cardcategory__button">
                      <IconText
                        type="right"
                        nameIcon="right"
                        listIcon="arrows"
                        text={t("restaurants.goTheTestaurant")}
                        family={"btnrestaurant"}
                        w={20}
                        h={20}
                        onClick={() =>
                          navigate(better.restaurante?.redireccion)
                        }
                        className="text"
                      />

                      <Button
                        type="gold"
                        onClick={() =>
                          redirectUrlExternal(
                            better.restaurante?.redireccion_reserva
                          )
                        }
                      >
                        {t("restaurants.book")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {modalIndex === better.id && (
                <Modal
                  isTitle={true}
                  showModal={modalIndex !== null}
                  close={() => toogleModal(better.id)}
                  title={"Términos y condiciones de la promoción"}
                >
                  <div className="modal__terminos">
                    <Text
                      html={{ __html: better.card?.terminos_y_condiciones }}
                      family={"txnormal"}
                    />
                  </div>
                </Modal>
              )}
            </div>
          </>
        );
      }
    }
  };

  return (
    <div
      className="promotionscard padding__general"
      ref={restaurantRef}
      style={{ paddingTop: `${pTS}px`, paddingBottom: `${pBS}px` }}
    >
      <div className="promotionscard__container">
        <div className="promotionscard__container--concept">
          {title && (
            <Text text={title} type="subtitle" className={"subtitle"} />
          )}

          {isFilter && (
            <div className="filtro">
              <Dropdown
                ordenarPor={filtro.ordenar_por}
                title={t("betterPrices.sortBy")}
                selectedOption={selectedOption}
                onSelect={handleSelect}
              />
            </div>
          )}
        </div>
        <div
          className="promotionscard__description"
          style={{ paddingTop: `${pT}px`, paddingBottom: `${pB}px` }}
        >
          {data?.length > 0 &&
            data.map((better: any, index: number) => (
              <Fragment key={index}>{cardsType({ better })}</Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

PromotionsCard.defaultProps = {
  isTag: true,
  isFilter: true,
  bgLife: true,
};

export default PromotionsCard;
