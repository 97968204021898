import React, { useState } from 'react'
import { IconText, Text } from 'components/atoms'
import { IDescriptionLocal } from './props'
import { messages } from 'utils/lang'
import { useIntl } from 'react-intl'
import { MAX_DESCRIPTION_LENGTH } from 'utils/values'
import './styles.scss'

const DescriptionLocal = ({altitude, data, temperature, description}: IDescriptionLocal) => {
  const { locale } = useIntl()

  const [isToggled, setIsToggled] = useState(false)

  const toogleMore = () => setIsToggled(!isToggled)

  const descriptionComplete = `${description}`;

  const shortenedDescription = isToggled
    ? descriptionComplete
    : descriptionComplete.slice(0, MAX_DESCRIPTION_LENGTH) + '...';

  return (
    <>
      <div className='ismobile content-mob'>
        <Text html={{ __html: shortenedDescription}} className='description'/>

        <div className='toogle'>
          <Text text={isToggled ? messages[locale].descriptionToogleLess : messages[locale].descriptionToogleMore} onClick={toogleMore} className='toogle__more' />
        </div>

        <div className='contenicon'>
          <IconText nameIcon={data.tipo_transporte?.titulo === 'Terrestre' ? 'auto' : 'airplane'} listIcon='items' text={data.referencia}/>
          <IconText nameIcon='temperature' listIcon='items' text={temperature} />
          <IconText nameIcon='sun' listIcon='items' text={`${altitude} m.s.n.m`} />
        </div>
      </div>

      <div className='isdesktop content-des'>
        <Text html={{ __html: descriptionComplete }} className='description'/>

        <div className='contenicon'>
          <IconText nameIcon={data.tipo_transporte?.titulo === 'Terrestre' ? 'auto' : 'airplane'} listIcon='items' text={data.referencia} className='pr-1rem br-1px-secondary-color5'/>
          <IconText nameIcon='temperature' listIcon='items' text={temperature} className='pr-1rem br-1px-secondary-color5 '/>
          <IconText nameIcon='sun' listIcon='items' text={`${altitude} m.s.n.m`}/>
        </div>
      </div>
    </>
  )
}

export default DescriptionLocal