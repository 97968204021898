import Language from "redux/types/language"
import { createSlice } from "@reduxjs/toolkit"

const initialState: Language = {
  data: null,
  dataBetterPrice: null,
  dataHome: null,
  dataPromotion: null,
  dataPromotionBanks: null,
  dataPromotionSubPages: null,
  dataHoteles: null,
  dataBoxSearch: null,
  dataBoxSearchPrice: null,
  dataCurrencies: null,
  dataTypesHotels: null,
  dataUtils: null,
  dataHotelesDestination: null,
  dataDestinations: null,
  dataRestaurants: null,
  dataMenuOfertas: null,
  dataMenuHoteles: null,
  dataMenuDestinos: null,
  dataRestaurantsRestaurant: null,
  dataRestaurantsPlaces: null,
  dataRestaurantsCategory: null,
  dataBestPrices: null,
  loading: false,
  dataAgency: null,
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setDataBetterPrice: (state, action) => {
      state.dataBetterPrice = action.payload
    },
    setDataHome: (state, action) => {
      state.dataHome = action.payload
    },
    setDataPromotion: (state, action) => {
      state.dataPromotion = action.payload
    },
    setDataPromotionBanks: (state, action) => {
      state.dataPromotionBanks = action.payload
    },
    setDataPromotionSubPages: (state, action) => {
      state.dataPromotionSubPages = action.payload
    },
    setDataBoxSearch: (state, action) => {
      state.dataBoxSearch = action.payload
    },
    setDataBoxSearchPrice: (state, action) => {
      state.dataBoxSearchPrice = action.payload
    },
    setDataHoteles: (state, action) => {
      state.dataHoteles = action.payload
    },
    setDataCurrencies: (state, action) => {
      state.dataCurrencies = action.payload
    },
    setDataUtils: (state, action) => {
      state.dataUtils = action.payload
    },
    setDataTypesHotels: (state, action) => {
      state.dataTypesHotels = action.payload
    },
    setDataHotelesDestination: (state, action) => {
      state.dataHotelesDestination = action.payload
    },
    setDataDestinations: (state, action) => {
      state.dataDestinations = action.payload
    },
    setDataMenuOfertas: (state, action) => {
      state.dataMenuOfertas = action.payload
    },
    setDataMenuHoteles: (state, action) => {
      state.dataMenuHoteles = action.payload
    },
    setDataMenuDestinos: (state, action) => {
      state.dataMenuDestinos = action.payload
    },
    setDataRestaurants: (state, action) => {
      state.dataRestaurants = action.payload
    },
    setDataRestaurantsPlaces: (state, action) => {
      state.dataRestaurantsPlaces = action.payload
    },
    setDataRestaurantsCategory: (state, action) => {
      state.dataRestaurantsCategory = action.payload
    },
    setDataRestaurantsRestaurant: (state, action) => {
      state.dataRestaurantsRestaurant = action.payload
    },
    setBestPrices: (state, action) => {
      state.dataBestPrices = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setDataAgency: (state, action) => {
      state.dataAgency = action.payload
    },
  }
})

export const {
  setData,
  setLoading,
  setDataBetterPrice,
  setDataHome,
  setDataPromotion,
  setDataPromotionBanks,
  setDataPromotionSubPages,
  setDataBoxSearch,
  setDataBoxSearchPrice,
  setDataHoteles,
  setDataUtils,
  setDataCurrencies,
  setDataTypesHotels,
  setDataHotelesDestination,
  setDataMenuOfertas,
  setDataMenuHoteles,
  setDataMenuDestinos,
  setDataRestaurants,
  setDataRestaurantsRestaurant,
  setDataRestaurantsPlaces,
  setDataRestaurantsCategory,
  setDataDestinations,
  setBestPrices,
  setDataAgency,
} = languageSlice.actions

export default languageSlice.reducer