import React from "react";
import { Text } from "components/atoms";
import { DescriptionLocal } from "components/molecules";
import { IDestinations } from "./props";
import "./styles.scss";

const Destinations = ({
  altitude,
  description,
  title,
  temperature,
  descriptionRef,
  data
}: IDestinations) => {
  return (
    <section className="destinations" ref={descriptionRef}>
      <div className="destinations__description">
        <Text text={title} type="subtitle" className={"subtitle"} />

        <DescriptionLocal
          altitude={altitude}
          data={data}
          temperature={temperature}
          description={description}
        />
      </div>
    </section>
  );
};

export default Destinations;
